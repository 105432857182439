import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import FreeTrialPopup from '../modals/FreeTrialPopup3';
import NewUserModal from '../modals/NewUserModal';
// import CheckoutModalMobile from '../modals/CheckoutModalMobile';
// import CheckoutModalStripe from '../zArchive/CheckoutModalStripe';
import Chat from '../components/Chat';


const ProtectedRoute = ({ children }) => {
  const { user, userLoaded } = useContext(UserContext);

  if ( !userLoaded ) return null
  else if ( !user ) return <Navigate to={Routes.LANDING} />
  return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-backgroundSecondary)' }}>
        <Outlet />
        <FreeTrialPopup />
        {/* <NewUserModal /> */}
        {/* <CheckoutModalStripe /> */}
        <Chat />
      </div>
  )
};

export default ProtectedRoute;




// else if ( !user?.isMember ) return <OnboardingScreen />


{/* <AbandonedCheckoutModal open={openAbandonedCheckoutModal} setOpen={setOpenAbandonedCheckoutModal} /> */}