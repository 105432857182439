import React, { useState, useContext, useEffect, useRef } from 'react';
import { Check, Star, ChevronDown, ChevronUp, CheckCircle2, User, File, TrendingUp, Clock, CircleCheckBig, X, Video, ListChecks, BookOpen, CheckSquare, NotebookPen, MessageCircle, Calculator, PenLine, GraduationCap, FileIcon, Youtube, ArrowRight, CheckIcon, CheckCircleIcon, ZapIcon, Rabbit, ChartNoAxesCombined,  } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import axios from 'axios';
import { API, dbRefs, PAYWALL_EVENTS, PAYWALL_TYPES, SEARCH_PARAMS, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { createCheckoutSession_v3, logPaywallAction, sendSlackNotification } from '../misc/utils';
import { useBreakpoint } from '../misc/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import Subheader from '../components/Subheader';
import { ContentCreate_Header } from '../components/ContentCreateComponents';
import SchoolGoat from '../assets/SchoolGoatBg.png';
import { Routes } from '../navigation/routes';
import { Pixel } from '../misc/analytics';

const Tile_Plan2 = ({ plan, index, handleClick,  }) => {
  const { user, paddle } = useContext(UserContext);
  const [ priceObj, setPriceObj ] = useState(null);
  const currencyCode = priceObj?.currencyCode || 'USD';
  const interval = priceObj?.details?.lineItems?.[0]?.price?.billingCycle?.interval;
  const intervalString = interval === 'month' ? '/mo' : '/yr';
  const userCountryCode = user?.authData?.country_code || 'US';

  const _idEffective = getEffectiveId(plan);

  useEffect(() => {
    getPriceObj();
  }, [_idEffective, userCountryCode, paddle]);

  const getPriceObj = async () => {
    if ( !paddle ) { console.log('no paddle'); return; }

    try {
      const result = await paddle.PricePreview({items: [{ priceId: _idEffective, quantity: 1 }], address: { countryCode: userCountryCode }});
      console.log(result);
      setPriceObj(result.data);
    }
    catch (error) {
      try {
        const result = await paddle.PricePreview({ items: [{ priceId: _idEffective, quantity: 1 }], address: { countryCode: 'US' } });
        console.log(result);
        setPriceObj(result.data);
      }
      catch (error) {
        console.error('Error getting price preview:', error);
      }
    }
  }

  

  return (
    <div style={{ flexShrink: 0, flexBasis: '320px', background: 'white', borderRadius: '1rem', padding: '1.7rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', position: 'relative' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '1rem', right: '1rem' }}>
        <span style={{ fontSize: '14px', color: 'var(--color-primary)', fontWeight: 'normal', marginLeft: '8px', background: '#EEE7FE', padding: '6px 8px', borderRadius: '.3rem', opacity: plan.tag ? 1 : 0 }}
          >{plan.tag || 'l'}
        </span> 
      </div>
 
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>     
        <img src={SchoolGoat} alt="School Goat" style={{ width: '70%', height: 'auto', objectFit: 'contain', alignSelf: 'center', }} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
        <p style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: 'bold', marginTop: '1rem' }}
          >School GOAT {plan.title}
        </p>

        <div style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '1rem', opacity: priceObj ? 1 : 0 }}
          >{priceObj?.details?.lineItems?.[0]?.formattedTotals?.total}<span style={{ fontSize: '.8rem', color: 'var(--color-text4)', fontWeight: 'normal' }}>{intervalString}{ currencyCode && currencyCode !== 'USD' ? ` (${currencyCode})` : ''}</span>
        </div>

        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '1.5rem', lineHeight: '1.5', textAlign: 'center', marginTop: '1rem' }}>
          {plan.description.map((item, index) => (
            <span key={index}>+ {item} {<br/>}</span>
          ))}
        </p>
      </div>


      <button style={{ width: '100%', padding: '12px 2px', background: '#000', color: 'white', borderRadius: '100px', fontWeight: '600', border: 'none', cursor: 'pointer', fontSize: '1rem', marginTop: 'auto' }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-text3)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-text1)'}
      onClick={handleClick}
        >Choose Plan
      </button>

      {/* <div style={{ fontSize: '12px', color: 'var(--color-text3)', fontWeight: 'normal', marginTop: '.5rem', alignSelf: "center", textAlign: "center" }}
        >{plan.buttonSubtext}
      </div> */}
    </div>
  )
}

const Tile_FAQ = ({ question, answer, isActive, handleClick, index }) => {
  return (
    <div style={{ borderBottom: '1px solid var(--color-separatorOpaque)', }}>
      <button style={{ width: '100%', paddingTop: '1.25rem', paddingBottom: !isActive ? '1.25rem' : '.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left', cursor: 'pointer', background: 'none', border: 'none', fontSize: '1rem', lineHeight: '1.5', paddingLeft: 0, paddingRight: 0 }}
        onClick={handleClick} 
        
      >
        <span style={{ fontWeight: 500, fontSize: '1.125rem', lineHeight: '1.75', color: 'var(--color-text1)' }}>
          {question}
        </span>
        {isActive ? 
          <ChevronUp style={{ width: 24, height: 24, color: 'var(--color-text1)' }} /> : 
          <ChevronDown style={{ width: 24, height: 24, color: 'var(--color-text1)' }} />
        }
      </button>
      {isActive && (
        <div style={{ paddingBottom: '1.25rem', paddingRight: '2rem', color: 'var(--color-text3)', fontSize: '1rem', lineHeight: '1.5', letterSpacing: '0.00625em' }}
          >{answer}
        </div>
      )}
    </div>
  );
};


const Tile_Testimonial = ({ item, index }) => {
  return (
    <div key={index} style={{ flexShrink: 0, background: 'white', borderRadius: '1rem', padding: '1.5rem', border: '1px solid var(--color-separatorOpaque)', flexBasis: '420px', flexGrow: 1, maxWidth: '400px', display: "flex", flexDirection: "column" }}>
      <div style={{ display: 'flex', marginBottom: '1rem', gap: '0.25rem' }}>
        {[...Array(5)].map((_, i) => (
          <Star 
            key={i} 
            style={{ width: 16, height: 16 }}
            color="var(--color-text1)"
            fill="var(--color-text1)"
          />
        ))}
      </div>
      <p style={{ color: 'var(--color-text3)', marginBottom: '1rem', fontSize: '1rem', lineHeight: '1.5', letterSpacing: '0.00625em' }}
        >{item.text}
      </p>
      <div style={{ display: "flex", flexDirection: "column", marginTop: "auto"}}>
        <p style={{ fontWeight: '600', fontSize: '1rem', lineHeight: '1.75', marginBottom: '0.25rem', color: 'var(--color-text1)' }}
          >{item.name}
        </p>
        <p style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}
          >{item.role}
        </p>
      </div>
    </div>
  )
}

const faqs = [
  {
    question: "If I change my mind, can I cancel or switch plans?",
    answer: "Yes, it's easy! You can cancel or change your plan directly on the website (Options > Billing)."
  },
  {
    question: "What is the difference between the plans?",
    answer: "Both plans come with access to all features. The Plus plan gets you generous usage limits, while the Pro plan gets you totally unlimited usage. If you plan to be a heavy user, the Pro plan is the best choice. But for most users, the Plus plan is more than enough."
  },
  {
    question: "Do I get access right after I subscribe?",
    answer: "Yes! As soon as you subscribe, you'll get access to all features."
  },
  {
    question: "Is the payment service secure?",
    answer: "Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards."
  },
];

const testimonials = [
  {
    name: "Manny M.",
    role: "Engineering, University of Pennsylvania",
    text: "The note-taking feature has saved me. I have a hard time focusing in class. Now I never miss a thing - I get perfect notes every class and can focus more easily in class."
  },
  {
    name: "Brandy C.",
    role: "Biology, UCLA",
    text: "School GOAT has made school so much easier. I can quickly get through my homework with the solver. Plus, my grades are so much higher because of all the study tools."
  },
];

const tabs = {
  // weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
}


const plusDescription = ["Access to all features", "Priority support", ]
const proDescription = ["Everything in Plus", "Unlimited usage", ]

const plans = [

  /////////////////// TIER 1 ///////////////////
  {
    _id: "pri_01jjcdrhshkrvzjhq6d14mzavn",     // live
    _idTest: 'pri_01jj8bfvbmdkmna30n3ewxzakw', // test
    title: 'Plus', frequency: tabs.monthly, description: plusDescription, tag: "Most Popular"
  },
  {
    
    _id: "pri_01jjcdsat83eevnp0fkgezge4a",     // live
    _idTest: 'pri_01jj8bfvbmdkmna30n3ewxzakw', // test
    title: 'Plus', frequency: tabs.yearly, description: plusDescription, tag: "2 Months Free",
  },

  {
    _id: "pri_01jjcdvascxj6vb7xjbyg4kh9m",     // live
    _idTest: 'pri_01jj8bfvbmdkmna30n3ewxzakw', // test
    title: 'Pro', frequency: tabs.monthly, description: proDescription, tag: "Most Powerful", 
  },
  {
    _id: "pri_01jjcdvya9nyq01h59qesevksb",     // live
    _idTest: 'pri_01jj8bfvbmdkmna30n3ewxzakw', // test
    title: 'Pro', frequency: tabs.yearly, description: proDescription, tag: "2 Months Free",
  },
]

const features = [
  { title: "Live Lectures", description: "Get notes taken for you automatically in class" },
  { title: "File Uploads", description: "Upload your class materials and get instant study tools." },
  { title: "File Chat", description: "Ask questions and talk straight to your class files." },
  { title: "Summaries", description: "Get summaries of your class materials and readings." },
  { title: "Study Guides", description: "Instant study guides to prepare you for your exams." },
  { title: "Flashcards", description: "Create flashcards to help memorize materials." },
  { title: "Quizzes", description: "Adaptive quizzes to test your knowledge before exams." },
  { title: "Live Tests", description: "Simulate exam questions with live testing." },
  { title: "Personal Tutor", description: "Personalized help and mindmaps for learning concepts." },
  { title: "Homework Help", description: "Get step-by-step solutions to your assignments." },
  { title: "Essay Help", description: "Get feedback on your essays and improve your writing skills." },
  { title: "Test Topics", description: "Get a list of likely test topics and common mistakes." },
]

const getEffectiveId = (plan) => {
  return process.env.REACT_APP_NODE_ENV === 'production' ? plan._id : plan._idTest;
}

const JoinScreen = () => {
  const { user, paddle } = useContext(UserContext);
  const navigate = useNavigate();
  const [ selectedFaqIndex, setSelectedFaqIndex ] = useState(null)
  const buttonInProgressRef = useRef(false);
  const [ activeTab, setActiveTab ] = useState(tabs.monthly);

  useEffect(() => {
    Pixel.track('ViewContent', { content_name: 'pricing_plans' });
  }, []);

  const handleFaqClick = (index) => {
    setSelectedFaqIndex(prevIndex => prevIndex === index ? null : index)
  }

  const handlePlanClick = async (plan) => {
    if (!paddle) return;

    try {
      await Promise.allSettled([
        logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_CHECKOUT, type: 'paddle'}),
        sendSlackNotification(
          `Going to checkout\nEmail: ${user?.email}\nType: paddle\nCountry: ${user?.authData?.country_name}`, 
          SLACK_WEBHOOK_CHANNELS.stripe
        )
      ]);
    }
    catch (error) {
      console.error('Error creating checkout session:', error);
    }

    Pixel.track('InitiateCheckout');

    const _idEffective = getEffectiveId(plan);

    const checkoutOptions = {
      items: [ { priceId: _idEffective, quantity: 1 } ],
      settings: {
        theme: 'light',  
        successUrl: `${process.env.REACT_APP_FRONTEND_URL}/app/home?${SEARCH_PARAMS.checkout_success}=${user?._id}&${SEARCH_PARAMS.payment_provider}=paddle`,
        // variant: "one-page"
      },
      customer: {
        email: user?.email || '',
        address: {
          postalCode: user?.authData?.postal || '',
          countryCode: user?.authData?.country_code || '',
        }
      },
      discountId: "",
      customData: {
        mongo_id: user?._id,
        mongo_email: user?.email,
      }
      };

    paddle.Checkout.open(checkoutOptions);
  };

  const handleDecline = async () => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;

    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_DECLINED });
      navigate(`/app/${Routes.HOME}`);
    }
    catch (error) {
      console.error('Error logging paywall decline:', error);
    }
    finally {
      buttonInProgressRef.current = false;
    }
  }

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'var(--color-backgroundSecondary)', overflowY: 'auto' }}>

      <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '1rem' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem', cursor: 'pointer' }}
          onClick={handleDecline}
        >
          <X style={{ width: '24px', height: '24px', color: 'var(--color-text3)', strokeWidth: 1.5 }} />
        </div>
        
        <div>
          <ContentCreate_Header
            // title="Lock in **better grades**"
            title="Get the best grades of **your life**"
            subtitle="By becoming a member now!"
            styleOverride={{ marginBottom: '1.5rem' }}
            titleStyleOverride={{ fontSize: '3rem' }}
            subtitleStyleOverride={{ marginTop: '1rem' }}
          />

          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
            <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>

          <div style={{ position: 'relative', width: '100%', }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '1rem', gap: '1rem', flexWrap: 'wrap' }}>
              {plans.filter(plan => plan.frequency === activeTab).map((plan, index) => (
                <Tile_Plan2 key={`plan-${index}`} plan={plan} index={index} handleClick={() => handlePlanClick(plan)} paddle={paddle} />
              ))}
            </div>
          </div>
        </div>

        <div style={{ marginTop: '6rem' }}>
          <ContentCreate_Header
            title="All the features you get as a **member**"
            subtitle="Live lectures, file uploads, and more..."
            styleOverride={{ marginBottom: '1.5rem' }}
            titleStyleOverride={{ fontSize: '2.25rem' }}
            subtitleStyleOverride={{ marginTop: '1rem' }}
          />
          
          <div style={{ display: "flex", justifyContent: "center", gap: "1rem", width: "100%", marginTop: "1rem", flexWrap: "wrap",  }}>
          {features.map((item, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "column", border: "1px solid var(--color-separatorOpaque)", padding: "1.5rem", borderRadius: "1rem", flexBasis: "200px", maxWidth: "200px",  }}>

              <Check style={{ width: "20px", height: "20px", color: "var(--color-primary)", strokeWidth: 3 }} />
              <div style={{ fontSize: "1rem", fontWeight: "600", color: "var(--color-text1)", lineHeight: "1.25", marginTop: "1rem" }}
                >{item.title}
              </div>
              <div style={{ fontSize: ".95rem", color: "var(--color-text4)", lineHeight: "1.25", marginTop: ".5rem" }}
                >{item.description}
              </div>
            </div>
          ))}
          </div>


        </div>


        {/* Testimonials Section */}
        <div style={{ marginTop: '6rem' }}>
          <ContentCreate_Header
            title="What our users are **saying**"
            subtitle="20,000+ students have already joined"
            styleOverride={{ marginBottom: '1.5rem' }}
            titleStyleOverride={{ fontSize: '2.25rem' }}
            subtitleStyleOverride={{ marginTop: '1rem' }}
          />

          <div style={{ display: 'flex', gap: '1rem', minWidth: 'min-content', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
          {testimonials.map((testimonial, index) => (
              <Tile_Testimonial key={index} item={testimonial} index={index} />
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div style={{ width: '668px', maxWidth: '90%', margin: "6rem auto" }}>
          <h2 style={{ fontSize: '1.75rem', fontWeight: 'bold', textAlign: 'center', marginBottom: '32px' }}
            >Frequently Asked Questions
          </h2>
          <div style={{  }}>
            {faqs.map((faq, index) => (
              <Tile_FAQ key={index} question={faq.question} answer={faq.answer} isActive={selectedFaqIndex === index} handleClick={() => handleFaqClick(index)} index={index} />
            ))}
          </div>
        </div>


      </div>
    </div>
  );
};

export default JoinScreen;
