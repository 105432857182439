import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useMemo, useCallback } from 'react';
import { MdArrowForward, MdArrowDownward } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import { UserContext } from '../contexts/UserProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { ChevronDown, ChevronUp, CodeIcon, GraduationCap, ListChecks } from 'lucide-react';
import { IoSparklesSharp } from "react-icons/io5";
import { COLOR_ARRAY, REFERRAL_CODE_USED_KEY, SEARCH_PARAMS, } from '../misc/constants';
import AuthModal from '../modals/AuthModal2';
import Benefit1 from '../assets/benefit-1.jpg';
import Benefit2 from '../assets/benefit-2.jpg';
import Benefit3 from '../assets/benefit-3.jpg';
import Benefit4 from '../assets/benefit-4.jpg';

import SchoolGoat from '../assets/schoolgoat.webp';
import { PenLine, Clock, Calendar, BookOpen, Calculator, CheckSquare, Zap, Star, Mail } from 'lucide-react';
import { Camera, MousePointer, Sparkles, NotebookPen, Trophy, ChartColumnIncreasing, Smile, BatteryCharging, ScanFace, DollarSign, HandCoins, HandHeart, Mic, FileIcon, Youtube, ArrowRight, CheckIcon, CheckCircleIcon, ZapIcon, Rabbit, ChartNoAxesCombined,  } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import ChillGirl from '../assets/chillGirl.png';
import { Pixel } from '../misc/analytics';
import axios from 'axios';
import { API, dbRefs } from '../misc/constants';

const buttonTextStyle = {cursor: "pointer", padding: ".5rem .8rem", borderRadius: "10px", shadow: "var(--shadow)", backgroundColor: "transparent", border: "none", color: "inherit", marginRight: ".2rem"}

const dataBenefits = [
  {
    title: "Better grades",
    text: "With a little help from School GOAT, you can reach your potential and get the best grades possible.",
    component: <ChartColumnIncreasing />,
    image: Benefit4,
    alt: "Girl writing on chalkboard",
  }, 
  {
    title: "Better life",
    text: "School Goat lightens your load. Meaning you can spend more time with friends, family, and your social life.",
    component: <BatteryCharging />,
    image: Benefit3,
    alt: "College graduates throwing their caps up",
  },
  {
    title: "Better mental health",
    text: "School doesn't have to be constant stress. With a personal assistant, you'll get more done with less stress.",
    component: <Smile />,
    image: Benefit1,
    alt: "College students relaxing and smiling",
    },
  {
    title: "Better focus",
    text: "You don't have to do everything yourself. School Goat will help. Which will make it easier for you to focus.",
    component: <ScanFace />,
    image: Benefit2,
    alt: "College guy carefully writing notes",
  },
]

const chunk = (array, size) => {
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
};

const PageDivider = () => {
  return (      
    <hr style={{ width: "100%", backgroundColor: "var(--color-divider)", height: "1px", marginTop: "4.25rem", marginBottom: "4.25rem", padding: 0, display: "flex", border: 0 }} aria-hidden="true" />
  )
}

const Spacer = () => {
  return (
    <div style={{ fontSize: "1rem", lineHeight: "1.5", marginTop: "1rem"}} aria-hidden="true">&nbsp;</div>
  )
}

const SectionHeader = ({ label }) => {
  return (
    <h2 style={{ fontSize: "1rem", lineHeight: "1.5", fontWeight: "800", marginTop: "1rem", textTransform: "uppercase", color: "var(--color-primary)", textAlign: "center" }} 
      >{label}
    </h2>
  )
}

const LandingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabletAndAbove, isMobileAndBelow, isPhoneAndBelow } = useBreakpoint();
  const { user, logout} = useContext(UserContext);
  const [ openAuthModal, setOpenAuthModal ] = useState(false); 
  const [ openAbandonedCheckoutModal, setOpenAbandonedCheckoutModal ] = useState(false); 


  useEffect(() => {
    Pixel.pageView();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get(SEARCH_PARAMS.ref);
    const email_id = searchParams.get(SEARCH_PARAMS.email_id);
    
    if (referralCode) {
      localStorage.setItem(REFERRAL_CODE_USED_KEY, referralCode);
    }

    if (email_id) {
      const updateEmailClick = async () => {
        try {
          await axios.put(`${API}/generalUpdateOne`, {
            matchObj: { _id: email_id },
            updateObj: { $set: { clicked: true } },
            dbRef: dbRefs.emails
          });
          navigate(location.pathname, { replace: true });
        } catch (error) {
          console.error('Failed to update email click:', error);
        }
      };
      updateEmailClick();
    }
  }, [location, navigate]);

  const CTAButton = ({ label }) => {
  
    const handleClick = (e) => {
      if (!user) {
        setOpenAuthModal(true);
      } else {
        navigate(Routes.APP);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleClick(e);
      }
    };
  
    return (
      <button style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", width: "auto", height: "3.5rem", padding: "0 2.75rem", fontSize: "1rem", fontWeight: "600", borderRadius: "100px", backgroundColor: "var(--color-primary)", color: "var(--color-textButton)", border: "none", cursor: "pointer", transition: "all 0.25s ease-in-out", marginTop: ".5rem", boxShadow: "var(--borderShadow)",  }}
        onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
        onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={user ? "Enter app" : "Try it out (for free)"}
      >
      <span>{user ? "Enter" : "Try it out (free)"}</span>
      { user && <MdArrowForward style={{ fill: "var(--color-textButton)", height: "1em", width: "1em", marginLeft: "0.75rem"}} aria-hidden="true" /> }  
    </button>
    );
  };

  const NavBar = () => {
    const handleLoginClick = () => {
      setOpenAuthModal(true);
    };
  
    const handleSignUpClick = () => {
      setOpenAuthModal(true);
    };
  
    return (
      <nav style={{ padding: '.5rem 1rem', borderBottom: '1px solid var(--color-separatorOpaque)', alignItems: 'center', justifyContent: 'space-between', width: '100%', display: 'flex' }}>

        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <span style={{ color: 'var(--color-text1)', textTransform: "uppercase", fontWeight: "300", letterSpacing: 1, fontSize: "1.1rem" }}
            >School Goat
          </span>
        </div>
  
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "flex-end" }}>
          { !user ?
            <>
              <button style={{ ...buttonTextStyle, fontSize: "1rem", color: 'var(--color-text2)'}}
                onClick={handleLoginClick}
                role="button"
                tabIndex="0"
              >
                Login
              </button>

              <button style={{ backgroundColor: `var(--color-backgroundSecondary)`, color: `var(--color-text2)`, marginLeft: ".2rem", fontSize: "1rem", border: "1px solid var(--color-separatorOpaque)", boxShadow: "var(--borderShadow)", padding: ".75rem 1rem", borderRadius: ".5rem"}}
                onClick={handleSignUpClick}
                >
                Start now
              </button>
            </>
          :
            <span style={{ ...buttonTextStyle, fontSize: "1rem", color: 'var(--color-text2)', padding: ".75rem 1rem", borderRadius: ".5rem", border: "1px solid transparent", }} 
              onClick={logout}
              >
              Logout
            </span>
          }
        </div>
      </nav>
    );
  };

  const HeroSection = ({  }) => {

    return (
      <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", gap: "1.5rem"}}>
        <img style={{ width: isTabletAndAbove ? "7rem" : "7rem", objectFit: "contain", marginTop: "1.5rem" }} 
          src={SchoolGoat} 
          alt="School GOAT logo" 
        />

        <h1 style={{ fontSize: !isMobileAndBelow ? "3.8rem" : "2.6rem", fontWeight: "700", color: "var(--color-text1)", lineHeight: "1.1", marginTop: "-1rem", maxWidth: "650px", textAlign: "center", letterSpacing: "-2px" }}
          // >The website that<br/>maxes your grades
          >Get the best<br/>grades of your life
          
        </h1>

        <p style={{ fontSize: "1.4rem", lineHeight: "1.6", textAlign: "center", maxWidth: "600px", color: "var(--color-text3)",
          ...(isMobileAndBelow && { fontSize: "1.3rem", lineHeight: "1.5" })
         }}
          // >With your own school assistant,<br/> you can reach your academic potential.
          // >The one tool you need to 10x your academic performance
          // >Get the best grades of your life
          >The only tool you need to reach your academic potential
        </p>

        <CTAButton />
        <FeatureGrid />
      </section>
    )
  };

  const FeatureGrid = () => {
    const data = [
      { icon: NotebookPen, text: "Take notes for you", textPhone: "Take notes for you" },
      { icon: CheckSquare, text: "Solve your homework", textPhone: "Solve your homework" },
      { icon: BookOpen, text: "Shortcut readings", textPhone: "Shortcut readings" },
      { icon: Trophy, text: "Write practice tests", textPhone: "Write practice tests" },
      { icon: Zap, text: "Create flashcards", textPhone: "Create flashcards" },
      { icon: Sparkles, text: "Build study guides", textPhone: "Build study guides" },
      { icon: PenLine, text: "Improve essays", textPhone: "Improve essays" },
      { icon: Clock, text: "Teach you fast", textPhone: "Teach you fast" },
    ];

    const Tile = ({ item, index }) => {
      if ( isTabletAndAbove ) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.79rem', backgroundColor: 'var(--color-background)', padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', transition: 'transform 0.2s ease, box-shadow 0.2s ease', boxShadow: "var(--borderShadow)", flexBasis: "300px" }}>
            <item.icon size={"1rem"} style={{color: 'var(--color-text2)', flexShrink: 0}} />
            <span style={{color: 'var(--color-text2)', fontSize: '1rem', fontWeight: '500', whiteSpace: 'nowrap'}}>
              {item.text}
            </span>
          </div>
        )
      }
      else {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', backgroundColor: 'var(--color-background)', padding: '.8rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', transition: 'transform 0.2s ease, box-shadow 0.2s ease', flex: 1, boxShadow: "var(--borderShadow)" }}>
            {/* <item.icon size={"1rem"} style={{color: 'var(--color-primary)', flexShrink: 0}} /> */}
            <span style={{color: 'var(--color-text2)', fontSize: '1rem', fontWeight: '500', whiteSpace: 'nowrap'}}>
              {item.textPhone || item.text}
            </span>
          </div>
        )
      }
    }
  
    return (
      <div style={{maxWidth: '700px', margin: '0 auto', marginTop: !isMobileAndBelow ? "1.5rem" : "1rem"}}>
        <h5 style={{textAlign: 'center', fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '1.5rem', fontWeight: '400',}}
          >What your Goat can do for you
        </h5>

        { isTabletAndAbove &&
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center'}}>
            {data.map((feature, index) => (
            <Tile key={index} item={feature} index={index} />
          ))}
        </div>
        }

        { !isTabletAndAbove &&
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '.5rem', justifyContent: 'center'}}>
            {data.map((feature, index) => (
              <Tile key={index} item={feature} index={index} />
            ))}
          </div>
        }

      </div>
    );
  };

  const HowItWorksSection = () => {
    const data = useMemo(() => [
      {
        title: `**Takes notes in class** for you, so you can just listen!`,
        titleShort: "Automatic note-taking",
        description: "School Goat will listen to your lecture and take notes for you, so you can focus and learn.",
        component: <NotebookPen />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_InClass.mp4",
        alt: "Image of a person taking notes in class",
      },
      {
        title: `**Solves your homework** problems and teaches you how`,
        titleShort: "Homework solutions",
        description: "Get instant solutions to your assignments and step by step explanations of how to solve them.",
        component: <ListChecks />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ReadingPDF.mp4",
        alt: "Image of a dense textbook page",
      },
      {
        title: `**Prepares you for exams** with class-specific practice tests`,
        titleShort: "Exam prep",
        description: "Get all the practice and guidance you need so you can walk into exams with confidence.",
        component: <ChartNoAxesCombined />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Auto-creates flashcards** so you can study efficiently`,
        titleShort: "Instant study materials",
        description: "Get flashcards, practice tests, and study guides created for you so you can study more efficiently.",
        component: <Zap />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Improves your essays** with estimated grades + feedback`,
        titleShort: "Essay help",
        description: "School GOAT will help you write your essays and give you grade estimates prior to turning them in.",
        component: <PenLine />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Personal tutor** for your classes`,
        titleShort: "Personal tutor",
        description: "Get any subject broken down and explained to you step by step, so you master the material.",
        component: <GraduationCap />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
    ], []);

    return (
      <section aria-label={"How it works"}>
        <PageDivider />
        <SectionHeader label={"How it works"} />
        
        { isPhoneAndBelow ?
          <h3 className="headerText" style={{ marginBottom: "2rem"}}
            >Get help with<br/>anything you need
          </h3>
        :
          <h3 className="headerText" style={{ marginBottom: "3rem"}}
            >Help with anything you need
          </h3>
        }

        { isPhoneAndBelow ? (
          <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
            {data.map((item, index) => (
              <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, backgroundColor: "var(--color-backgroundSecondary)", padding: "1.5rem 1rem", borderTop: index !== 0 ? "1px solid var(--color-separatorOpaque)" : "none", position: "relative"}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "1.5rem" }}>
                  {React.cloneElement(item.component, { style: { width: "1.75rem", height: "1.75rem", color: "var(--color-text1)", strokeWidth: 1.25 }})}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, }}>
                  <h3 style={{fontSize: '1.3rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}>
                    {item.titleShort}
                  </h3>
                  <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.5rem'}}>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : 
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'center', }}>
            { data.map((item, index) => (
              <div key={index} style={{ flexBasis: '325px', minWidth: '200px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.75rem', borderRadius: '.75rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', height: "350px", position: "relative"}}>
                <h3 style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}>
                  {item.titleShort}
                </h3>
                <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.5rem'}}>
                  {item.description}
                </p>
      
                <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", height: "225px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {React.cloneElement(item.component, { color: 'black', size: "5rem", strokeWidth: .95 })}
                </div>
              </div>
            ))}
          </div>
        }
      </section>
    );
  };

  const BenefitsSection = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
        <PageDivider />
        <SectionHeader label={"Benefits"} />
        { isPhoneAndBelow ?
          <h3 className="headerText" style={{ marginBottom: '2rem' }}>
            For maximum<br/>academic results
          </h3>
        :
          <h3 className="headerText" style={{ marginBottom: '3rem' }}>
            For maximum academic results
          </h3>
        }

        <div style={{ display: 'flex', flexDirection: 'row', gap: '32px',
        ...(windowWidth < 1024 && { flexDirection: 'column', alignItems: 'center' })

         }}>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', alignItems: 'center' }}>
            {dataBenefits.map((service, index) => (
              <div key={index} style={{ padding: '1rem', borderRadius: '.5rem', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', maxWidth: '600px' }}>
                <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                  <div style={{ padding: '12px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {React.cloneElement(service.component, { color: 'var(--color-text1)' })}
                  </div>
                  <div>
                    <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '8px' }}>{service.title}</h3>
                    <p style={{ color: '#666', lineHeight: '1.5' }}>{service.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>


          <img src={ChillGirl} alt="Stylized illustration" style={{ width: "50%", height: 'auto' }} />
        </div>
      </div>
    );
  };

  const TileBenefits_Phone = ({ item, index, isActive }) => (
    <article style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: '0 0 85%', maxWidth: '90%', scrollSnapAlign: 'center', opacity: isActive ? 1 : 0.5, transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out", marginRight: "20px" }}
      key={index}
      aria-hidden={!isActive}
    >
      <img
        style={{ width: "100%", aspectRatio: ".9", objectFit: "cover", borderRadius: "15px", boxShadow: `var(--shadow)` }}
        src={item.image}
        alt={item.alt}
      />

      <div style={{ display: "flex", flexDirection: "column", padding: "0 .5rem", opacity: isActive ? 1 : 0, transition: "opacity 0.5s ease-in-out" }}>
        <h4 style={{ fontSize: "1.3rem", lineHeight: "1.4", whiteSpace: "pre-wrap", maxWidth: "100%", marginTop: "1rem", fontWeight: "600", textAlign: "left" }}>
          {item.title}
        </h4>
        <p style={{ marginTop: ".2rem", color: "var(--color-text3)", lineHeight: "1.4", fontSize: "1.1rem", textAlign: "left" }}>
          {item.text}
        </p>
      </div>

    </article>
  )

  const BenefitsSectionPhone = ({  }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const activeIndexRef = useRef(0);
    const scrollContainerRef = useRef(null);
    const updateActiveIndex = () => {
      if (scrollContainerRef.current) {
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const itemWidth = scrollContainerRef.current.offsetWidth;
        const newActiveIndex = Math.round(scrollLeft / itemWidth);
        activeIndexRef.current = newActiveIndex;
        setActiveIndex(newActiveIndex);
      }
    };

    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', updateActiveIndex);
        return () => scrollContainer.removeEventListener('scroll', updateActiveIndex);
      }
    }, []);

    return (
      <section aria-label={"Benefits"}>
        <PageDivider />
        <SectionHeader label={"Benefits"} />
        <h3 className="headerText">What this can do for you</h3>
        <Spacer />
        <div className="horizontalScrollContainer" style={{ display: 'flex', alignItems: 'flex-start', overflowX: 'auto', width: '100%', paddingBottom: '20px', scrollSnapType: 'x mandatory' }}
          ref={scrollContainerRef}
          aria-label="Scrollable benefits list"
          role="region"
          tabIndex="0"
        >
          {dataBenefits.map((item, index) => (
            <TileBenefits_Phone key={index} item={item} index={index} isActive={activeIndexRef.current === index} role="listitem" />
          ))}
        </div>
      </section>
    );
  };


  const TileFaq = ({ item, index, isOpen, toggleItem }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
      if (isOpen) {
        const contentHeight = contentRef.current.scrollHeight;
        setHeight(contentHeight);
      } else {
        setHeight(0);
      }
    }, [isOpen]);

    const renderTextWithUnderline = (text) => {
      const lines = text.split('\n');
      return lines.map((line, lineIndex) => {
        const parts = line.split('__');
        const renderedParts = parts.map((part, partIndex) => 
          partIndex % 2 === 0 ? part : <span key={`${lineIndex}-${partIndex}`} style={{ textDecoration: "underline" }}>{part}</span>
        );
        return (
          <React.Fragment key={lineIndex}>
            {lineIndex > 0 && <br />}
            {renderedParts}
          </React.Fragment>
        );
      });
    };

    return (
      <div role="listitem" style={{ width: "100%" }}>
        <h3>
          <button style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "left", padding: "1.5rem 1rem", cursor: "pointer", backgroundColor: "transparent", border: "none", borderTop: index !== 0 ? '1px solid var(--color-separatorOpaque)' : 'none' }}
            aria-controls={`content-${index}`}
            onClick={() => toggleItem(index)}
            
          >
            <span style={{ fontWeight: "600", color: 'var(--color-text2)', fontSize: "1.1rem", textAlign: "left" }}>
              {item.title}
            </span>
            <span aria-hidden="true" style={{ marginLeft: '10px' }}>
              {isOpen ? <ChevronUp size={20} color={'var(--color-primary)'} /> : <ChevronDown size={20} color={'var(--color-primary)'}/>}
            </span>
          </button>
        </h3>
        <div
          id={`content-${index}`}
          role="region"
          aria-labelledby={`heading-${index}`}
          style={{ overflow: "hidden", transition: 'height 300ms ease-in-out, opacity 300ms ease-in-out', height: `${height}px`, opacity: isOpen ? 1 : 0, padding: "0 1rem" }}
          ref={contentRef}
        >
          <div style={{ paddingBottom: '20px' }}>
            <p style={{ color: 'var(--color-text3)', lineHeight: "1.4", fontSize: "1rem", textAlign: "left" }}>
              {renderTextWithUnderline(item.description)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const FaqSection = ({  }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
      setOpenIndex(prevIndex => prevIndex === index ? null : index);
    };

    const data = [
      {
        category: "Output",
        title: "What is School GOAT?",
        description: "School GOAT is a personal assistant specifically for students. It's designed to help you with all aspects of your school work and make your life easier.",
      },
      { 
        title: "What does it do?",
        description: "__A lot.__ It will take notes for you - both in class and from materials. It will also: solve problems for you, prepare you for exams, and improve your essays.",
      },
      {
        title: "What level classes can it handle?",
        description: "__School GOAT can handle any level of class__. We have members in high school all the way up to the Ivy League and PhD programs.",
      },
      { 
        title: "Can I try it for free?",
        description: "__Yes!__ Just sign in and you'll be able to try it out",
      },
      { 
        title: "Can I get paid for referrals?",
        description: "__Yes!__ When you sign in you'll be given your own referral link. You'll get paid each time someone subscribes via your link!",
      },
    ]

    return (
      <section aria-label={"FAQs"} style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
        <PageDivider />
        <SectionHeader label={"FAQs"} />

        <h3 className="headerText" style={{ marginBottom: isPhoneAndBelow ? '2rem' : '3rem' }}>
          Common questions
        </h3>
        <div style={{ maxWidth: "700px", paddingTop: '.5rem', paddingBottom: '.5rem', display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center"}}
          role="list"
        >
          {data.map((item, index) => (
            <TileFaq 
              key={index} 
              item={item} 
              index={index} 
              isOpen={openIndex === index} 
              toggleItem={toggleItem}
            />
          ))}
        </div>
      </section>
    );
  }

  const CTASection = () => {
    return (
      <section aria-label={"Start now"}>
        <PageDivider />
        <SectionHeader label={"Start now"} />

        
        <div style={{ display: "flex", flexDirection: "column", gap: '1.5rem', alignItems: "center"}}
          aria-labelledby="cta-heading"
        >
          <h3 id="cta-heading" className="headerText"
            >Interested?<br />It's easy to start
          </h3>
          <p style={{ fontSize: !isMobileAndBelow ? "1.4rem" : "1.2rem", lineHeight: "1.6", textAlign: "center", maxWidth: "600px", color: "var(--color-text3)" }}
            >Just click below to get started.<br/><span style={{ textDecoration: "underline" }}>And remember, it's free to start.</span>
          </p>
          <Spacer />
          <CTAButton label="Get started now" />
          <Spacer />
          <Spacer />
          <Spacer />
          <footer style={{ marginTop: '2rem', textAlign: 'center' }}>
            <p style={{ fontSize: '0.9rem' }}>© DealStream LLC. All rights reserved.</p>
            <div style={{ display: 'flex', gap: '.3rem', justifyContent: 'center', marginTop: '0.5rem', fontSize: '0.8rem', color: 'var(--color-text3)' }}>
              <a href={Routes.TERMS} style={{ textDecoration: 'none', color: 'inherit' }}>Terms of Service</a>
              <span>•</span>
              <a href={Routes.PRIVACY} style={{ textDecoration: 'none', color: 'inherit' }}>Privacy</a>
              <span>•</span>
              <a href={Routes.REFUND} style={{ textDecoration: 'none', color: 'inherit' }}>Refund</a>
            </div>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <div id="wrapper" >


      
      <NavBar />
      
      <div id="main" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "var(--color-backgroundSecondary)", paddingTop: 0}}>
        <div className="inner" style={{   }}>

          <HeroSection />
          <HowItWorksSection />

          {isPhoneAndBelow ? <BenefitsSectionPhone /> : <BenefitsSection />}
          <FaqSection />
          <CTASection />
          
        </div>
      </div>

      <AuthModal open={openAuthModal} setOpen={setOpenAuthModal} />
    </div>
  );
};

export default LandingScreen;





    // const checkoutAbandoned = searchParams.get(SEARCH_PARAMS.checkout_abandoned);
    // if (checkoutAbandoned) {
    //   setOpenAbandonedCheckoutModal(true);
    //   navigate('/', { replace: true }); // Clean URL
    // }
      {/* <AbandonedCheckoutModal open={openAbandonedCheckoutModal} setOpen={setOpenAbandonedCheckoutModal} /> */}






// const EmailLoginComponent = () => {
//   const auth = getAuth();
//   const provider = new GoogleAuthProvider();

//   const login_Google = async () => {
//     try {
//       await signInWithPopup(auth, provider);
//     } 
//     catch (error) {
//       alert("Error signing in with Google");
//     }
//   };

//   const buttonContainerStyle = { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexBasis: "200px", maxWidth: "300px", flexGrow: 1, height: "3.5rem", fontSize: "1.1rem", color: 'var(--color-text1)', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: ".5rem", cursor: 'pointer', fontWeight: "500", position: 'relative', border: '1px solid var(--color-text3)', boxShadow: `var(--shadow)`, transition: 'background-color 0.3s ease', gap: '8px' }

//   return (
//     <div style={{ width: "100%", margin: '0 auto', gap: "1rem", display: "flex", flexDirection: "row", justifyContent: "center", gap: "1rem", flexWrap: "wrap" }}>

//       { !user ?
//         <>
//           <button style={buttonContainerStyle}
//             onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
//             onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//             onClick={() => setOpenAuthModal(true)}
//           >
//             <Mail style={{ width: '1.2rem', height: '1.2rem', color: 'var(--color-primary)', marginRight: '8px' }} />
//             Enter with email
//           </button>
          
//           <button style={buttonContainerStyle}
//             onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
//             onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//             onClick={login_Google}  
//           >
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="24px" height="24px">
//               <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"/>
//               <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"/>
//               <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"/>
//               <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"/>
//             </svg>
//             Enter with Google
//           </button>
//         </>
//       :
//         <button style={{ ...buttonContainerStyle, color: 'var(--color-primary)', fontWeight: '600' }}
//           onClick={() => navigate(Routes.APP)}
//         >
//           Enter app
//           <ArrowRight size={16} color="var(--color-primary)" />
//         </button>
//       }

//     </div>
//   );
// };


// import Uni1 from '../assets/uni1.png.webp';
// import Uni2 from '../assets/uni2.png.webp';
// import Uni3 from '../assets/uni3.png.webp';
// import Uni4 from '../assets/uni4.png.webp';
// import Uni5 from '../assets/uni5.png.webp';
// import Uni6 from '../assets/uni6.png.webp';


// const TrustedBySection = () => {
//   const universities = [
//     { name: 'Princeton', abbr: 'P', image: Uni1 },
//     { name: 'Harvard', abbr: 'H', image: Uni2 },
//     { name: 'Berkeley', abbr: 'Cal', image: Uni3 },
//     { name: 'Yale', abbr: 'Y', image: Uni4 },
//     { name: 'Virginia', abbr: 'V', image: Uni5 },
//     { name: 'Michigan', abbr: 'M', image: Uni6 }
//   ];

//   return (
//     <div style={{maxWidth: '700px', margin: '0 auto', marginTop: isPhoneAndBelow ? "1rem" : "2rem", width: "100%" }}>
//       {/* <h5 style={{textAlign: 'center', fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '1.5rem', fontWeight: '600'}}
//         >Trusted by students at top schools across the country
//       </h5>
//        */}
//       <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', maxWidth: '700px', margin: '0 auto', width: "100%", gap: '1rem'}}>
//         {universities.map((uni) => (
//           <div key={uni.name} style={{opacity: '0.7', transition: 'opacity 0.2s ease', cursor: 'pointer', flex: 1, display: 'flex', justifyContent: 'center', }}>
//             <img 
//               src={uni.image}
//               alt={`${uni.name} logo`}
//               style={{width: '40%', aspectRatio: '1/1', objectFit: 'contain', filter: 'grayscale(100%)'}}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };


// const HowItWorksSection = ({  }) => {
//   const [ videoUrl, setVideoUrl ] = useState(null);
//   const data = useMemo(() => [
//     {
//       title: `The program will take notes for you **in class**`,
//       description: "The program listens to your class and automatically takes detailed notes for you. Meaning you can focus on understanding the material without having to waste time and energy on taking notes.",
//       component: <Mic color={`var(--color-white)`} size={25} />,
//       image: howto_Class,
//       videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_InClass.mp4",
//       alt: "Image of a person taking notes in class",
//     },
//     {
//       title: `Turns your **assigned readings** into skimmable notes`,
//       description: "Speed up your learning process and save hours of work by converting your class readings into organized, structured notes ready for your review.",
//       component: <FileIcon color={`var(--color-white)`} size={25} />,
//       image: howto_Text,
//       videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ReadingPDF.mp4",
//       alt: "Image of a dense textbook page",
//     },
//     {
//       title: `Converts your **recorded lectures** into readable notes`,
//       description: "By transforming online video lectures into concise, structured notes, you can learn the material in minutes without having to watch the whole lecture.",
//       component: <FileIcon color={`var(--color-white)`} size={25} />,
//       image: howto_Files,
//       videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_LectureFile.mp4",
//       alt: "Image of a list of lecture files on computer screen",
//     },
//     {
//       title: `Shortcuts **online articles** and research papers`,
//       description: "Quickly transform complex research papers and articles into concise, structured summaries, helping you grasp key concepts efficiently.",
//       component: <FileIcon color={`var(--color-white)`} size={25} />,
//       image: howto_Google,
//       videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
//       alt: "Image of Google search results",
//     },
//   ], []);

//   const formatTitle = (title) => {
//     const parts = title.split('**');
//     return parts.map((part, index) => 
//       index % 2 === 0 ? (
//         <span key={index}>{part}</span>
//       ) : (
//         <span key={index} style={{ color: 'var(--color-primary)', fontWeight: '600', backgroundColor: 'var(--color-primaryLight)', padding: '2px 6px', borderRadius: '5px' }}>{part}</span>
//       )
//     );
//   };

//   const Tile = ({ item, index }) => {
//     return (
//       <>
//         <article className='computerOnly'
//           style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", maxWidth: "100%", gap: "2%", marginBottom: "2rem" }}
//         >
//           <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", textAlign: "left" }}>
//             <h4 style={{ fontSize: "1.8rem", lineHeight: "1.2", whiteSpace: "pre-wrap", maxWidth: "450px", margin: "0 0 0.5rem 0" }}>
//               {formatTitle(item.title)}
//             </h4>
//             <p style={{ marginTop: ".5rem", color: "var(--color-text3)", lineHeight: "1.4", fontSize: "1rem" }}>
//               {item.description}
//             </p>
//             <button style={{ marginTop: "1rem", padding: "0.5rem 1rem", borderRadius: "0.5rem", cursor: "pointer", fontSize: ".9rem", display: "flex", flexDirection: "row", alignItems: "center", gap: ".5rem", backgroundColor: "var(--color-background)", color: "var(--color-text3)", border: "1px solid var(--color-backgroundTertiary)" }}
//               onClick={() => setOpenAuthModal(true)}
//               aria-label={`Try ${item.title} feature now`}
//             >
//               Try it now
//               <ArrowRight size={15} aria-hidden="true" />
//             </button>
//           </div>
//           <button style={{ position: "relative", width: "50%", aspectRatio: "4/3", cursor: "pointer", padding: 0, margin: 0, border: "none", backgroundColor: "transparent" }}
//             onClick={() => setVideoUrl(item.videoUrl)}
//             role="button"
//             aria-label={`Play video about ${item.title}`}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter' || e.key === ' ') {
//                 e.preventDefault();
//                 setVideoUrl(item.videoUrl);
//               }
//             }}
//           >
//             <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
//               src={item.image}
//               alt={item.alt}
//             />
//             <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "45px", height: "45px", borderRadius: "50%", backgroundColor: "rgba(255,255,255,0.8)", display: "flex", justifyContent: "center", alignItems: "center" }}
//               aria-hidden="true"
//             >
//               <div style={{ width: 0, height: 0, borderTop: "10px solid transparent", borderBottom: "10px solid transparent", borderLeft: "15px solid var(--color-primary)", marginLeft: "3px" }} />
//             </div>
//           </button>
//         </article>

//         <article className='phoneOnly'
//           style={{ flexDirection: "column", alignItems: "center", justifyContent: "flex-start", maxWidth: "90%", gap: "1rem", marginTop: "2rem", marginBottom: "0rem", }} 
//         >
//           <button style={{ position: "relative", width: "100%", aspectRatio: "4/2", cursor: "pointer", padding: 0, margin: 0, border: "none", backgroundColor: "transparent" }} 
//             onClick={() => setVideoUrl(item.videoUrl)}
//             aria-label={`Play video about ${item.title}`}
//             onKeyDown={(e) => {
//               if (e.key === 'Enter' || e.key === ' ') {
//                 e.preventDefault();
//                 setVideoUrl(item.videoUrl);
//               }
//             }}
//           >
//             <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }} 
//               src={item.image} 
//               alt={item.alt}
//             />
//             <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "45px", height: "45px", borderRadius: "50%", backgroundColor: "rgba(255,255,255,0.8)", display: "flex", justifyContent: "center", alignItems: "center" }}
//               aria-hidden="true"
//             >
//               <div style={{ width: 0, height: 0, borderTop: "10px solid transparent", borderBottom: "10px solid transparent", borderLeft: "15px solid var(--color-primary)", marginLeft: "3px" }} />
//             </div>
//           </button>
//           <div style={{ display: "flex", flexDirection: "column", width: "100%", }}>
//             <h4 className='componentHeaderText' style={{ fontSize: "1.5rem", lineHeight: "1.4", whiteSpace: "pre-wrap", maxWidth: "100%",}}
//               >{formatTitle(item.title)}
//             </h4>
//             <p className='componentBodyText' style={{ marginTop: ".2rem", color: "var(--color-text3)", lineHeight: "1.4", fontSize: "1rem", }}
//               >{item.description}
//             </p>
//             <button style={{ marginTop: ".8rem", padding: "0.5rem .5rem", borderRadius: "0.5rem", cursor: "pointer", fontSize: ".9rem", display: "flex", flexDirection: "row", alignItems: "center", gap: ".5rem", 
//               color: "var(--color-text3)", border: "1px solid var(--color-backgroundTertiary)", justifyContent: "center" , backgroundColor: "var(--color-background)", alignSelf: "flex-end",  }} 
//               onClick={() => setOpenAuthModal(true)} 
//               aria-label={`Try ${item.title} feature now`}
//             >
//               Try it now
//               <ArrowRight size={15} aria-hidden="true" />
//             </button>
//           </div>
//         </article>

//       </>
//     );
//   }

// return (
//   <section aria-label={"How it works"}>
//     <PageDivider />
//     <h2 className="pageNumberText" 
//       >{"How it works"}
//     </h2>
//     <h3 className="headerText"
//       >The program<br/>takes notes <u>for you</u>
//     </h3>
//     <p className="spacer" aria-hidden="true">&nbsp;</p>
//     <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "3rem"}} 
//       role="list" 
//       aria-label="How it works features"
//     >
//       {data.map((item, index) => <Tile key={index} item={item} index={index} /> )}
//     </div>

//     <VideoViewer videoUrl={videoUrl} setVideoUrl={setVideoUrl}/>

//   </section>
// )
// }


// const Tile = ({ item, index, isOpen, toggleItem }) => {
//   const contentRef = useRef(null);
//   const [height, setHeight] = useState(0);

//   useLayoutEffect(() => {
//     if (isOpen) {
//       const contentHeight = contentRef.current.scrollHeight;
//       setHeight(contentHeight);
//     } else {
//       setHeight(0);
//     }
//   }, [isOpen]);

//   const renderTextWithUnderline = (text) => {
//     const lines = text.split('\n');
//     return lines.map((line, lineIndex) => {
//       const parts = line.split('__');
//       const renderedParts = parts.map((part, partIndex) => 
//         partIndex % 2 === 0 ? part : <span key={`${lineIndex}-${partIndex}`} style={{ textDecoration: "underline" }}>{part}</span>
//       );
//       return (
//         <React.Fragment key={lineIndex}>
//           {lineIndex > 0 && <br />}
//           {renderedParts}
//         </React.Fragment>
//       );
//     });
//   };

//   return (
//     <div role="listitem">
//       <h3>
//         <button style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "left", padding: "1rem 0", cursor: "pointer", backgroundColor: "transparent", border: "none", borderTop: index !== 0 ? '1px solid var(--color-separator)' : 'none' }}
//           aria-controls={`content-${index}`}
//           onClick={() => toggleItem(index)}
          
//         >
//           <span className='componentBodyText' style={{ fontWeight: "400", color: 'var(--color-text1)' }}>
//             {item.title}
//           </span>
//           <span aria-hidden="true" style={{ marginLeft: '10px' }}>
//             {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
//           </span>
//         </button>
//       </h3>
//       <div
//         id={`content-${index}`}
//         role="region"
//         aria-labelledby={`heading-${index}`}
//         style={{ overflow: "hidden", transition: 'height 300ms ease-in-out, opacity 300ms ease-in-out', height: `${height}px`, opacity: isOpen ? 1 : 0 }}
//         ref={contentRef}
//       >
//         <div style={{ paddingBottom: '20px' }}>
//           <p className='componentBodyText' style={{ color: 'rgba(0,0,0,.6)', lineHeight: "1.4" }}>
//             {renderTextWithUnderline(item.description)}
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// const FaqSection = ({  }) => {
//   const [openIndex, setOpenIndex] = useState(null);

//   const toggleItem = (index) => {
//     setOpenIndex(prevIndex => prevIndex === index ? null : index);
//   };

//   const data = [
//     {
//       category: "Output",
//       title: "What do the notes look like?",
//       description: "__The notes are structured exactly like you want them.__ They are organized into sections, with headers and nested bullets that follow the lecture material. They are thorough and formatted cleanly, perfect for studying.",
//     },
//     { 
//       category: "Accuracy",
//       title: "How accurate are the notes?",
//       description: "__Extremely accurate.__ We employ the latest technologies to ensure that the notes we provide you are of the highest quality. Occasionally, a random acronym may be a bit off. Other than that, we've had no reported issues.",
//     },
//     {
//       category: "Intelligence",
//       title: "What level classes can it handle?",
//       description: "__Our program can handle any level of class__. We have members in high school all the way up to the Ivy League and PhD programs. So no matter what level, it can keep up with your class. __And similarly, it can handle any subject__ (as long as it's taught in English). Our members have used it in all types of subjects: Economics, Math, CS, Engineering, History, Psychology, Humanities, and more. Any sort of lecture-based class is a good fit.",
//     },
//     {
//       category: "Ease",
//       title: "How easy is the program to use?",
//       description: "__Very easy.__ To take notes, all you have to do is click 'Start' and enter the name of the class. Then click 'Done' when class is over. The notes will be generated automatically and sent to you via email. ",
//     },
//     {
//       category: "Equipment",
//       title: "Do I need any special equipment?",
//       description: "__No__, all you need is your laptop or phone.",
//     },
//     {
//       category: "Environment",
//       title: "Will it work in big lecture halls?",
//       description: "__Yes, members have used our program in all kinds of classroom settings__ - including major lecture halls without issues. Modern laptops and phones have sufficient audio recording capabilities to pick up lecture content in most any setting. That, plus our program does not need as loud of audio as a human would need to understand the material. Of course if you are concerned, sitting closer to the front always helps.",
//     },
//     {
//       category: "Flexibility",
//       title: "What file formats do you offer?",
//       description: "We offer both __PDF and Word Docs__. So you can download and edit your notes as you see fit.",
//     },
//     {
//       category: "Parents",
//       title: "How can I get my parents to pay?",
//       description: "Here are some points you can bring up with your parents:\n\n1) __Better grades__ - How you do in school (i.e., the grades you get) is directly linked with the quality of your future life. Your grades impact where you go to college, the jobs you get, the friends you have, the lifestyle you can live. In that context, investing in getting good grades is smart. And at just $10/month, using this program to maximize your grades is a deal. Similarly, if you are spending thousands of dolllars on college, again, $10 to make sure you get the most out of it is a no-brainer. On top of that, as this grows in adoption, you will soon be at a disadvantage in class if you are not using this. \n\n2) __Better performance__ - Money aside, this helps you make the most of your class time. Constantly writing things down in class is a bad use of time. It distracts you from actual learning, in favor of just constant typing. In using our automated note-taking, you can focus on absorbing what is being taught and actually learning. Plus, it's then easier to be an active participant in class. Ultimately, this will help you perform better in school. \n\n3) __More energy + less stress__ - School can be stressful. It's draining to sit there for hours a day. And even more so when you have to be constantly taking notes. This program lets you rest easy in class, knowing you will have all the info you need, even if you are having an off day. This leaves you with more energy to tackle your assignments later in the day and generally have one less thing to stress about.",
//     },
//     {
//       category: "Permission",
//       title: "Can I use this in class?",
//       description: "None of our members have reported any issues with using this in class. If you want to be sure, just confirm with your professor! A few points to emphasize with them: \n\n1) No audio recording or transcript of the class is being published. The only output is the notes you are receiving.\n\n2) Freeing students from constant note-taking allows them to be more engaged and active participants in class.\n\nIf they have any questions, I'm happy to chat with them. Feel free to connect us (ryan@effortlessnotes.com)!",
//     },
//     {
//       category: "Opportunity",
//       title: "Can I get paid to help?",
//       description: "Yes! We offer a referral program that __will pay you $10 per user__ that you bring to the site. You are welcome to invite friends, share with classmates, and post to social media. Simply log in and you will get access to everything you need to get started.",
//     },
//   ]

//   return (
//     <section aria-label={"FAQs"}>
//       <PageDivider />
//       <h2 className="pageNumberText"
//         >{"FAQs"}
//       </h2>
//       <h3 className="headerText" style={{ marginBottom: '3rem' }}>
//         Common questions<br />from students
//       </h3>
//       <div className='bubbles__container' >
//         <div className='bubbles' style={{ maxWidth: "700px", paddingTop: '.5rem', paddingBottom: '.5rem', boxShadow: "none", }}
//           role="list"
//         >
//           {data.map((item, index) => (
//             <Tile 
//               key={index} 
//               item={item} 
//               index={index} 
//               isOpen={openIndex === index} 
//               toggleItem={toggleItem}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// }
