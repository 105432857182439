'use client';
import { Drawer } from 'vaul';
import React, { useState, useEffect, useContext } from 'react';
import { Check, ChevronDown } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import { SEARCH_PARAMS } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import { API, dbRefs } from '../misc/constants';
import { logPaywallAction, sendSlackNotification } from '../misc/utils';
import { PAYWALL_EVENTS, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';

const priceIdLive = 'pri_01jknvbmr1x1vns0h81j16ydf9';
const priceIdTest = 'pri_01jkjxe18423s506wz907v8ed2';
const priceId = process.env.REACT_APP_NODE_ENV === 'production' ? priceIdLive : priceIdTest;

const BodyTextStyle = { fontSize: '1.1rem', lineHeight: '1.75', marginBottom: '1.25rem' }

const HardPaywall = () => {
  const { user, paddle } = useContext(UserContext);
  const { isPhoneAndBelow } = useBreakpoint();
  const [isVisible, setIsVisible] = useState(false);
  const [priceObj, setPriceObj] = useState(null);
  const userCountryCode = user?.authData?.country_code || 'US';
  const [ userCount, setUserCount ] = useState(null);

  useEffect(() => {
    setTimeout(() => { setIsVisible(true) }, 50);
  }, []);

  useEffect(() => {
    getPriceObj();
  }, [userCountryCode, paddle]);

  useEffect(() => {
    initLogging();
  }, []);

  const getPriceObj = async () => {
    if ( !paddle ) { console.log('no paddle'); return; }

    try {
      const result = await paddle.PricePreview({items: [{ priceId: priceId, quantity: 1 }], address: { countryCode: userCountryCode }});
      const results = result.data;

      const outputObj = {
        totalUnformatted: Number((results?.details?.lineItems?.[0]?.totals?.total / 100).toFixed(2)),
        totalFormatted: results?.details?.lineItems?.[0]?.formattedTotals?.total,
        currencySymbol: results?.details?.lineItems?.[0]?.formattedTotals?.total.slice(0, 1),
        interval: results?.details?.lineItems?.[0]?.price?.billingCycle?.interval,
        currencyCode: results?.currencyCode
      }

      setPriceObj(outputObj);
    }
    catch (error) {
      try {
        const result = await paddle.PricePreview({ items: [{ priceId: priceId, quantity: 1 }], address: { countryCode: 'US' } });
        const results = result.data;

        const outputObj = {
          totalUnformatted: Number((results?.details?.lineItems?.[0]?.totals?.total / 100).toFixed(2)),
          totalFormatted: results?.details?.lineItems?.[0]?.formattedTotals?.total,
          currencySymbol: results?.details?.lineItems?.[0]?.formattedTotals?.total.slice(0, 1),
          interval: results?.details?.lineItems?.[0]?.price?.billingCycle?.interval,
          currencyCode: results?.currencyCode
        }

        setPriceObj(outputObj);
      }
      catch (error) {
        console.error('Error getting price preview:', error);

        const outputObj = {
          totalUnformatted: 9.99,
          totalFormatted: '$9.99',
          currencySymbol: '$',
          interval: 'month',
          currencyCode: 'USD'
        }

        setPriceObj(outputObj);
      }
    }
  }

  const initLogging = async () => {
    Promise.allSettled([
      axios.get(`${API}/generalCount`, { params: { 
        matchObj: JSON.stringify({ }),
        dbRef: dbRefs.users
      }}),
      axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: user?._id },
        updateObj: { $set: { 'testId': '20250209_hardPaywall' } },
        dbRef: dbRefs.users,
      }),
      logPaywallAction({ user, eventType: PAYWALL_EVENTS.PAYWALL_SHOWN, funnel: 'hardPaywall' }),
    ]).then(results => {
      const userCountResults = results[0]?.value?.data || 28267;
      setUserCount(userCountResults);
    });
  }


  
  const handlePlanClick = async () => {
    if (!paddle) return;

    try {
      await Promise.allSettled([
        logPaywallAction({ user, eventType: PAYWALL_EVENTS.CHECKOUT, type: 'paddle'}),
        // sendSlackNotification(
        //   `Going to checkout\nEmail: ${user?.email}\nCountry: ${user?.authData?.country_name}`, 
        //   SLACK_WEBHOOK_CHANNELS.stripe
        // )
      ]);
    }
    catch (error) {
    }

    // Pixel.track('InitiateCheckout');

    const checkoutOptions = {
      items: [ { priceId: priceId, quantity: 1 } ],
      
      settings: {
        theme: 'light',  
        successUrl: `${process.env.REACT_APP_FRONTEND_URL}/app/home?${SEARCH_PARAMS.checkout_success}=${user?._id}&${SEARCH_PARAMS.payment_provider}=paddle`,
        // variant: "one-page"
      },
      customer: {
        email: user?.email || '',
        address: {
          postalCode: user?.authData?.postal || '',
          countryCode: user?.authData?.country_code || '',
        }
      },
      discountId: "",
      customData: {
        mongo_id: user?._id,
        mongo_email: user?.email,
      }
      };

    paddle.Checkout.open(checkoutOptions);
  };
  
  
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', minHeight: '100dvh', backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', padding: '16px', zIndex: 1000, alignItems: 'center', justifyContent: 'center', 
      opacity: isVisible ? 1 : 0, 
      transition: 'opacity 300ms ease-in-out',
      ...isPhoneAndBelow && { padding: "1rem", }
    }}>

      { priceObj &&
        <div className="safe-area-container" style={{maxWidth: '500px', margin: '0 auto', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', padding: '1.8rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', overflowY: 'auto', transition: "all 0.3s ease-in-out", }}>

          <div style={{ marginBottom: '1rem' }}>
            <div style={{ fontSize: '1.75rem', fontWeight: 'bold', letterSpacing: '-0.025em', marginBottom: '8px' }}
              >Update
            </div>
            <div style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text3)' }}
              >{new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}
            </div>
          </div>

          <p style={BodyTextStyle}>
            We've been blown away by the response to School Goat — thousands are joining each day! To keep up with demand, we now have new members start with a trial.
          </p>

          <p style={BodyTextStyle}>

            The trial is 4 days, totally free, and gives you unlimited access. It's easy to cancel and if you continue it's only {priceObj?.currencySymbol}{priceObj?.totalUnformatted}/{priceObj?.interval}{ priceObj?.currencySymbol === '$' && priceObj?.currencyCode !== 'USD' ? ` (${priceObj?.currencyCode})` : ''}.
          </p>

          <p style={BodyTextStyle}>
            We know asking for payment info upfront isn't ideal, but this ensures we can be there for our members who count on us.
          </p>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '12px', marginTop: '2rem',}}>
            <button style={{ width: '100%', padding: '16px 24px', borderRadius: '9999px', backgroundColor: 'black', color: 'white', fontSize: '1rem', border: 'none', cursor: 'pointer', marginBottom: '.5rem', transition: 'opacity 0.3s ease',  }}
              onClick={() => handlePlanClick()}
              onMouseEnter={(e) => e.target.style.opacity = '0.5'}
              onMouseLeave={(e) => e.target.style.opacity = '1'}
            >
              {/* 🎉  Join the 25,314 using School Goat */}
              🎉  Join {userCount?.toLocaleString() || `28,267`} using School Goat
            </button>

            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '0.875rem', color: '#6B7280' }}>
              <Check size={16} />
              <p>Nothing due today • Cancel anytime</p>
            </div>

          </div>
        </div>
      }

      <style>
        {`
          @keyframes pulse {
            0% {
              opacity: 1;
              transform: scale(1);
            }
            50% {
              opacity: 0.5;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  );
};

export default HardPaywall;



// const RecentJoinNotification = () => {
//   const [minutes, setMinutes] = useState(1);
//   const [isVisible, setIsVisible] = useState(true);

//   useEffect(() => {
//     const updateMinutes = () => {
//       setMinutes(Math.floor(Math.random() * 3) + 1);
//       setIsVisible(true);
//       setTimeout(() => setIsVisible(false), 5000);
//     };

//     const interval = setInterval(updateMinutes, Math.random() * 30000 + 30000);
//     return () => clearInterval(interval);
//   }, []);

//   if (!isVisible) return null;

//   return (
//     <>
//       <style>
//         {`@keyframes pulse { 0%, 100% { opacity: 1; } 50% { opacity: .5; } }`}
//       </style>
//       <div style={{ position: 'fixed', bottom: '16px', left: '16px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', opacity: isVisible ? 1 : 0, transition: 'opacity 500ms ease-in-out' }}>
//         <div style={{ padding: '16px 12px' }}>
//           <div style={{ width: '8px', height: '8px', backgroundColor: '#22c55e', borderRadius: '50%', animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite' }} />
//         </div>
//         <div style={{ width: '1px', height: '24px', backgroundColor: '#e5e7eb' }} />
//         <div style={{ padding: '8px 16px', fontSize: '14px', color: '#374151' }}>
//           A new member joined {minutes} minute{minutes > 1 ? 's' : ''} ago
//         </div>
//       </div>
//     </>
//   );
// };


// const [secondsAgo, setSecondsAgo] = useState(20);
//   const [isPopoverOpen, setIsPopoverOpen] = useState(false);  

// useEffect(() => {
//   const interval = setInterval(() => {
//     setSecondsAgo(current => {
//       if (current >= 45) return 15;
//       return current + 5;
//     });
//   }, 5000);

//   return () => clearInterval(interval);
// }, []);

// const getTimeText = (seconds) => {
//   if (seconds < 60) {
//     return `${seconds} seconds`;
//   }
//   return `${Math.floor(seconds / 60)} minute${Math.floor(seconds / 60) > 1 ? 's' : ''}`;
// };


            {/* The trial is 7 days, totally free, and gives you unlimited access to all features. It's easy to cancel and we'll remind you along the way. If you continue, it's only {priceObj?.currencySymbol}{priceObj?.totalUnformatted}/{priceObj?.interval}. */}

            
            {/* <div style={{ backgroundColor: '#F9FAFB', padding: '8px 16px', borderRadius: '9999px', display: 'flex', alignItems: 'center', gap: '8px', fontSize: '0.875rem' }}>
              <div style={{ width: '8px', height: '8px', backgroundColor: '#22C55E', borderRadius: '50%', animation: 'pulse 2s infinite' }} />
              <p style={{ fontWeight: 500 }}>
                A student just joined 20s ago
              </p>
            </div> */}


{/* <div style={{ position: 'relative', display: 'inline-block' }}>
<button 
  style={{ background: 'none', border: 'none', padding: '0', font: 'inherit', cursor: 'pointer', textDecoration: 'underline dotted', textUnderlineOffset: '4px', display: 'inline-flex', alignItems: 'center', gap: '4px' }}
  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
  onBlur={() => setTimeout(() => setIsPopoverOpen(false), 200)}
>
  features <ChevronDown size={16} />
</button>
<div style={{ position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)', width: '320px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '16px', marginTop: '8px', zIndex: 50, display: isPopoverOpen ? 'block' : 'none' }}>
  <h4 style={{ fontWeight: 500 }}>Everything you get:</h4>
  <div style={{ marginTop: '16px' }}>
    {[
      '24/7 AI tutoring in any subject',
      'Step-by-step problem solving',
      'Practice problems with explanations',
      'Study guides and summaries',
      'Essay feedback and writing help'
    ].map((feature, index) => (
      <div key={index} style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginBottom: '12px' }}>
        <Check size={16} color="#16A34A" style={{ marginTop: '4px' }} />
        <span>{feature}</span>
      </div>
    ))}
  </div>
</div>
</div> */}