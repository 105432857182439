import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS, PAYWALL_TYPES } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';
import { ArrowRight, Sparkles } from 'lucide-react';
import { Routes } from '../navigation/routes';

const Uploader_Home_Youtube = ({ 
  placeholder = "Paste the URL of the YouTube video you'd like to process...",
  styleOverride = {},
  setIsLoading = () => {}
 }) => {
  
  const { isPhoneAndBelow } = useBreakpoint();
  const { user, setRefreshRecentResources, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const textareaRef = useRef(null);

  const handleClose = () => {
    setFile('');
  };

  function validateYoutubeUrl(url) {
    if (!url) return false;
    
    // Handle both youtu.be and youtube.com URLs
    const patterns = [
      // youtube.com/watch?v= format
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?:.*&)?v=([a-zA-Z0-9_-]{11})(?:&.*)?$/,
      
      // youtu.be/ format
      /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/,
      
      // youtube.com/embed/ format
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/,
      
      // youtube.com/shorts/ format
      /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/
    ];
  
    // Try each pattern
    for (const pattern of patterns) {
      const match = url.trim().match(pattern);
      if (match) {
        return match[1]; // Return the video ID
      }
    }
  
    return false;
  }
  
  // Function to convert any YouTube URL format to watch URL
  function getStandardYoutubeUrl(url) {
    const videoId = validateYoutubeUrl(url);
    if (!videoId) {
      return false;
    }
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  const uploadFile = async () => {

    let resourceObj = null

    try {
      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.resources)
      if ( !hasPermission ) {
        return;
      }

      const trimmedText = getStandardYoutubeUrl(file);

      if (!trimmedText) {
        alert('Please enter a valid YouTube video URL');
        return;
      }

      setIsLoading(true)

      resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.youtube })
      await uploadResourceToS3({ resource_id: resourceObj._id, file: trimmedText, uploadMethod: RESOURCES_UPLOAD_METHODS.youtube })
      await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories  })
      handleClose();
      setRefreshRecentResources(prev => !prev)
      navigate(`/app/resources/${resourceObj._id}/${Routes.SOURCE}`)
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      try {
        const fullMessage = `Error uploading youtube: ${error.message}\nuser: ${user.email}\nfile: ${file}\nUpload method: ${RESOURCES_UPLOAD_METHODS.youtube}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }

      setFile('')
      alert(error.message);
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%',  }}>
      <textarea style={{ position: 'relative', height: '100%', width: '100%', padding: '1rem', resize: 'none', fontSize: isPhoneAndBelow ? '16px' : '1rem', color: 'var(--color-text2)', lineHeight: '1.4', outline: 'none', backgroundColor: 'transparent', ...styleOverride }}
        ref={textareaRef}   
        placeholder={placeholder}
        value={file}
        onChange={(e) => setFile(e.target.value)}
        aria-label="Text to convert"
      >
        
      </textarea>

      <div style={{ position: 'absolute', bottom: '.5rem', right: '.5rem', zIndex: 1, display: file ? 'block' : 'none' }}>
        <button 
          onClick={uploadFile}
          disabled={!file}
          style={{
            padding: '8px 16px',
            fontSize: '1rem', 
            backgroundColor: file ? 'var(--color-text1)' : 'var(--color-backgroundTertiary)',
            color: file ? 'white' : 'var(--color-text3)',
            border: 'none',
            borderRadius: '6px',
            cursor: file ? 'pointer' : 'default',
            transition: 'all 0.2s'
          }}
          onMouseEnter={(e) => { e.target.style.opacity = '0.6'; }}
          onMouseLeave={(e) => { e.target.style.opacity = '1'; }}
        >
          {'Submit'}
        </button>
      </div>
    </div>
    
  );
};

export default Uploader_Home_Youtube;
