import React, { useContext, useEffect, } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import { useState } from 'react';
import { FileCheck, Search, File, Text, User, Home, Sparkles, Menu, GraduationCap, SquarePen, MapPin, FileText, Zap, ShieldCheck, HelpCircle, MessageSquareMore, Calculator, Mic, Upload, FileEdit, Settings, ArrowRight } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import SchoolGoatBg from '../assets/SchoolGoatBg.png';
import { SidebarContainer, SidebarTab, SidebarUnderlay, SidebarCollapsedHeader, SidebarUpgradeButton, SidebarGroupHeader2, SidebarTab2 } from '../components/RouterComponents';
import axios from 'axios';
import { API, dbRefs, SEARCH_PARAMS, stripeSubscriptionStates } from '../misc/constants';
import Physics from '../assets/Physics2.webp';
import War from '../assets/War2.webp';
import Dollar from '../assets/Dollar2.webp';
import { Pixel } from '../misc/analytics';
import HardPaywall from '../modals/HardPaywall';

const tabs = {
  homework: { title: "Solver", icon: <FileCheck />, route: Routes.SOLVE },
  teach: { title: "Tutor", icon: <GraduationCap />, route: Routes.TEACH },
  essayEvaluation: { title: "Writing", icon: <Text />, route: Routes.ESSAY_EVALUATION },
  settings: { title: "Settings", icon: <User />, route: Routes.SETTINGS },
}

const SidebarTile = ({ title, icon, onClick, isActive, rightContent }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '.75rem', cursor: 'pointer', borderRadius: '.5rem', 
      ...(isActive && { backgroundColor: 'var(--color-backgroundTertiary)', })
    }}
      onClick={onClick}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '.95rem', color: 'var(--color-text2)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        { icon && React.cloneElement(icon, { size: 16, color: 'var(--color-text2)', strokeWidth: 1.5, style: { marginRight: '.75rem' } })}
        {title}
        {rightContent}
      </div>
    </div>
  )
}

const SidebarTileNested = ({ title, icon, onClick, isActive, rightContent }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column',  cursor: 'pointer', borderLeft: '1px solid var(--color-separatorOpaque)',  marginLeft: '1rem' 
    }}
      onClick={onClick}
    >

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '.95rem', color: 'var(--color-text2)', padding: '.75rem', marginLeft: '.5rem',
        ...(isActive && { backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem', })
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '.9rem', color: 'var(--color-text2)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
          ...(isActive && { backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem', })
        }}
        >
          {title}
          {rightContent}
        </div>
      </div>
    </div>
  )
}

const exampleNotes = [
  { _id: "42584d00-5705-4356-bbe3-e45c2be5bfdd", title: "World War II", subject: "History", type: "Class Notes", image: War },
  { _id: "ee3df88c-7262-46ab-981a-e69788e662b7", title: "Quantum Physics", subject: "Physics", type: "Class Notes", image: Physics },
  { _id: "bb550a06-5ad3-4071-9041-0d760d1e41c6", title: "Inflation Notes", subject: "Economics", type: "Recorded Lecture", image: Dollar }
];

const HomeRouter = ({ }) => {
  const { user, setUser, refreshRecentResources, setShowPaywallType } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabletAndBelow, } = useBreakpoint();
  const [ activeTab, setActiveTab ] = useState('');
  const [ openSidebar, setOpenSidebar ] = useState(isTabletAndBelow ? false : true);
  const [ underlayIsVisible, setUnderlayIsVisible ] = useState(false);
  const [ data, setData ] = useState([])
  const [ dataCount, setDataCount ] = useState(null)

  const [ showHardPaywall, setShowHardPaywall ] = useState(false);

  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const checkoutSuccess = searchParams.get(SEARCH_PARAMS.checkout_success);
    const paymentProvider = searchParams.get(SEARCH_PARAMS.payment_provider);

    if (checkoutSuccess && checkoutSuccess === user?._id) {
      refreshUser(paymentProvider);
      navigate(`/app/${Routes.HOME}`, { replace: true });
      Pixel.track('Purchase');
    }
  }, [searchParams, user?._id]);

  const refreshUser = (paymentProvider) => {
    console.log('refreshing user')
    setUser({
      ...user,
      isMember: true,
      stripeSubscription: {
        ...user?.stripeSubscription,
        status: stripeSubscriptionStates.active,
        paymentProvider: paymentProvider || null,
      },
    })
  };

  useEffect(() => {
    if (isTabletAndBelow) {
      setOpenSidebar(false);
    }
    else {
      setOpenSidebar(true);
    }
  }, [isTabletAndBelow]);

  useEffect(() => {
    let pathname = location.pathname;
    let lastPart = pathname.split('/').pop();
    setActiveTab(lastPart);

    Pixel.pageView();
  }, [location]);

  useEffect(() => {
    getData()
  }, [refreshRecentResources])

const getData = async () => {
  try {  

    let docsResults = []
    let countResults = 0

    if ( user ) {
      const [docsResult, countResult] = await Promise.all([
        axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'sourceContent.url': { $exists: true } }),
          projectObj: JSON.stringify({ _id: 1, title: 1, category: 1, date: 1, sourceContent: 1 }),
          sortObj: JSON.stringify({ date: -1 }),
          currentPage: 0,
          pageSize: 9,
          dbRef: dbRefs.resources
        }}),
        axios.get(API + '/generalCount', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'sourceContent.url': { $exists: true } }),
          dbRef: dbRefs.resources
        }})
      ]);
    

      docsResults = docsResult.data;
      countResults = countResult.data;
    }

    if (docsResults.length === 0) {
      setData(exampleNotes)
      setDataCount(0)
    }
    else {
      setData(docsResults)
      setDataCount(countResults)
    }
  }
  catch (err) {
    console.error('Error getting resources:', err);
  }
}

const handleTileClick = (route) => {
  navigate(route)

  if (isTabletAndBelow) {
    setOpenSidebar(false);
    setUnderlayIsVisible(false);
  }
}

const handleTileNestedClick = (item) => {
  const path = location.pathname;
  const parts = path.split('/');
  const resourcesIndex = parts.indexOf('resources');
  const isInResources = resourcesIndex >= 0 && parts[resourcesIndex + 1];
  const finalPart = isInResources ? parts[parts.length - 1] : null;
  
  let route;
  if (finalPart) {
    route = finalPart;
  } 
  else {
    route = Routes.SOURCE;
  }

  navigate(`/app/${Routes.RESOURCES}/${item._id}/${route}`)

  if (isTabletAndBelow) {
    setOpenSidebar(false);
    setUnderlayIsVisible(false);
  }
}

  useEffect(() => {
    if ( 
      user?.stripeSubscription?.status == stripeSubscriptionStates.free_trial
      || user?.stripeSubscription?.status == stripeSubscriptionStates.duplicate_account
      || user?.stripeSubscription?.status == stripeSubscriptionStates.canceled
      || user?.stripeSubscription?.status == stripeSubscriptionStates.incomplete
      || user?.stripeSubscription?.status == stripeSubscriptionStates.incomplete_expired 
    ) {
      setShowHardPaywall(true);
    }
    else {
      setShowHardPaywall(false);
    }
  }, [user?.stripeSubscription?.status])
  

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-backgroundSecondary)' }}>

      { showHardPaywall && <HardPaywall /> }

        <div style={{ display: 'flex', flexDirection: 'column', width: '260px', padding: '1rem', backgroundColor: 'var(--color-background)',

        position: !isTabletAndBelow ? 'relative' : 'fixed', width: openSidebar ? '260px' : '0px', 
        overflowY: 'auto', transition: "all 0.3s ease-in-out", left: 0, top: 0, bottom: 0, zIndex: 6, padding: openSidebar ? ".5rem" : "0px", flexShrink: 0,
      }}>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: ".75rem", marginBottom: ".75rem", cursor: "pointer", overflow: 'hidden', height: '40px', marginTop: "0rem", flexShrink: 0 }}
          onClick={() => handleTileClick(Routes.HOME)}
        >
          <img src={SchoolGoatBg} style={{ width: "40px", aspectRatio: "1/1", backgroundColor: "var(--color-text1)", borderRadius: ".75rem", padding: ".2rem",  }} />
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: ".2rem", overflow: 'hidden' }}>
            <span style={{ fontSize: "1rem", fontWeight: "600", color: "var(--color-text1)", letterSpacing: "-0.04rem", whiteSpace: "nowrap", textOverflow: "ellipsis",  }}>School Goat</span>
            <span style={{ fontSize: ".85rem", fontWeight: "400", color: "var(--color-text2)", letterSpacing: "-0.04rem", whiteSpace: "nowrap", textOverflow: "ellipsis",  }}>Your school assistant</span>
          </div>
        </div>


        <SidebarTile
          title="Home"
          icon={<Home size={16} />}
          onClick={() => handleTileClick(Routes.HOME)} 
          isActive={activeTab === Routes.HOME && !location.pathname.includes('resources')}
        />


        { data?.length > 0 &&

          <div style={{ display: 'flex', flexDirection: 'column', margin: '.2rem 0'}}>
            { data.map((item, index) => (
              <SidebarTileNested 
                key={index} 
                title={item.title} 
                onClick={() => handleTileNestedClick(item)}
                isActive={location.pathname.includes(item._id)}
              />
            ))}

            <SidebarTileNested 
              title="See all"
              onClick={() => handleTileClick(Routes.RESOURCES)}
              rightContent={<ArrowRight size={12} style={{ color: 'var(--color-text2)', marginLeft: '.25rem' }} />}
              isActive={location.pathname.split('/').pop() === Routes.RESOURCES}
            />

          </div>
        }

        {Object.values(tabs).map((item, index) => (
          <SidebarTile 
            key={index}
            title={item.title} 
            icon={item.icon}
            onClick={() => handleTileClick(item.route)}
            isActive={activeTab === item.route && !location.pathname.includes('resources')}
          />
        ))}

        <SidebarUpgradeButton />


      </div>


        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: "relative", backgroundColor: 'var(--color-background)' }}>

        <SidebarUnderlay underlayIsVisible={underlayIsVisible} setOpenSidebar={setOpenSidebar} setUnderlayIsVisible={setUnderlayIsVisible} />
        <SidebarCollapsedHeader setOpenSidebar={setOpenSidebar} setUnderlayIsVisible={setUnderlayIsVisible} />

        <Outlet context={{ data, dataCount }} />
      </div>

    </div>
  )
};

export default HomeRouter;


// const SidebarGroupHeader = ({ title, children }) => {
//   return (
//     <div style={{ fontSize: '.9rem', fontWeight: '400', color: 'var(--color-text4)', marginTop: "1rem", marginBottom: ".35rem", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
//       {title}
//       {children}
//     </div>
//   )
// }

        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid var(--color-separatorOpaque)', backgroundColor: 'var(--color-backgroundSecondary)', padding: '.5rem 1rem' }}>
        
        <div style={{ display: 'flex', alignItems: 'center', gap: '.75rem', cursor: 'pointer', height: '100%', }} onClick={() => navigate(Routes.HOME)}>
          <img src={SchoolGoatBg} style={{ width: '32px', height: '32px', borderRadius: '.5rem' }} alt="School Goat Logo" />
        </div>

        <div style={{ display: 'flex', gap: '.5rem', flex: 1, justifyContent: 'flex-start', alignItems: 'center', padding: "0 1rem" }}>
          <button style={{ fontSize: '1rem', color: 'var(--color-text1)', background: 'none', border: 'none', cursor: 'pointer', padding: '.5rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', }}
            onClick={() => navigate(Routes.HOME)}>
            Study
          </button>
          <button style={{ fontSize: '1rem', color: 'var(--color-text1)', background: 'none', border: 'none', cursor: 'pointer', padding: '.5rem' }}
            onClick={() => navigate(Routes.ESSAY_EVALUATION)}>
            Write
          </button>
          <button style={{ fontSize: '1rem', color: 'var(--color-text1)', background: 'none', border: 'none', cursor: 'pointer', padding: '.5rem' }}
            onClick={() => navigate(Routes.SOLVE)}>
            Solve
          </button>
        </div>

        <button style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '.5rem', color: 'var(--color-text1)' }}
          onClick={() => navigate(Routes.SETTINGS)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"/>
            <circle cx="12" cy="12" r="3"/>
          </svg>
        </button>
      </div> */}



// {isTabletAndBelow && (
//   <div style={{ position: 'relative', height: '55px', display: 'flex', alignItems: 'center', padding: '0 1rem', borderBottom: '1px solid var(--color-separatorOpaque)' }}>
//     <button 
//       style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '32px', height: '32px', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', color: 'var(--color-text1)' }}
//       onClick={() => setOpenSidebar(true)}
//       aria-label="Open sidebar"
//     >
//       <Menu size={20} />
//     </button>
//     <h1 style={{ marginLeft: '12px', fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem' }}>
//       {activeTab?.title}
//     </h1>
//   </div>
// )}