export const userData = {
  "_id": "user_12345", 
  "databases": [
    {
      "databaseName": "myDatabase",
      "type": "mongo",
      "lastUpdated": "2025-02-10T12:34:56Z",
      "collections": {
        "users": {
          "fields": {
            "_id": ["string"],
            "email": ["string"],
            "emailVerified": ["boolean"],
            "createdAt": ["string"],
            "categories": ["array", "string", "null"],
            "stripe": ["object", {
              "customer": ["string"],
              "email": ["string"]
            }],
            "stripeSubscription": ["object", {
              "status": ["string"],
              "minutesUsed": ["object", {
                "$numberDouble": ["string"]
              }],
              "plan": ["object", {
                "id": ["string"],
                "amount": ["object", {
                  "$numberInt": ["string"]
                }],
                "currency": ["string"], 
                "interval": ["string"],
                "interval_count": ["object", {
                  "$numberInt": ["string"]
                }],
                "product": ["string"],
                "minutes": ["string", "null"],
                "nickname": ["string", "null"]
              }],
              "billing_cycle_anchor": ["object", {
                "$numberInt": ["string"]
              }],
              "created": ["object", {
                "$numberInt": ["string"]
              }],
              "current_period_end": ["object", {
                "$numberInt": ["string"]
              }],
              "current_period_start": ["object", {
                "$numberInt": ["string"]
              }],
              "customer": ["string"],
              "id": ["string"],
              "latest_invoice": ["string"],
              "latest_invoice_paid": ["string"]
            }],
            "termsAccepted": ["object", {
              "$numberDouble": ["string"]
            }],
            "stripeSubscriptionHistory": ["array", "object", {
              "status": ["string"],
              "minutesUsed": ["object", {
                "$numberInt": ["string"]
              }],
              "plan": ["object", {
                "id": ["string"],
                "amount": ["object", {
                  "$numberInt": ["string"]
                }],
                "currency": ["string"],
                "interval": ["string"],
                "interval_count": ["object", {
                  "$numberInt": ["string"]
                }],
                "product": ["string"],
                "minutes": ["string", "null"],
                "nickname": ["string", "null"]
              }],
              "billing_cycle_anchor": ["object", {
                "$numberInt": ["string"]
              }],
              "created": ["object", {
                "$numberInt": ["string"]
              }],
              "current_period_end": ["object", {
                "$numberInt": ["string"]
              }],
              "current_period_start": ["object", {
                "$numberInt": ["string"]
              }],
              "customer": ["string"],
              "id": ["string"],
              "latest_invoice": ["string"],
              "latest_invoice_paid": ["string"],
              "cancellation_details": ["object", {
                "comment": ["string", "null"],
                "feedback": ["string", "null"],
                "reason": ["string"]
              }],
              "canceled_at": ["object", {
                "$numberInt": ["string"]
              }],
              "discounts": ["array", "any"]
            }],
            "referralCode": ["string"],
            "emailSentCount": ["object", {
              "$numberInt": ["string"]
            }],
            "termsAcceptedPartnerProgram": ["object", {
              "$numberDouble": ["string"]
            }],
            "freeTrialExpiration": ["object", {
              "$numberDouble": ["string"]
            }],
            "emails": ["array", "object", {
              "tag": ["string", "number"],
              "date": ["string"]
            }],
            "authCount": ["object", {
              "$numberInt": ["string"]
            }],
            "authLastDate": ["object", {
              "$numberDouble": ["string"]
            }],
            "authData": ["object", {
              "ip": ["string"],
              "network": ["string"],
              "version": ["string"],
              "city": ["string"],
              "region": ["string"],
              "region_code": ["string"],
              "country": ["string"],
              "country_name": ["string"],
              "country_code": ["string"],
              "country_code_iso3": ["string"],
              "country_capital": ["string"],
              "country_tld": ["string"],
              "continent_code": ["string"],
              "in_eu": ["boolean"],
              "postal": ["string"],
              "latitude": ["object", {
                "$numberDouble": ["string"]
              }],
              "longitude": ["object", {
                "$numberDouble": ["string"]
              }],
              "timezone": ["string"],
              "utc_offset": ["string"],
              "country_calling_code": ["string"],
              "currency": ["string"],
              "currency_name": ["string"],
              "languages": ["string"],
              "country_area": ["object", {
                "$numberInt": ["string"]
              }],
              "country_population": ["object", {
                "$numberInt": ["string"]
              }],
              "asn": ["string"],
              "org": ["string"]
            }],
            "paywall": ["object", {
              "eventCount": ["object", {
                "$numberInt": ["string"]
              }],
              "events": ["array", "object", {
                "event": ["string"],
                "date": ["string"],
                "path": ["string"],
                "funnel": ["string"],
                "type": ["string"],
                "version": ["object", {
                  "$numberInt": ["string"]
                }],
                "message": ["string"]
              }],
              "lastEvent": ["object", {
                "event": ["string"],
                "date": ["string"],
                "path": ["string"],
                "funnel": ["string"],
                "type": ["string"],
                "version": ["object", {
                  "$numberInt": ["string"]
                }]
              }]
            }],
            "usage": ["object", {
              "teach": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_chat": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_test": ["object", {
                "$numberInt": ["string"]
              }],
              "resources": ["object", {
                "$numberInt": ["string"]
              }],
              "resources_youtube": ["object", {
                "$numberInt": ["string"]
              }],
              "resources_file": ["object", {
                "$numberInt": ["string"]
              }],
              "resources_text": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_teach": ["object", {
                "$numberInt": ["string"]
              }],
              "solver": ["object", {
                "$numberInt": ["string"]
              }],
              "resources_recording": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_summary": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_notes": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_flashcards": ["object", {
                "$numberInt": ["string"]
              }],
              "resource_quiz": ["object", {
                "$numberInt": ["string"]
              }],
              "resources_recording_minutes": ["object", {
                "$numberInt": ["string"]
              }]
            }]
          },
          "lastSampled": "2025-02-10T12:34:56Z"
        },
      }
    }
  ]
}


export const outputData = [
  {
    "_id": "viz_1",
    "user_id": "user_12345",
    "dashboard_name": "Monthly Metrics",
    "vizConfig": {
      "chartType": "barChart",
      "label": "Bar Chart of Total Users by Month", 
      "outputStructure": {
        "xField": "month",
        "yField": "totalUsers"
      }
    },
    "rawQuery": "I need a line graph of users by month",
    "refinedQuery": "Generate a line chart showing monthly total users",
    "aggregationPipeline": [
      {
        "$addFields": {
          "createdDate": {
            "$toDate": {
              "$toLong": "$createdAt"
            }
          }
        }
      },
      {
        "$group": {
          "_id": {
            "$dateToString": {
              "format": "%Y-%m",
              "date": "$createdDate"
            }
          },
          "totalUsers": { "$sum": 1 }
        }
      },
      {
        "$project": {
          "month": "$_id",
          "totalUsers": "$totalUsers",
          "_id": 0
        }
      },
      {
        "$sort": { "month": 1 }
      }
    ],
    "createdAt": "2025-02-10T12:34:56Z",
    "updatedAt": "2025-02-10T13:00:00Z"
  },
  {
    "_id": "viz_2",
    "user_id": "user_12345", 
    "dashboard_name": "Monthly Metrics",
    "vizConfig": {
      "chartType": "lineChart",
      "label": "Cumulative Total Users by Month",
      "outputStructure": {
        "xField": "month",
        "yField": "cumulativeTotal"
      }
    },
    "rawQuery": "I need a line graph of cumulative users by month",
    "refinedQuery": "Generate a line chart showing cumulative monthly total users",
    "aggregationPipeline": [
  {
    "$addFields": {
      "createdDate": {
        "$toDate": { "$toLong": "$createdAt" }
      }
    }
  },
  {
    "$group": {
      "_id": {
        "$dateToString": {
          "format": "%Y-%m",
          "date": "$createdDate"
        }
      },
      "monthlyTotal": { "$sum": 1 }
    }
  },
  {
    "$set": { "month": "$_id" }
  },
  {
    "$setWindowFields": {
      "sortBy": { "month": 1 },
      "output": {
        "cumulativeTotal": {
          "$sum": "$monthlyTotal",
          "window": { "documents": [ "unbounded", "current" ] }
        }
      }
    }
  },
  {
    "$project": {
      "_id": 0,
      "month": 1,
      "cumulativeTotal": 1
    }
  }
],
    "createdAt": "2025-02-10T12:34:56Z",
    "updatedAt": "2025-02-10T13:00:00Z"
  },
  {
    "_id": "viz_2",
    "user_id": "user_12345",
    "dashboard_name": "Subscription Status",
    "vizConfig": {
      "chartType": "pieChart", 
      "label": "Distribution of Stripe Subscription Statuses",
      "outputStructure": {
        "nameField": "_id",
        "valueField": "count"
      }
    },
    "rawQuery": "Show me a pie chart of subscription statuses",
    "refinedQuery": "Generate a pie chart showing distribution of stripe subscription statuses",
    "aggregationPipeline": [
      {
        "$group": {
          "_id": "$stripeSubscription.status",
          "count": { "$sum": 1 }
        }
      },
      {
        "$sort": { "count": -1 }
      }
    ],
    "createdAt": "2025-02-10T12:34:56Z",
    "updatedAt": "2025-02-10T13:00:00Z"
  },
  {
    "_id": "viz_3",
    "user_id": "user_12345", 
    "dashboard_name": "Trial Users",
    "vizConfig": {
      "chartType": "barChart",
      "label": "Daily Count of Trial Users",
      "outputStructure": {
        "xField": "_id",
        "yField": "count"
      }
    },
    "rawQuery": "Show me daily count of trial users",
    "refinedQuery": "Generate a bar chart showing daily count of users with trial subscription status",
    "aggregationPipeline": [
      {
        "$match": {
          "stripeSubscription.status": "trialing"
        }
      },
      {
        "$group": {
          "_id": {
            "$dateToString": {
              "format": "%Y-%m-%d",
              "date": {
                "$toDate": { "$toLong": "$createdAt" }
              }
            }
          },
          "count": { "$sum": 1 }
        }
      },
      {
        "$sort": { "_id": 1 }
      }
    ],
    "createdAt": "2025-02-10T12:34:56Z",
    "updatedAt": "2025-02-10T13:00:00Z"
  },
  
]