'use client';
import React, { useContext, useState, useEffect } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { FileText, Image, Text, X, Youtube, Zap } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import Uploader_Home_File from './Uploader_Home_File';
import Uploader_Home_Text from './Uploader_Home_Text';
import FileOverlay from './FileOverlay';
import { Resource_Loader } from './Resource_Loader';
import Uploader_Home_Youtube from './Uploader_Home_Youtube';


const subTabs = {
  file: { _id: RESOURCES_UPLOAD_METHODS.file, title: 'File', icon: <FileText />, },
  text: { _id: RESOURCES_UPLOAD_METHODS.text, title: 'Text', icon: <Text />, },
  // image: { _id: RESOURCES_UPLOAD_METHODS.image, title: 'Image', icon: <Image />, },
  youtube: { _id: RESOURCES_UPLOAD_METHODS.youtube, title: 'Youtube', icon: <Youtube />, },
  quickstart: { _id: RESOURCES_UPLOAD_METHODS.quickstart, title: 'Quickstart', icon: <Zap />, },
}


const Uploader_HomeComponent = ({ setOpenResourceProcessingModal, dataCount }) => {
  const { user,  } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();
  const [ activeSubTab, setActiveSubTab ] = useState(subTabs.file.title);
  const [ open, setOpen ] = useState(false);
  const [ file, setFile ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  
  useEffect(() => {
    if (file) {
      setFile(null)
    }
  }, [activeSubTab])

  return (
    <>

      <FileOverlay 
        handleFile={(file) => {
          setFile(file)
          setActiveSubTab(subTabs.file.title)
        }}
        title="Drop your class file here"
      />

      { isLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 1)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}>
          <Resource_Loader isActive={isLoading} text="Uploading your materials" subText="This may take ~15 seconds depending on size" />

        </div>
      )}

      <div style={{ display: 'flex', gap: '.25rem', border: '1px solid var(--color-separatorOpaque)', borderBottom: 'none', borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', padding: '.75rem', width: "100%", boxShadow: 'var(--borderShadow)' }}>
          { Object.values(subTabs).map(tab => {
            const isActive = tab.title === activeSubTab;
            return (                    
              <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', background: 'none', fontSize: '1rem', color: 'var(--color-text3)', padding: '.5rem .75rem', cursor: 'pointer', border: '1px solid transparent', borderRadius: '30px',
                ...(isActive && { color: 'var(--color-text1)', backgroundColor: 'var(--color-backgroundTertiary)', })
              }}
                key={tab.title}
                onClick={() => setActiveSubTab(tab.title)}
                >
                  { !isPhoneAndBelow &&
                    <>
                      {React.cloneElement(tab.icon, { size: 16, color: isActive ? 'var(--color-text1)' : 'var(--color-text3)', style: { marginRight: '6px' } })}
                      <span>{tab.title}</span>
                    </>
                  }
                  { isPhoneAndBelow &&
                    <span>{tab.title}</span>
                  }
              </button>
            )
          })}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'var(--color-background)', transition: 'all 0.3s', height: !dataCount ? "200px" : isPhoneAndBelow ? "250px" : "300px", borderTopLeftRadius: 0, borderTopRightRadius: 0, width: "100%", overflow: 'hidden', backgroundColor: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)' }}>

          { activeSubTab === subTabs?.file?.title && (
            <Uploader_Home_File 
              file={file}
              setFile={setFile}
              setOpenResourceProcessingModal={setOpenResourceProcessingModal} 
              setIsLoading={setIsLoading}
            />
          )}

          { activeSubTab === subTabs?.text?.title && (
            <Uploader_Home_Text 
              setIsLoading={setIsLoading} 
              placeholder="Paste text from your class materials (readings, textbook chapters, notes, etc.)..."
            />
          )}

          { activeSubTab === subTabs?.youtube?.title && (
            <Uploader_Home_Youtube 
              setIsLoading={setIsLoading} 
              placeholder="Paste the URL of YouTube video..."
            />
          )}

          { activeSubTab === subTabs?.quickstart?.title && (
            <Uploader_Home_Text 
              setIsLoading={setIsLoading}
              placeholder="Enter a topic you'd like to learn (ex: the process of photosynthesis)..."
            />
          )}

        </div>



        {/* { activeSubTab === subTabs?.image?.title && (
          <Uploader_Image setOpen={setOpen} />
        )} */}

        {/* { activeSubTab === subTabs?.youtube?.title && (
          <Uploader_Link setOpen={setOpen} />
        )} */}

    </>
  );
}

export default Uploader_HomeComponent; 

