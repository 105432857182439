import { Button, IconButton } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, MoreVertical, FileText, File, Mic, Ellipsis, FileUp, MessageSquareText, MessageCircleMore, TextCursorInput, Zap, FileCheck, BookOpenCheck, Rabbit, Text, Youtube } from 'lucide-react';
import axios from 'axios';
import { API, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import ResourceInfoModal from '../modals/ResourceInfoModal';
import CustomSelect from './CustomSelect';

const NEW_USER_ID = 'newuserexample'
const SECONDARY_COLUMN_WIDTH = '13rem'
const SECONDARY_COLUMN_FLEX = .5
const TERTIARY_COLUMN_WIDTH = '40px'

const iconDirectory = {
  [RESOURCES_UPLOAD_METHODS.text]: <Text />,
  [RESOURCES_UPLOAD_METHODS.file]: <FileText />,
  [RESOURCES_UPLOAD_METHODS.recording]: <Mic />,
  [RESOURCES_UPLOAD_METHODS.youtube]: <Youtube />,
}

const Tile = ({ item, index, isMobileAndBelow, handleRowClick, handleMoreVerticalClick }) => {

  if ( !isMobileAndBelow ) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: "1.2rem 1.3rem", fontSize: '1rem', borderTop: '1px solid var(--color-background)', cursor: 'pointer', transition: 'background-color 0.3s ease', overflow: 'hidden' }}
        key={index}
        onClick={(e) => handleRowClick(e, item)}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '35px' }}>
          {React.cloneElement(iconDirectory?.[item?.uploadMethod], { size: 17, color: 'var(--color-text3)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
        </div>
        <div style={{ flex: 1, fontSize: '1rem', color: 'var(--color-text1)', lineHeight: '1.3', paddingRight: '1rem' }}>
          {item.title || "Document"}
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: SECONDARY_COLUMN_FLEX, overflow: 'hidden', fontSize: '.9rem', color: 'var(--color-text4)' }}>
          {item.category} 
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: SECONDARY_COLUMN_FLEX, overflow: 'hidden', fontSize: '.9rem', color: 'var(--color-text4)' }}>
          {format(new Date(item.date), "MMM d, yyyy")} 
        </div>
        <div style={{ display: 'flex', alignItems: 'center', width: TERTIARY_COLUMN_WIDTH, justifyContent: 'center' }}>
          <Ellipsis 
            style={{ borderRadius: '50%', padding: '4px', cursor: 'pointer' }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            onClick={(e) => handleMoreVerticalClick(e, item)}  
            size={26} color='var(--color-text3)' strokeWidth={1} 
          />
        </div>
      </div>
    )
  }
  else {
    return (
      <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: "1rem", fontSize: '1rem', borderTop: '1px solid var(--color-background)', cursor: 'pointer', transition: 'background-color 0.3s ease', overflow: 'hidden' }}
      key={index}
      onClick={(e) => handleRowClick(e, item)}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
    >

      <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", overflow: 'hidden', paddingRight: '1rem', flex: 1  }}>
        <div style={{ flex: 1, fontSize: '1rem', color: 'var(--color-text1)',  }}>
          {item.title || "Document"}
        </div>
        <div style={{ width: '100%',  }}>
          <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
          </span>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, }}>
        <Ellipsis onClick={(e) => handleMoreVerticalClick(e, item)}  size={20} color='var(--color-text3)' strokeWidth={1} />
      </div>
    </div>
    )
  }



}




const ResourcesTable = ({ isActive = true, data = [], setData, handleRowClick, }) => {
  const { user } = useContext(UserContext)
  const { isMobileAndBelow,  } = useBreakpoint();
  const [resourceInfoModalId, setResourceInfoModalId] = useState(null);

  const handleMoreVerticalClick = (e, item) => {
    e.stopPropagation();
    if ( item?.user_id == NEW_USER_ID || item?.previewMode ) {
      alert('This is an example file for new users. You can view it, but you cannot edit it. Upload your own files!')
      return
    }
    else {
      setResourceInfoModalId(item._id);
    }
  }

  if ( !isActive ) return null
  return (
    <>
      <div style={{ backgroundColor: 'var(--color-backgroundSecondary)',  overflow: 'hidden', display: 'flex', flexDirection: 'column', position: 'relative'}}
        role="region"
        aria-label="Notes list"
      >

        { data && data.length > 0 && data.map((item, index) => (
          <Tile 
            key={index}
            item={item} 
            index={index} 
            isMobileAndBelow={isMobileAndBelow} 
            handleRowClick={handleRowClick} 
            handleMoreVerticalClick={handleMoreVerticalClick}
          />
        ))}
      </div>



      <ResourceInfoModal 
        resourceInfoModalId={resourceInfoModalId} 
        setResourceInfoModalId={setResourceInfoModalId} 
        setData={setData}
      />
    </>
  );
};

export default ResourcesTable;


// const [typeFilter, setTypeFilter] = useState(null)
//   const [classFilter, setClassFilter] = useState(null)


    // const onPreviousPage = () => {
    //   setCurrentPage((prevPage) => prevPage - 1);
    // };

    // const onNextPage = () => {
    //   setCurrentPage((prevPage) => prevPage + 1);
    // };

      {/* <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem", position: 'sticky', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTop: '1px solid var(--color-separatorOpaque)', marginTop: "auto", position: "absolute", bottom: 0, left: 0, right: 0 }}>
          <button
            style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPageDisplayed === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPageDisplayed === 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
            onClick={onPreviousPage}
            disabled={currentPageDisplayed === 1}
            aria-label="Go to previous page"
            aria-disabled={currentPageDisplayed === 1}
          >
            ←
          </button>
          <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
            Page {currentPageDisplayed} of {totalPages} | {dataCount} items
          </span>
          <button
            style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages - 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
            onClick={onNextPage}
            disabled={currentPageDisplayed === totalPages}
            aria-label="Go to next page"
            aria-disabled={currentPageDisplayed === totalPages}
          >
            →
          </button>
        </nav> */}




{/* <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: isMobileAndBelow ? "1rem" : "1.2rem 1.3rem", fontSize: '1rem', borderTop: index !== 0 ? '1px solid var(--color-separatorOpaque)' : 'none', cursor: 'pointer', transition: 'background-color 0.3s ease', minWidth: 0, overflow: 'hidden' }}
key={index}
onClick={(e) => handleRowClick(e, item)}
onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
>

<div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: 1, minWidth: 0, width: '100%', overflow: 'hidden', minWidth: 0, }}>

  <div style={{display: 'flex', alignItems: 'center', width: '100%', minWidth: 0, overflow: 'hidden'}}>
    { !isMobileAndBelow && (
      <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: '35px' }}>
        {React.cloneElement(iconDirectory[item.uploadMethod], { size: 17, color: 'var(--color-text3)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
      </div>
    )}
    <div style={{ flex: 1, fontSize: '1rem', color: 'var(--color-text1)', minWidth: 0, 
      ...!isMobileAndBelow && { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}
     }}>
      {item.title || "Document"}
    </div>

  </div>

  { isMobileAndBelow && (
    <div style={{ width: '100%', minWidth: 0 }}>
      <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
      </span>
    </div>
  )}
</div>

{ !isMobileAndBelow &&
<>
  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
    <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', }}
      >{item.category} 
    </span>
  </div>

  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
    <span style={{ fontSize: '.9rem', color: 'var(--color-text4)',  }}
      >{format(new Date(item.date), "MMM d, yyyy")} 
    </span>
  </div>
</>
}
<div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
  <IconButton style={{ color: 'var(--color-text3)', borderRadius: '50%', transition: 'background-color 0.3s ease', marginLeft: 'auto',   }}
    aria-label="View notes"
    onClick={(e) => handleMoreVerticalClick(e, item)}
  >
    <Ellipsis size={20} color='var(--color-text3)' strokeWidth={1} />
  </IconButton>
    <ArrowRight onClick={(e) => handleMoreVerticalClick(e, item)}  size={20} color='var(--color-text3)' strokeWidth={1} />
</div>
</div> */}





        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '.5rem', width: '100%', maxWidth: '600px', flex: 1 }}>
          <CustomSelect
            value={classFilter}
            onChange={(e) => setClassFilter(e.target.value)}
            getData={async () => user.categories}
            getDisplayField={(item) => item}
            getIdField={(item) => item}
            placeholder="Class"
          />

          <CustomSelect
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value)}
            getData={async () => ['Type 1', 'Type 2', 'Type 3']}
            getDisplayField={(item) => item}
            getIdField={(item) => item}
            placeholder="Type"
          />
        </div> */}



                {/* { !isMobileAndBelow &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1.25rem 1rem",  color: 'var(--color-text4)', fontWeight: "600", fontSize: "1rem", borderBottom: "1px solid var(--color-separator)", textAlign: "left", backgroundColor: 'var(--color-background)', letterSpacing: '-0.02rem' }}>
            <div style={{ flex: 1, }}>
              Your Materials
            </div>
            <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
              Class
            </div>
            <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
              Date
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
              </div>
          </div>
        } */}



// import { Button, IconButton } from '@mui/material';
// import { format } from 'date-fns';
// import React, { useState, useEffect, useContext, useRef } from 'react';
// import { UserContext } from '../contexts/UserProvider';
// import { useNavigate } from 'react-router-dom';
// import { ArrowRight, MoreVertical, FileText, File, Mic, Ellipsis, FileUp, MessageSquareText, MessageCircleMore, TextCursorInput, Zap, FileCheck, BookOpenCheck, Rabbit, Text } from 'lucide-react';
// import axios from 'axios';
// import { API, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
// import { useBreakpoint } from '../misc/useBreakpoint';
// import ResourceInfoModal from '../modals/ResourceInfoModal';

// const NEW_USER_ID = 'newuserexample'
// const SECONDARY_COLUMN_WIDTH = '13rem'
// const SECONDARY_COLUMN_FLEX = .5
// const TERTIARY_COLUMN_WIDTH = '40px'


// const ResourcesTable = ({ isActive = true, data = [], dataCount = 0, setData, handleRowClick, currentPage, setCurrentPage, itemsPerPage }) => {
//   const { isMobileAndBelow,  } = useBreakpoint();
//   const totalPages = Math.ceil(dataCount / itemsPerPage);
//   const [resourceInfoModalId, setResourceInfoModalId] = useState(null);
//   const currentPageDisplayed = currentPage + 1

//   const iconDirectory = {
//     [RESOURCES_UPLOAD_METHODS.text]: <Text />,
//     [RESOURCES_UPLOAD_METHODS.file]: <FileText />,
//     [RESOURCES_UPLOAD_METHODS.recording]: <Mic />,
//   }

//   const onPreviousPage = () => {
//     setCurrentPage((prevPage) => prevPage - 1);
//   };

//   const onNextPage = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };

//   const handleMoreVerticalClick = (e, item) => {
//     e.stopPropagation();
//     if ( item?.user_id === NEW_USER_ID ) {
//       alert('This is an example file for new users. You can view it, but you cannot edit it. Upload your own files!')
//       return
//     }
//     else {
//       setResourceInfoModalId(item._id);
//     }
//   }


//   if ( !isActive ) return null

//   return (
//     <>
//       <div style={{ backgroundColor: 'var(--color-backgroundSecondary)',  overflow: 'hidden', borderRadius: '.5rem', marginBottom: '3rem', marginTop: '1.5rem',
//         ...isMobileAndBelow && { padding: 0, border: 'none', borderRadius: 0, boxShadow: 'none' } }}
//         role="region"
//         aria-label="Notes list"
//       >
//         { !isMobileAndBelow &&
//           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1rem 1rem",  color: 'var(--color-text1)', fontWeight: "600", textTransform: "uppercase", fontSize: ".9rem", borderBottom: "1px solid var(--color-separator)", textAlign: "left", }}>
//             {/* <div style={{ width: "40px", }}>
              
//             </div> */}
//             <div style={{ flex: 1, }}>
//               Your Materials
//             </div>
//             {/* <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
//               Class
//             </div>
//             <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
//               Date
//             </div>
//             <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
//               </div> */}
//           </div>
//         }

//         { data && data.length > 0 && data.map((item, index) => (
//           <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: isMobileAndBelow ? "1rem" : ".8rem 1.3rem", fontSize: '1rem', 
//             borderBottom: index === data.length - 1 ? 'none' : '1px solid var(--color-separator)', cursor: 'pointer', transition: 'background-color 0.3s ease', minWidth: 0, overflow: 'hidden' }}
//             key={index}
//             onClick={(e) => handleRowClick(e, item)}
//             onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//             onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//           >
            

//             <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: 1, minWidth: 0, width: '100%' }}>

//               <div style={{display: 'flex', alignItems: 'center', width: '100%', minWidth: 0, overflow: 'hidden'}}>
//                 { !isMobileAndBelow && (
//                   <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: '35px' }}>
//                     {React.cloneElement(iconDirectory[item.uploadMethod], { size: 17, color: 'var(--color-text3)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
//                   </div>
//                 )}
//                 <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1, fontSize: '1rem', color: 'var(--color-text1)', minWidth: 0 }}>
//                   {item.title || "Document"}
//                 </div>

//               </div>

//               { isMobileAndBelow &&
//                 <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "100%" }}
//                   >{format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
//                 </span>
//               }
//             </div>

//             { !isMobileAndBelow &&
//             <>
//               <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
//                 <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', }}
//                   >{item.category} 
//                 </span>
//               </div>

//               <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
//                 <span style={{ fontSize: '.9rem', color: 'var(--color-text4)',  }}
//                   >{format(new Date(item.date), "MMM d, yyyy")} 
//                 </span>
//               </div>
//             </>
//             }
//             <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
//               <IconButton style={{ color: 'var(--color-text3)', borderRadius: '50%', transition: 'background-color 0.3s ease', marginLeft: 'auto',   }}
//                 aria-label="View notes"
//                 onClick={(e) => handleMoreVerticalClick(e, item)}
//               >
//                 <Ellipsis size={20} color='var(--color-text3)' strokeWidth={1} />
//               </IconButton>
//             </div>
//           </div>
//         ))}
//       <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem", position: 'sticky', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTop: '1px solid var(--color-separator)', marginTop: "auto" }}>
//           <button
//             style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPageDisplayed === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPageDisplayed === 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
//             onClick={onPreviousPage}
//             disabled={currentPageDisplayed === 1}
//             aria-label="Go to previous page"
//             aria-disabled={currentPageDisplayed === 1}
//           >
//             ←
//           </button>
//           <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
//             Page {currentPageDisplayed} of {totalPages} | {dataCount} items
//           </span>
//           <button
//             style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages - 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
//             onClick={onNextPage}
//             disabled={currentPageDisplayed === totalPages}
//             aria-label="Go to next page"
//             aria-disabled={currentPageDisplayed === totalPages}
//           >
//             →
//           </button>
//         </nav>


//       </div>




//       <ResourceInfoModal 
//         resourceInfoModalId={resourceInfoModalId} 
//         setResourceInfoModalId={setResourceInfoModalId} 
//         setData={setData}
//       />
//     </>
//   );
// };

// export default ResourcesTable;
