const ErrorSentryScreen = ({ error: sentryError }) => {
  const error = sentryError;
  const errorMessage = error?.error?.message || error?.message || error?.statusText || ''


  return (
    <div id="error-page" style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      textAlign: 'center',
      backgroundColor: 'var(--color-background)',
      color: 'var(--color-text)'
    }}>
      <h1>Oops!</h1>
      <p style={{ color: 'var(--color-text3)', marginTop: '1rem' }}>Sorry, an unexpected error has occurred.</p>
      {error && (
        <p style={{ 
          color: 'var(--color-text3)',
          maxWidth: '600px',
          wordBreak: 'break-word'
        }}>
          <i>{errorMessage}</i>
        </p>
      )}
      <button 
        onClick={() => window.location.reload()}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          borderRadius: '8px',
          backgroundColor: 'var(--color-primary)',
          color: 'white',
          border: 'none',
          cursor: 'pointer'
        }}
      >
        Refresh Page
      </button>
    </div>
  );
}

export default ErrorSentryScreen;