import React, { useState, useContext } from 'react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { API, dbRefs, PAYWALL_TYPES } from '../misc/constants';
import axios from 'axios';
import { Resource_Loader } from './Resource_Loader';
import { UserContext } from '../contexts/UserProvider';
import { ContentCreate_Container, ContentCreate_DemoButton, ContentCreate_Header, ContentCreate_Input, ContentCreate_InputSubButton, ContentCreate_InputSubButton_ModelLevel } from './ContentCreateComponents';
import { Check, CheckCircle, ChevronDown, PlusCircleIcon, PlusIcon, X } from 'lucide-react';
import TextInputModal from '../modals/TextInputModal';

const ButtonStyle = { display: "flex", alignItems: "center", gap: "0.25rem", padding: "0.75rem", borderRadius: "0.75rem", border: "none", background: "var(--color-backgroundTertiary)", color: "var(--color-text3)", fontSize: "0.95rem", cursor: "pointer"}

const Dialog = ({ title, description, value, onChange, trigger }) => {
  const { isPhoneAndBelow } = useBreakpoint()
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  
  const handleOpen = (e) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Stop event bubbling
    setIsOpen(true);
    setIsClosing(false);
  };

  const handleClose = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 200); // Match animation duration
  };
  
  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(tempValue);
    handleClose();
  };

  return (
    <>
      <ContentCreate_InputSubButton
        label={trigger}
        onClick={handleOpen}
      >
        { value ? <CheckCircle size={14} strokeWidth={1.5} color="var(--color-primary)" /> : <PlusCircleIcon size={14} strokeWidth={1.5} color="var(--color-text2)" />}
      </ContentCreate_InputSubButton>

      {isOpen && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, animation: isClosing ? 'fadeOut 0.3s ease forwards' : 'fadeIn 0.3s ease forwards' }}>
          <div style={{ backgroundColor: 'white', padding: '24px', borderRadius: '8px', width: '90%', maxWidth: '500px', position: 'relative', maxHeight: '90vh', overflow: 'auto', animation: isClosing ? 'slideOut 0.3s ease forwards' : 'slideIn 0.3s ease forwards' }}>
            <button onClick={handleClose} style={{ position: 'absolute', right: '16px', top: '16px', background: 'none', border: 'none', cursor: 'pointer' }}>
              <X size={20} color="var(--color-text3)" />
            </button>
            
            <h2 style={{ color: 'var(--color-text1)', fontSize: '1.2rem', marginBottom: '.5rem', fontWeight: 600 }}
              >{title}
            </h2>
            <p style={{ color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '1rem', lineHeight: '1.4' }}
              >{description}
            </p>
            <textarea style={{ width: '100%', height: '100px', padding: '1rem', borderRadius: '.75rem', border: '1px solid var(--color-separatorOpaque)', marginBottom: '16px', fontSize: isPhoneAndBelow ? '16px' : '1rem', resize: 'none', outline: 'none' }} 
              value={tempValue} 
              onChange={(e) => setTempValue(e.target.value)} 
              placeholder="Enter text here..." 
            />
            
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
              <button style={{ padding: '.75rem 1rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', backgroundColor: 'white', cursor: 'pointer', color: 'var(--color-text3)', fontSize: '1rem' }}
                onClick={handleClose}
                >Cancel
              </button>
              <button style={{ padding: '.75rem 1rem', border: 'none', borderRadius: '.5rem', backgroundColor: 'var(--color-text1)', color: 'white', cursor: 'pointer', fontSize: '1rem' }}
                onClick={handleSave}
                >Save Changes
              </button>
            </div>

          </div>
          <style>{`
            @keyframes fadeIn {
              from { background-color: rgba(0, 0, 0, 0); }
              to { background-color: rgba(0, 0, 0, 0.7); }
            }
            @keyframes fadeOut {
              from { background-color: rgba(0, 0, 0, 0.7); }
              to { background-color: rgba(0, 0, 0, 0); }
            }
            @keyframes slideIn {
              from { transform: translateY(20px); opacity: 0; }
              to { transform: translateY(0); opacity: 1; }
            }
            @keyframes slideOut {
              from { transform: translateY(0); opacity: 1; }
              to { transform: translateY(20px); opacity: 0; }
            }
          `}</style>
        </div>
      )}
    </>
  );
};

const EXAMPLE_ESSAY_TEXT = `# World War II: A Global Conflict That Changed the World

The Second World War (1939-1945) stands as the deadliest and most consequential conflict in human history. This devastating war reshaped international borders, transformed global politics, and left an indelible mark on human civilization that continues to influence our world today.

## Origins and Causes

The seeds of World War II were sown in the aftermath of World War I. The Treaty of Versailles, which formally ended the First World War, imposed harsh penalties on Germany, leading to economic hardship and social unrest. This environment enabled the rise of Adolf Hitler and his Nazi Party, who promoted aggressive nationalism and territorial expansion. Meanwhile, Imperial Japan sought to dominate East Asia and the Pacific, while Italy under Mussolini pursued its own expansionist ambitions.

## The Path to War

The 1930s saw a series of aggressive actions that set the stage for global conflict. Japan invaded Manchuria in 1931 and began expanding into China. Italy invaded Ethiopia in 1935. Germany remilitarized the Rhineland in 1936, annexed Austria in 1938, and invaded Czechoslovakia in 1939. The final trigger came on September 1, 1939, when Germany invaded Poland, prompting Britain and France to declare war.

## The War Expands

What began as a European conflict quickly evolved into a global war. By 1941, the Axis powers (Germany, Italy, and Japan) controlled much of Europe and parts of Asia. The war's scope dramatically expanded when Germany invaded the Soviet Union in June 1941, and Japan attacked Pearl Harbor in December 1941, bringing the United States into the conflict.

## Major Turning Points

Several crucial battles marked turning points in the war:

- The Battle of Stalingrad (1942-1943) halted German expansion in the East
- The Battle of Midway (1942) crippled Japanese naval power in the Pacific
- D-Day (June 6, 1944) opened a second front in Western Europe
- The Battle of Berlin (1945) led to Germany's surrender

## The Holocaust

Central to World War II was the Holocaust, the systematic genocide of six million Jewish people and millions of others deemed "undesirable" by the Nazi regime. This unprecedented crime against humanity revealed the depths of human cruelty and later led to new international laws and human rights conventions.

## The War's End

The war in Europe ended in May 1945 with Germany's unconditional surrender. In the Pacific, the United States dropped atomic bombs on Hiroshima and Nagasaki in August 1945, leading to Japan's surrender and ushering in the atomic age.

## Post-War Transformations and Legacy

The aftermath of World War II fundamentally reshaped the global order. The United Nations was established on October 24, 1945, with 51 member states signing the UN Charter in San Francisco. This new organization sought to succeed where the League of Nations had failed, establishing more robust mechanisms for international cooperation and conflict resolution.

The war's devastating toll reached approximately 70-85 million casualties, with the Soviet Union suffering the highest losses at around 27 million people. China lost an estimated 15-20 million people, while Germany lost 7-9 million, and Japan lost 2.5-3.1 million. In Poland, approximately 17% of the total population perished.

The geopolitical map was dramatically redrawn:
- Germany was divided into East and West, with Berlin similarly split
- Poland's borders shifted westward, losing territory to the Soviet Union and gaining former German lands
- Japan was occupied by American forces until 1952, leading to significant political and social reforms
- The Soviet Union established control over Eastern Europe, creating satellite states
- Colonial empires began to crumble, with India gaining independence in 1947 and other colonies following

The Holocaust's horrific revelation led to several crucial developments. The Nuremberg Trials (1945-1946) established new precedents for international law and human rights. The United Nations adopted the Genocide Convention in 1948, defining genocide as a crime under international law. That same year, the state of Israel was established on May 14, 1948, providing a homeland for Jewish people in the aftermath of the Holocaust.

The emerging Cold War between the United States and Soviet Union defined the post-war era. This rivalry led to:
- The Marshall Plan (1948-1952), providing $13 billion in aid to rebuild Western Europe
- The formation of NATO (1949) and the Warsaw Pact (1955)
- An arms race that included nuclear weapons proliferation
- Proxy conflicts in Korea, Vietnam, and other regions

Technological and scientific advances from the war transformed civilian life:
- Radar technology revolutionized aviation and weather forecasting
- Penicillin mass production saved countless lives
- Nuclear technology led to new power sources
- Computer development accelerated, laying groundwork for the digital age

## Conclusion

World War II transformed the global order and human society in unprecedented ways. Its lessons about the dangers of totalitarianism, the importance of international cooperation, and the human capacity for both destruction and resilience continue to resonate today. As the generation that experienced this conflict firsthand passes away, it becomes increasingly important to remember and learn from this pivotal moment in human history.`;

const EXAMPLE_EVALUATION_CRITERIA = [
  { title: "Content Accuracy", description: "The essay presents accurate and factual information with proper citations and references.", weight: 30 },
  { title: "Analysis and Insight", description: "The essay demonstrates deep understanding of the topic with insightful analysis and interpretation.", weight: 40 },
  { title: "Organization", description: "The essay is well-organized and follows a logical flow from introduction to conclusion.", weight: 15 },
  { title: "Clarity and Style", description: "The writing is clear, concise and engaging with appropriate word choice and tone.", weight: 10 },
  { title: "Grammar and Mechanics", description: "The essay is free of grammatical errors and follows standard English conventions.", weight: 5 },
];

const EXAMPLE_EVALUATION_FEEDBACK = [
  { title: "Content Accuracy", weight: 30, score: 92,
    explanation: "The essay presents accurate and factual information with proper references.",
    suggestions: [
      "Expand on the reasons behind the Allied victory at the Battle of Stalingrad, like logistics and the Soviet strategy.",
      "Provide specific examples of how the Marshall Plan influenced the recovery of European countries after the war.",
      "Include more context on the impact of the Cold War dynamics on post-war Europe, such as the division of Germany and the role of the Iron Curtain.",
    ]
   },
  { title: "Analysis and Insight", weight: 40, score: 88,
    explanation: "The essay demonstrates understanding of the topic; could benefit from deeper analysis of the factors that led to the conflict.",
    suggestions: [
      "Expand on the economic consequences of World War II on both Europe and Asia, possibly addressing how these shaped future trading relationships.",
      "Incorporate more analysis on how the ideological battle between democracy and totalitarianism during the war influenced the Cold War dynamics.",
      "Discuss more explicitly the long-term implications of the United Nations' establishment, particularly in relation to global conflict resolution mechanisms.",
    ]
  },
  { title: "Organization", weight: 15, score: 85,
    explanation: "The essay is well-organized with clear sections and transitions, but could improve flow between paragraphs.",
    suggestions: [
      "Consider using clearer subheadings for each section to improve organization, such as 'Causes of Conflict', 'Major Battles', and 'Aftermath'.",
      "Try to include transition sentences that bridge concepts between sections for improved flow, like connecting the discussion on the rise of totalitarian regimes directly to the major battles of the war.",
      "Ensure that each section succinctly ties back to the main argument of the essay, emphasizing how each part contributes to the overall impact of World War II on global history.",
    ]
  },
  { title: "Clarity and Style", weight: 10, score: 80,
    explanation: "The writing style is generally clear but could be more engaging and polished in places.",
    suggestions: [
      "Reduce verbosity by simplifying sentences; for example, change 'The war's devastating toll reached approximately 70-85 million casualties' to 'The war caused approximately 70-85 million casualties.'",
      "Clarify complex phrases; for instance, instead of 'The Holocaust's horrific revelation led to several crucial developments,' specify which developments, such as 'The horrific revelation of the Holocaust prompted the establishment of new international laws and conventions regarding genocide.'",
    ]
  },
  { title: "Grammar and Mechanics", weight: 5, score: 99,
    explanation: "The grammar and mechanics of the essay are flawless.",
    suggestions: []
  },
];

const Essay_Input = ({ isActive, setResponses, setActiveTab, essayText, setEssayText }) => {
  const { user, checkUserPermission } = useContext(UserContext)
  const [promptText, setPromptText] = useState('');
  const [rubricText, setRubricText] = useState('');
  const [gradeLevel, setGradeLevel] = useState('');
  const [evaluationCriteria, setEvaluationCriteria] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const isSubmittable = essayText && essayText.length > 10;
  const [ open, setOpen ] = useState(false);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.essay_evaluation)
      if ( !hasPermission ) return;

      setLoading(true);
      let criteria = evaluationCriteria;

      if ( !criteria || criteria.length === 0 ) {
        const result = await axios.post(`${API}/essayEvaluationCriteria`, { essayText, promptText, rubricText, gradeLevel });
        const results = result.data;
        criteria = results?.content?.criteria;
        setEvaluationCriteria(criteria);
      }

      const feedbackPromises = criteria.map(criterion => 
        axios.post(`${API}/evaluateEssay3`, { 
          essayText, 
          criteriaTitle: criterion.title, 
          criteriaDescription: criterion.description, 
          gradeLevel: gradeLevel 
        })
      );

      const feedbackResults = await Promise.all(feedbackPromises);
      const feedbackArray = feedbackResults.map((result, index) => {
        const feedback = result.data.content;
        feedback.title = criteria[index].title;
        feedback.weight = criteria[index].weight;
        feedback.score = Math.round(feedback.score)
        return feedback;
      });

      axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: user._id },
        updateObj: { $inc: { ['usage.essay_evaluation']: 1 } },
        dbRef: dbRefs.users,
      }).catch(() => {}) // Silently handle any errors

      setResponses(feedbackArray);
      setActiveTab('Score')
    } 
    catch (error) {
      console.error('Error evaluating essay:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleDemo = async () => { 
    try {
      await axios.put(`${API}/generalUpdateOne`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $inc: { ['usage.example_essay_evaluation']: 1,  } }, 
        dbRef: dbRefs.users 
      })
    }
    catch (error) {
      console.error('Error updating user usage:', error);
    }
    finally {
      setLoading(true)
      setEssayText(EXAMPLE_ESSAY_TEXT)
      setEvaluationCriteria(EXAMPLE_EVALUATION_CRITERIA)
      setResponses(EXAMPLE_EVALUATION_FEEDBACK)
      setActiveTab('Score')
      setLoading(false)
    }
  }



  if (!isActive) return null;
  if (loading) return <Resource_Loader isActive={true} text="Evaluating your essay" subText="This may take ~10 seconds" />;
  return (
    <>

      <ContentCreate_Container>

        <ContentCreate_Header
          title="Improve my **essay**"
          subtitle="Get your essay graded and see where you can improve"
        />

        <ContentCreate_Input
          placeholder="Paste your essay here..."
          value={essayText}
          setValue={setEssayText}
          handleSubmit={handleSubmit}
          // handleSubmit={() => {}}
        >
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%", marginTop: "1rem", flexWrap: 'wrap' }}>

            <Dialog
              title="Prompt"
              description="Add the prompt for your essay (optional)"
              value={promptText}
              onChange={setPromptText}
              trigger="Prompt"
            />

            <Dialog
              title="Rubric"
              description="Paste the rubric or state the criteria you'd like to be graded on (optional)"
              value={rubricText}
              onChange={setRubricText}
              trigger="Rubric"
            />

              <div style={{ ...ButtonStyle, position: 'relative' }}
                onClick={() => document.getElementById('gradeLevel').focus()}
                onMouseEnter={(e) => e.currentTarget.style.opacity = '0.5'}
                onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
              >
                <select style={{ appearance: 'none', position: 'absolute', inset: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
                  id="gradeLevel"
                  value={gradeLevel}
                  onChange={(e) => setGradeLevel(e.target.value)} 
                >
                  <option value="">Class Level</option>
                  <option value="High School">High School</option>
                  <option value="University">University</option>
                  <option value="Graduate School">Graduate School</option>
                </select>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px', pointerEvents: 'none' }}>
                  <span>{gradeLevel ? gradeLevel.charAt(0).toUpperCase() + gradeLevel.slice(1) : 'Class Level'}</span>
                  { gradeLevel ? <CheckCircle size={14} strokeWidth={1.5} color="var(--color-primary)" /> : <ChevronDown size={14} strokeWidth={1.5} color="var(--color-text2)" />}
                </div>
              </div>

              <ContentCreate_InputSubButton_ModelLevel />
          </div>
        </ContentCreate_Input>

        <ContentCreate_DemoButton onClick={handleDemo} />

      </ContentCreate_Container>

      <TextInputModal
        open={open}
        setOpen={setOpen}
        handleOnClick={() => {}}
      />

        
    </>
  );
};

export default Essay_Input;



        {/* <div style={{ marginTop: '1rem' }}>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: VERTICAL_SPACING }}>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
              <label htmlFor="essay" style={labelStyle}>
                Essay Text <span style={{ color: '#ef4444' }}>*</span>
              </label>
              <textarea style={{ ...optionalInputStyle, height: "120px", lineHeight: '1.3', resize: 'none' }}
                id="essay"
                placeholder="Paste your essay here..."
                required={true}
                value={essayText}
                onChange={(e) => setEssayText(e.target.value)}
              />
            </div>

              <div style={{ display: 'flex', flexDirection: 'column', }}>
                <label htmlFor="prompt" style={labelStyle}
                  >Prompt <OptionalTag />
                </label>
                <input style={optionalInputStyle}
                  type="text" 
                  id="prompt"
                  placeholder="Copy & paste the prompt here (if any)"
                  value={promptText}
                  onChange={(e) => setPromptText(e.target.value)}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <label htmlFor="rubric" style={labelStyle}
                  >Rubric <OptionalTag />
                </label>
                <input style={optionalInputStyle}
                  type="text" 
                  id="rubric"
                  placeholder="Copy & paste the rubric or enter the criteria you'd like to be graded on (if any)"
                  value={rubricText}
                  onChange={(e) => setRubricText(e.target.value)}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <label htmlFor="gradeLevel" style={labelStyle}
                  >Grade Level <OptionalTag />
                </label>
                <select style={optionalInputStyle}
                  id="gradeLevel"
                  value={gradeLevel}
                  onChange={(e) => setGradeLevel(e.target.value)}
                >
                  <option value="">Select your level</option>
                  <option value="high">High School</option>
                  <option value="college">University</option>
                  <option value="graduate">Graduate School</option>
                </select>
              </div>
          </form>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: VERTICAL_SPACING, alignSelf: 'flex-start' }}>
          <button style={{ padding: '.75rem 1.5rem', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', fontSize: '1rem', cursor: 'pointer', width: '100%',
            ...(!isSubmittable && { cursor: 'not-allowed', opacity: .3 }),
           }}
            onClick={handleSubmit}
            disabled={!isSubmittable || loading}
            onMouseEnter={e => { if (isSubmittable) { e.target.style.opacity = '0.7'; } }}
            onMouseLeave={e => { if (isSubmittable) { e.target.style.opacity = '1'; } }}
          >
            Grade Essay
          </button>


        </div> */}

          {/* <button style={{ padding: '.75rem 1.5rem', backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', borderRadius: '.5rem', fontSize: '1rem', cursor: 'pointer',}}
            onClick={handleSubmit}
            onMouseEnter={e => e.target.style.opacity = '0.7'}
            onMouseLeave={e => e.target.style.opacity = '1'}
          >
            Watch Example
          </button> */}