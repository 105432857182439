import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API, dbRefs, SEARCH_PARAMS } from '../misc/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes } from '../navigation/routes';

const EmailUnsubscribeScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const user_id = params.get(SEARCH_PARAMS.user_id);
    const email_id = params.get(SEARCH_PARAMS.email_id);
    
    if (user_id && email_id) {
      handleUnsubscribe(user_id, email_id);
    }
  }, [location, navigate]);

  const handleUnsubscribe = async (user_id, email_id) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: user_id },
        updateObj: { $set: { emailUnsubscribed: true } },
        dbRef: dbRefs.users
      });

      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: email_id },
        updateObj: { $set: { unsubscribed: true } },
        dbRef: dbRefs.emails
      });

      navigate(location.pathname, { replace: true });
      setStatus('success');
    } 
    catch (err) {
      setStatus('error');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', padding: '2rem', textAlign: 'center' }}>
      <h1 style={{ fontSize: '2rem', marginBottom: '1.5rem' }}>Email Preferences</h1>
      
      {status === 'processing' && (
        <p style={{ color: 'var(--color-text3)' }}>Processing your request...</p>
      )}

      {status === 'success' && (
        <div>
          <p style={{ color: 'var(--color-text1)', }}>
            You've been successfully unsubscribed from our emails.
          </p>
        </div>
      )}

      {status === 'error' && (
        <p style={{ color: '#dc2626' }}>
          Sorry, there was an error processing your request. Please try again later.
        </p>
      )}

      <button style={{ padding: '0.75rem 1.5rem', marginTop: '2rem', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text1)', borderRadius: '30px', boxShadow: 'var(--borderShadow)', cursor: 'pointer' }} 
        onClick={() => navigate(Routes.HOME)}
      >
        Return to Home
      </button>
    </div>
  );
};

export default EmailUnsubscribeScreen;
