import React, { useState, useContext } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import Resource_Teach from '../components/Resource_Teach3';
import { Resource_Loader } from '../components/Resource_Loader';
import { getResourceTextUrl } from '../misc/utils';
import { BookOpen } from 'lucide-react';
import { GenerateComponent } from '../components/Resource_Components';
import { UserContext } from '../contexts/UserProvider';

const ResourceTeachScreen = ({ }) => {
  const { user } = useContext(UserContext);
  const { data, setData, getData,  } = useOutletContext();
  const [ loading, setLoading ] = useState(false);

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      await axios.post(`${API}/generateTeach2`, { resource_id: data._id, content_url });
      await Promise.all([
        axios.put(`${API}/generalUpdateOne`, { matchObj: { _id: user._id }, updateObj: { $inc: { ['usage.resource_teach']: 1,  } }, dbRef: dbRefs.users }),
        getData()
      ]);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
      alert("Error generating tutorial, please try again.")
    }
    finally {
      setLoading(false);
    }
  }

  const handleSave = async ({ messages, index }) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: data?._id },
        updateObj: { $push: { [`teach.content.${index}.messages`]: { $each: messages } }},
        dbRef: dbRefs.resources,
      })

      setData(prev => ({
        ...prev,
        teach: {
          ...prev.teach,
          content: prev.teach.content.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                messages: [
                  ...item.messages,
                  ...messages
                ]
              }
            }
            return item
          })
        }
      }))
    }
    catch (error) {
      console.error('Error in handleSave:', error);
    }
  }

  return (
    <div id="wrapper" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', backgroundColor: 'var(--color-backgroundSecondary)' }}>   
      
      { loading ? 
        <Resource_Loader isActive={true} text="Generating tutorial" subText="This may take ~10 seconds" />
      
      : !data?.teach?.content ?
        <GenerateComponent title="Generate Tutorial" onClick={generateContent} isActive={true} />
      
      :
        <Resource_Teach isActive={true} data={data} handleSave={handleSave} />      
      }
          
    </div>
  );  
};


export default ResourceTeachScreen;

