import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, dbRefs } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import Teach_Message from './Teach_Message';
import { OPENAI_CHAT_ROLES } from '../misc/constants';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Check, CircleHelp } from 'lucide-react';
import { processChatStreamResponse } from '../misc/utils';


const TileSidebar = ({ item, index, handleSidebarClick, activeIndex, isLoaded }) => {
  const isSelected = index === activeIndex

  return (
    <button style={{ display: "flex", flexDirection: "row", backgroundColor: isSelected ? "var(--color-background)" : "var(--color-backgroundSecondary)", cursor: "pointer", alignItems: "center", justifyContent: "space-between", textAlign: "left", padding: ".5rem .75rem", overflow: "hidden", fontSize: ".9rem", color: "var(--color-text1)", textOverflow: "ellipsis", whiteSpace: "nowrap", borderRadius: ".5rem", flexShrink: 0, }}
      key={index}
      onClick={() => handleSidebarClick(index)} 
    >
      {item.title}

      { isLoaded && <Check size={12} style={{ color: "var(--color-text1)" }} strokeWidth={1.35} />}
    </button>
  )
}



const Resource_Teach = ({ isActive, data, handleSave = null, handleBack = null }) => {
  const { user, } = useContext(UserContext)
  const { isPhoneAndBelow, isMobileAndBelow } = useBreakpoint()
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ messages, setMessages ] = useState({})

  useEffect(() => {
    const initialMessages = {};
    data?.teach?.content?.forEach((_, index) => {
      initialMessages[index] = data?.teach?.content?.[index]?.messages || [];
    });
    setMessages(initialMessages);
  }, [data])

  useEffect(() => {
    setActiveIndex(0)
  }, [data?._id])
  
  const [ loadingResponse, setLoadingResponse ] = useState(false)
  const [ error, setError ] = useState(false);
  const messagesEndRef = useRef(null);
  const [ chatInput, setChatInput ] = useState("")
  const [ openSidebar, setOpenSidebar ] = useState(true)
  const [ underlayIsVisible, setUnderlayIsVisible ] = useState(false)

  useEffect(() => {
    if ( !isMobileAndBelow ) {
      setOpenSidebar(true)
    }
    else {
      setOpenSidebar(false)
    }
  }, [isMobileAndBelow])

  useEffect(() => {
    scrollToBottom()
  }, [loadingResponse])

  const handleSubmit = async (newMessage, clearMessages = false, index = 0) => {
    if ( loadingResponse ) return

    setLoadingResponse(true);
    const currentMessages = messages?.[index] || [];
    const updatedMessages = clearMessages ? [newMessage] : [...currentMessages, newMessage];
    
    // Add empty assistant message placeholder
    setMessages(prev => ({
      ...prev,
      [index]: [...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }]
    }));

    setChatInput("")
    
    const TIMEOUT_DURATION = 12000;
    let timeoutId;

    try {
      const response = await fetch(`${API}/streamTeach2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          user_id: user?._id, 
          teachObj: data?.teach,
          index: index,
          messages: updatedMessages 
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedContent = ''; // Keep track of the full message

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');
          
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const json = line.replace('data: ', '');
              if (json.trim() !== '') {
                const data = JSON.parse(json);
                if (data.error) {
                  throw new Error('Server error');
                }

                // Reset timeout
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  throw new Error('Response timeout');
                }, TIMEOUT_DURATION);

                // Update accumulated content
                accumulatedContent += data.content || '';

                // Update state with the full accumulated content
                setMessages((prevMessages) => {
                  const currentMessages = prevMessages[index] || [];
                  const lastMessage = currentMessages[currentMessages.length - 1];
                  if (lastMessage?.role === OPENAI_CHAT_ROLES.assistant) {
                    return {
                      ...prevMessages,
                      [index]: [
                        ...currentMessages.slice(0, -1),
                        { ...lastMessage, content: accumulatedContent }
                      ]
                    };
                  }
                  return prevMessages;
                });
              }
            }
          }
        }
      };

      timeoutId = setTimeout(() => {
        throw new Error('Initial response timeout');
      }, TIMEOUT_DURATION);

      await processStream();

      if ( handleSave ) {
        await handleSave({ messages: [ newMessage, { role: OPENAI_CHAT_ROLES.assistant, content: accumulatedContent } ], index })
      }
    } 
    catch (error) {
      console.error('Error in handleSubmit:', error);
      handleError();

    } finally {
      clearTimeout(timeoutId);
      setLoadingResponse(false);
    }
  };

  const handleError = () => {
    setError(true);
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  }

  const handleSidebarClick = (index) => {
    setActiveIndex(index)
    // if ( !messages?.[index]?.length || messages?.[index]?.length == 0 ) {
    //   handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Begin" }, false, index)
    // }
  }

  const handleNext = () => {
    if ( activeIndex < data?.teach?.content?.length - 1 ) {
      let newIndex = activeIndex + 1

      setActiveIndex(newIndex)
      if ( !messages?.[newIndex]?.length || messages?.[newIndex]?.length == 0 ) {
        handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Begin" }, false, newIndex)
      }
    }
  }

  const handleToggleSidebar = () => {
    if ( openSidebar ) {
      setOpenSidebar(false)
      if ( isMobileAndBelow ) {
        setUnderlayIsVisible(false)
      }
    }
    else {
      setOpenSidebar(true)
      if ( isMobileAndBelow ) {
        setUnderlayIsVisible(true)
      }
    }
  }

  if ( !isActive || !data?.teach?.content ) return null
  return (

    <div id="wrapper" style={{ overflow: "hidden" }}>
      <div id="main" style={{ overflow: "hidden", padding: 0 }}>
        <div className="inner" >

          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignSelf: 'center', minHeight: '100%', position: 'relative',  }}>

            {/* UNDERLAY */}
            <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 2, opacity: underlayIsVisible ? 1 : 0, visibility: underlayIsVisible ? 'visible' : 'hidden', transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"}}
              onClick={handleToggleSidebar}
            />
            


            {/* RIGHT CONTAINER */}
            <div style={{ display: 'flex', flexDirection: 'column', height: "100%", flex: 1, position: "relative", overflow: "hidden"}}>

              
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "1rem", marginLeft: "1rem", marginRight: "1rem" }}>
              { handleBack &&
                <button style={{ height: "32px", padding: "0 1rem", backgroundColor: "var(--color-background)", borderRadius: ".75rem", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}
                  onClick={handleBack}
                >
                  <ArrowLeft size={16} style={{ color: "var(--color-text1)" }} />
                  <span style={{ fontSize: "14px", color: "var(--color-text1)" }}>Back</span>
                </button>
              }

              <button style={{ height: "32px", padding: "0 1rem", backgroundColor: "var(--color-background)", borderRadius: ".75rem", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", gap: "4px", marginLeft: "auto" }}
                onClick={handleToggleSidebar}
              >
                <ArrowRight size={16} style={{ color: "var(--color-text1)", transform: !openSidebar ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }} />
                <span style={{ fontSize: "14px", color: "var(--color-text1)" }}>{ openSidebar ? "Close" : "Open" }</span>

              </button>
            </div>

              { !messages?.[activeIndex]?.length ?

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: 1, padding: "1rem" }}>
                  <button style={{ padding: ".75rem 1.5rem", backgroundColor: "var(--color-backgroundSecondary)", color: "var(--color-text1)", border: "1px solid var(--color-separatorOpaque)", borderRadius: ".5rem", cursor: "pointer", fontSize: "1rem", fontWeight: "500", boxShadow: "var(--borderShadow)" }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)' }
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)' }
                    onClick={() => handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: "Begin" }, false, activeIndex)}
                  >
                    Begin Section
                  </button>
                </div>

              :

                <>

                  <div style={{ display: "flex", flexDirection: "column", flex: 1, padding: "1rem 1rem", color: "var(--color-text1)", overflowY: "auto", gap: "1rem" }}>

                    {messages?.[activeIndex]?.map((message, index) => (
                      <Teach_Message key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} />
                    ))}
                    <div ref={messagesEndRef} />
                  </div>

                  {/* FOOTER */}
                  <div style={{ display: "flex", flexDirection: "row", width: "100%", backgroundColor: "var(--color-backgroundSecondary)", marginTop: 'auto', justifyContent: "space-between", alignItems: "center", gap: ".5rem", padding: "1rem" }}>
                    
                    { !data?.previewMode &&
                      <div style={{ display: "flex", flexDirection: "row", flex: 1, border: "1px solid var(--color-separatorOpaque)", borderRadius: ".5rem", height: "100%"}}>
                        <input style={{ flex: 1, paddingLeft: ".75rem", backgroundColor: "transparent", color: "var(--color-text1)", border: "none", fontSize: isPhoneAndBelow ? "16px" : "14px", outline: "none", height: "100%" }}
                          type="text" 
                          placeholder="Ask a question..."
                          value={chatInput}
                          onChange={(e) => setChatInput(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: chatInput }, false, activeIndex)
                            }
                          }}
                        />
                        <button style={{ backgroundColor: chatInput ? "var(--color-text1)" : "var(--color-backgroundSecondary)", color: "var(--color-textButton)", border: "none", borderRadius: ".5rem", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", margin: "4px" }}
                          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                          onClick={() => handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: chatInput }, false, activeIndex)}
                        >
                          <ArrowUp size={16} />
                        </button>
                      </div>
                    }

                    { !isMobileAndBelow && !data?.previewMode && (
                      [
                        { title: "Simplify", description: "Explain this concept in a simpler, more basic way.", },
                        { title: "Expand", description: "Expand the content to make it more detailed.", },
                      ]?.map((item, index) => (
                        <button key={index} style={{ padding: ".75rem 1rem", backgroundColor: "var(--color-backgroundSecondary)", color: "var(--color-text1)", border: "none", borderRadius: "6px", cursor: "pointer", fontSize: "14px", fontWeight: "500", display: "flex", alignItems: "center", gap: "8px", border: "1px solid var(--color-separatorOpaque)", boxShadow: "var(--borderShadow)" }}
                          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                          onClick={() => handleSubmit({ role: OPENAI_CHAT_ROLES.user, content: item.description }, false, activeIndex)}
                        >
                          {item.title}
                        </button>
                      ))
                    )}
                    
                    <button style={{ padding: ".75rem 1rem", backgroundColor: "var(--color-text1)", color: "var(--color-textButton)", border: "none", borderRadius: "6px", cursor: "pointer", fontSize: "14px", fontWeight: "500", display: "flex", alignItems: "center", gap: "8px", marginLeft: "auto" }}
                      onClick={handleNext}
                      onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                      onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                    >
                      Next
                      { !isMobileAndBelow && (
                        <ArrowDown size={16} />
                      )}
                    </button>
                  </div>

                </>

              }


            </div>

                        {/* LEFT CONTAINER */}
            <div style={{ display: 'flex', flexDirection: 'column', width: openSidebar ? "250px" : "0px", height: "100%", borderLeft: '1px solid var(--color-backgroundTertiary)', position: "relative", transition: "all 0.2s", overflow: "hidden",
              ...(isMobileAndBelow && { position: 'absolute', top: 0, bottom: 0, right: 0, zIndex: 3, }), backgroundColor: "var(--color-backgroundSecondary)", padding: "1rem .5rem", overflowY: "auto",
              ...(!openSidebar && { padding: "0" })
            }}>

              {data?.teach?.content?.map((item, index) => (
                <React.Fragment key={index}>
                  {(index === 0 || item.section !== data.teach.content[index - 1].section) && (
                    <h4 style={{ fontSize: ".9rem", fontWeight: "400", color: "var(--color-text4)", marginBottom: ".5rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginTop: index === 0 ? "0" : "1rem", paddingBottom: "3px", flexShrink: 0 }}>{item.section}</h4>
                  )}
                  <TileSidebar 
                    item={item} 
                    index={index} 
                    activeIndex={activeIndex}
                    isLoaded={messages?.[index]?.length > 0}
                    handleSidebarClick={handleSidebarClick} 
                  />
                </React.Fragment>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>

  );  
}


export default Resource_Teach;
