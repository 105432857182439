import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useMemo, } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { ChevronDown, ChevronUp, GraduationCap, ListChecks,Users } from 'lucide-react';
import {  SEARCH_PARAMS, SLACK_WEBHOOK_CHANNELS, } from '../misc/constants';
import AuthModal from '../modals/AuthModal2';
import SchoolGoat from '../assets/SchoolGoatBg.png';
import { PenLine, Clock, BookOpen, Calculator, Zap, Star, Mail } from 'lucide-react';
import {  NotebookPen, Trophy, DollarSign,CheckIcon, CheckCircleIcon, ChartNoAxesCombined, X } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { Pixel } from '../misc/analytics';
import { FileEdit } from 'lucide-react';
import { AlertTriangle, UserX , HelpCircle,  CreditCard } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { sendSlackNotification } from '../misc/utils';


const SectionHeader = ({ title, subTitle }) => {
  const { isPhoneAndBelow } = useBreakpoint();

  return (
    <h2 style={{ fontSize: '2rem', fontWeight: 700, lineHeight: 1.4, color: '#111', textAlign: 'center', width: "80%", marginLeft: "auto", marginRight: "auto", marginTop: '3rem', marginBottom: '1.5rem', marginBottom: "2rem",
      ...(isPhoneAndBelow && { marginTop: '2rem', fontSize: '1.7rem', width: "100%", textAlign: 'center', marginBottom: '1.5rem', })
    }}>
      {title}
    </h2>
  )
}

const styles = {
  section: {
    fontFamily: 'system-ui, -apple-system, sans-serif', lineHeight: 1.6, color: '#1a1a1a'
  },

  featureList: {
    margin: '2rem 0',
    padding: 0,
    listStyle: 'none'
  },
  featureItem: {
    margin: '1rem 0',
    paddingLeft: '1.5rem',
    position: 'relative'
  },
  testimonialCard: {

  },
  testimonialText: {
    fontSize: '1rem',
    // fontStyle: 'italic',
    marginBottom: '1.5rem',
    color: 'var(--color-text2)'
  },
  testimonialAuthor: {
    fontWeight: 500,
    color: 'var(--color-text4)'
  },
  bullet: {
    position: 'absolute',
    left: 0,
    color: '#666'
  }
};

const HeaderSection = ({ isPhoneAndBelow }) => {
  return (
    <section style={styles.section}>
      <div style={{ color: '#666', fontSize: '1.1rem', fontWeight: 500, textTransform: 'uppercase', marginBottom: '1rem', textAlign: 'center', 
        ...(isPhoneAndBelow && { fontSize: '.9rem', textAlign: 'left' }) 
      }}>
        The solution for parents of struggling students
      </div>
      <h1 style={{ fontSize: '3.5rem', fontWeight: 700, lineHeight: 1.4, marginBottom: '1.5rem', color: '#111', textAlign: 'center', 
        ...(isPhoneAndBelow && { fontSize: '2rem', marginBottom: '1rem', textAlign: 'left' }) 
      }}>
        Help your child reach their academic potential and be successful in life
      </h1>

      <p style={{ fontSize: '1.5rem', fontWeight: 500, color: 'var(--color-text4)', lineHeight: 1.4, textAlign: 'center', width: '80%', marginLeft: 'auto', marginRight: 'auto', 
        ...(isPhoneAndBelow && { fontSize: '1.2rem', textAlign: 'left', width: '100%' }) 
      }}>
        For less than the cost of a single tutoring session, you can help your child go from struggling to successful in school
      </p>
  </section>
  )
}

const PainPoints = ({ }) => {
  const painPoints = {
    student: [
      {
        title: "Falling behind in class",
        description: "Missing important information, unable to keep up with the pace, feeling lost and overwhelmed"
      },
      {
        title: "Receiving poor grades", 
        description: "Despite how smart they are, the grades don't reflect their true ability"
      },
      {
        title: "Disengaging from school",
        description: "Losing interest in learning, feeling unmotivated and disconnected from school"
      },
      {
        title: "Losing confidence",
        description: `Starting to believe they're "not smart enough" - their self-esteem taking daily hits`
      }
    ],
    parent: [
      
      {
        title: "Fearing for their future",
        description: "Worrying about college prospects, career options, and their long-term success"
      },
      {
        title: "Dealing with strained relationships",
        description: "Homework and grades turning into arguments, affecting your relationship"
      },
      {
        title: "Feeling helpless",
        description: "Watching your bright, capable child struggle and lose confidence, knowing they could do better"
      },
      {
        title: "Unsure how to help",
        description: "Not sure what to do to help your child, or if you're even doing the right thing"
      },
    ]
  };

  return (
    <section style={{maxWidth: '800px', margin: '0 auto'}}>
      <SectionHeader title="Does this sound familiar to you?" />

      <div style={{overflowX: 'auto', width: '100%', maxWidth: '800px', margin: '0 auto'}}>
        <table style={{width: '100%', borderCollapse: 'collapse', backgroundColor: '#ffffff'}}>
          <thead>
            <tr>
              <th style={{textAlign: 'left', padding: '16px .5rem', fontSize: '0.875rem', fontWeight: '500', color: '#6b7280', textTransform: 'uppercase', letterSpacing: '0.05em', borderBottom: '1px solid #e5e7eb'}}>Your Student Is...</th>
              <th style={{textAlign: 'left', padding: '16px .5rem', fontSize: '0.875rem', fontWeight: '500', color: '#6b7280', textTransform: 'uppercase', letterSpacing: '0.05em', borderBottom: '1px solid #e5e7eb'}}>While You Are...</th>
            </tr>
          </thead>
          <tbody>
            {painPoints.student.map((point, index) => (
              <tr key={index}>
                <td style={{padding: '16px .5rem', borderBottom: '1px solid #f3f4f6'}}>
                  <div style={{fontSize: '1rem', fontWeight: '500', color: 'var(--color-text1)', marginBottom: '0.45rem', lineHeight: 1.3}}>{point.title}</div>
                  <div style={{color: 'var(--color-text4)', lineHeight: 1.3}}>{point.description}</div>
                </td>
                <td style={{padding: '16px .5rem', borderBottom: '1px solid #f3f4f6'}}>
                  <div style={{fontSize: '1rem', fontWeight: '500', color: 'var(--color-text1)', marginBottom: '0.45rem', lineHeight: 1.3}}>{painPoints.parent[index].title}</div>
                  <div style={{color: 'var(--color-text4)', lineHeight: 1.3}}>{painPoints.parent[index].description}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

const EmpathySection = () => {
  return (
    <section style={{...styles.section,     }}>
    <SectionHeader title="You are not alone. I've been there." />
    <p style={{marginBottom: '1.5rem'}}>
      I know that feeling - watching your bright, capable child struggle in school. 
      Their grades dropping. Their confidence crumbling. Knowing that they are capable of so much more.
    </p>
    <p style={{marginBottom: '1.5rem'}}>
      I've been there. My son was that student. Smart, full of potential, but falling 
      further behind each semester. The calls from teachers. The late nights 
      trying to help with assignments. The arguments that were damaging our relationship.
    </p>
    <p style={{marginBottom: '1.5rem'}}>
      What hurt most was seeing him start to believe he wasn't "smart enough" - when I 
      knew that couldn't be further from the truth. And that I didn't know how to help.
    </p>
    <p style={{marginBottom: '1.5rem'}}>
      The stakes? They're everything. It's not just about grades. It's about your child's 
      self-worth. Their future opportunities. Their belief in themselves. Every day they 
      continue to struggle, that belief dims a little more.
    </p>
    <p style={{marginBottom: '1.5rem'}}>
      That's when I realized: waiting wasn't an option. Every week that passed meant more missed opportunities, more damaged confidence, more strain on our relationship.
    </p>
    <p style={{marginBottom: '1.5rem'}}>
      - Ryan, Creator of School Goat
    </p>
  </section>
  )
}

const ConsequencesSection = ({ isPhoneAndBelow }) => {
  return (
    <section style={{...styles.section}}>
      <SectionHeader title="Your child can't afford for you to do nothing because it only gets worse." />
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '1.5rem' }}>
        {[
          {
            color: '#eab308',            
            title: 'Poor Grades → Poor College Options',
            description: "Low grades and test scores meaningfully reduce college acceptance chances. Top universities become out of reach. Scholarship opportunities disappear. It gets harder and harder to get back on track.",
            descriptionMobile: "Poor grades early on limit college options, eliminate scholarships, and make it harder for your child to succeed later on."
          },
          {
            color: '#f97316', 
            title: 'Poor College Options → Limited Career Opportunities',
            description: "Less prestigious schools often mean fewer networking opportunities, limited access to top employers, and lower starting salaries. Career paths become narrower before they even begin.",
            descriptionMobile: "Attending low-ranked schools means fewer job opportunities, lower salaries, and limited career opportunities."
          },
          {
            color: '#ef4444',
            title: 'Limited Career Opportunities → Financial Insecurity', 
            description: "Lower-paying jobs mean living paycheck to paycheck, struggling with debt, and fewer opportunities for advancement. This makes it hard for them to support their own family one day.",
            descriptionMobile: "Lower-paying jobs make it harder for your child to save money and provide for their own family one day."
          }
        ].map((item, index) => (
          <div key={index} style={{ padding: '1.5rem', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 1px 3px rgba(0,0,0,0.1)', borderLeft: `4px solid ${item.color}` }}>
            <h3 style={{
              fontSize: isPhoneAndBelow ? '1rem' : '1.15rem',
              fontWeight: 600,
              marginBottom: '0.75rem',
              color: '#333'
            }}>
              {item.title}
            </h3>
            <p style={{color: '#555', lineHeight: '1.5'}}>
              { isPhoneAndBelow ? item.descriptionMobile : item.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

const BadOptionsSection = ({ isPhoneAndBelow }) => {
  const options = [
    {
      title: "Hire a Tutor",
      price: "$50-100/hour",
      icon: DollarSign,
      impacts: ["Expensive ($400+/mo)", "Limited availability", "Single subject focus only", "No in-class support", "Logistical hassles", "Quality tutors scarce"],
      impactsMobile: ["Expensive ($400+ per month)", "Limited availability and scheduling", "Can only help with one subject", "No support during actual classes", "Hassle of coordinating schedules", "Hard to find qualified tutors"],
      tag: "Expensive",
    },
    {
      title: "Teach Them Yourself",
      price: "Free",
      icon: UserX,
      impacts: ["Requires hours of time", "Often frustrating process", "Can strain your relationship", "Not a trained educator", "Likely not sustainable", "No in-class support"],
      impactsMobile: ["Requires significant time commitment", "Often leads to frustrating interactions", "Can strain parent-child relationship", "Not a professionally trained educator", "Difficult to maintain long-term", "No support during actual classes"],
      tag: "Impractical",
    },
    {
      title: "Do Nothing",
      price: "Free",
      icon: AlertTriangle,
      impacts: ["Grades continue to suffer", "Anxiety and stress build", "Hurts college options", "Self-esteem keeps dropping", "Learning deficits grow", "Future opportunities narrow"],
      impactsMobile: ["Grades continue to suffer over time", "Anxiety and stress levels keep building", "Severely limits future college options", "Self-esteem and confidence keep dropping", "Learning deficits grow larger each semester", "Future career opportunities become  limited"],
      tag: "Detrimental",
    }
  ];


    
  return (
    <section style={styles.section}>
      <SectionHeader title="The problem is that all of your options are bad." />

      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '1rem', marginBottom: '1rem'}}>
        {options.map((option, index) => (
          <div key={index} style={{padding: isPhoneAndBelow ? '2rem' : '1rem', backgroundColor: '#fff', borderRadius: '12px', border: '1px solid var(--color-separatorOpaque)', position: 'relative'}}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '.5rem'}}>
              <div style={{flex: 1}}>
                <div style={{fontSize: isPhoneAndBelow ? '1rem' : '0.875rem', color: '#666', marginBottom: '0.25rem'}}>Option {index + 1}</div>
                <h3 style={{fontSize: isPhoneAndBelow ? '1.5rem' : '1.25rem', fontWeight: '600', marginBottom: '0.25rem'}}>{option.title}</h3>
              </div>
            </div>

            <ul style={{margin: '0', padding: '0', listStyle: 'none',  }}>
              { (isPhoneAndBelow ? option.impactsMobile : option.impacts).map((impact, i) => (
                <li key={i} style={{display: 'flex', alignItems: 'center', color: '#444', fontSize: '1rem', lineHeight: 1.5, marginTop: '1rem'}}>
                  <X size={isPhoneAndBelow ? 22 : 16} style={{marginRight: '0.5rem', flexShrink: 0}} color={"#9f1239"} />
                  <span style={{fontSize: '1rem'}}>{impact}</span>
                </li>
              ))}
            </ul>

            {/* { isPhoneAndBelow && ( */}
              <div style={{position: 'absolute', top: '-1rem', right: '1rem', backgroundColor: "#9f1239", color: 'white', padding: '0.25rem 0.5rem', borderRadius: '6px', fontSize: '0.875rem',
                ...(isPhoneAndBelow && { top: '1rem', right: '1rem', fontSize: '1rem' })

              }}>
                {option.tag}
              </div>
            {/* )} */}
          </div>
        ))}
      </div>
    </section>
  );
};

const TransitionSection = () => {
  return (
    <section style={{...styles.section, }}>

      <SectionHeader title="There had to be a better way. That's why we built School Goat." />

      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: '3rem 1.5rem', backgroundColor: '#f8fafc', borderRadius: '12px', }}>
        {/* <h3 style={{fontSize: '1.125rem', fontWeight: '600', color: '#0f172a', marginBottom: '1.5rem', whiteSpace: "pre-line`"}}>
          There had to be a better way,
          so we built
        </h3> */}

        <img 
          src={SchoolGoat} 
          alt="School Goat Logo" 
          style={{width: '120px', height: '120px', margin: '0 auto', display: 'block'}}
        />

        <h3 style={{fontSize: '3rem', fontWeight: '600', color: '#0f172a', marginBottom: '.5rem'}}>
          School Goat
        </h3>
        
        <p style={{fontSize: '1.125rem', lineHeight: '1.7', color: '#334155', }}>
          The all-in-one academic solution for struggling students.
        </p>
      </div>
    </section>
  );
};

const SolutionSection = ({ isPhoneAndBelow }) => {
  const data = useMemo(() => [
    {
      component: <NotebookPen />,
      label: "In-Class",
      title: "Note-taking support",
      title2: "Note-taking support",
      description: "School Goat will listen to your child's lectures and take notes for them, so they can focus and learn.",
      description2: "School Goat will listen to your child's lectures and take detailed notes for them. This allows them to focus on listening and absorbing the material during class."
    },
    {
      component: <ListChecks />,
      label: "Homework",
      title: "24/7 homework help",
      title2: "24/7 homework help",
      description: "School Goat provides step-by-step guidance through homework problems and is always available to them.",
      description2: "School Goat walks your student through their homework step-by-step. It helps them to understand how to solve problems and is available 24/7 whenever they get stuck."
    },
    {      
      component: <ChartNoAxesCombined />,
      label: "Testing",
      title: "Guided exam prep",
      title2: "Guided exam preparation",
      description: "School Goat will prepare your child for tests with class-specific practice tests and study guides.",
      description2: "School Goat will prepare your child for tests with class-specific practice tests and study guides, so they walk into each exam with confidence."
    },
    {
      component: <Zap />,
      label: "Studying",
      title: "Instant study tools",
      title2: "Custom study materials",
      description: "School Goat will create flashcards and practice quizzes for your child's class so they can study efficiently.",
      description2: "School Goat will create every study tool your child needs - flashcards, practice quizzes, and more. So that when they go to study, they do so efficiently."
    },
    {
      component: <PenLine />,
      label: "Writing",
      title: "Essay guidance",
      title2: "Essay feedback and guidance",
      description: "School Goat will help your child write their essays with detailed feedback and advice prior to turning them in.",
      description2: "School Goat will work with your child to improve their essays, giving individualized feedback at each step and guidance on how to improve - all before they have to turn it in."
    },
    {
      
      component: <GraduationCap />,
      label: "Learning",
      title: "Custom tutorials",
      title2: "Personalized tutorials",
      description: "School Goat will break down any subject and explain it to your child step by step, so they master the material.",
      description2: "School Goat will act as a personal tutor, walking your child through any topic step-by-step. It adapts to their learning style, breaks down complex concepts, and helps build deep understanding."
    },
  ], []);

  return (
    <section aria-label={"How it works"}>
      <SectionHeader title={"School Goat helps your child with every aspect of school"} />
      
      { isPhoneAndBelow ? (
        <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', borderBottom: "1px solid var(--color-separatorOpaque)"}}>
          {data.map((item, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, backgroundColor: "var(--color-backgroundSecondary)", padding: "1.5rem 1rem", borderTop: "1px solid var(--color-separatorOpaque)", position: "relative"}}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '1rem' }}>
                  <CheckCircleIcon size={14} style={{ color: 'var(--color-text4)' }} />
                  <span style={{ color: 'var(--color-text4)', fontSize: '1rem' }}>
                    {item.label}
                  </span>
                </div>
                <h3 style={{fontSize: '1.3rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}>
                  {item.title2}
                </h3>
                <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.75rem'}}>
                  {item.description2}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : 
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem', justifyContent: 'center', maxWidth: '1200px', margin: '0 auto'}}>
          { data.map((item, index) => (
            <div key={index} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.75rem', borderRadius: '.75rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', height: "350px", position: "relative"}}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '1rem' }}>
                <CheckCircleIcon size={14} style={{ color: 'var(--color-text4)' }} />
                <span style={{ color: 'var(--color-text4)', fontSize: '0.9rem' }}>
                  {item.label}
                </span>
              </div>
              <h3 style={{fontSize: '1.25rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.3'}}>
                {item.title}
              </h3>
              <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.5rem'}}>
                {item.description}
              </p>
    
              <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", height: "175px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                {React.cloneElement(item.component, { color: 'black', size: "4.25rem", strokeWidth: .95 })}
              </div>
            </div>
          ))}
        </div>
      }
    </section>
  );
};

const TestimonialsSection = () => { 
  return (
    <section style={styles.section}>
      <SectionHeader title="Students love using it." />
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '1rem'}}>
        {[
          {
            text: "I went from a C- to a B+ in Chemistry within two months. The difference? I finally understood the material, thanks to School Goat breaking everything down in a way that made sense to me.",
            author: "Brandy C., Student"
          },
          {
            text: "I actually enjoy studying now because I have the support I need. My last report card showed all A's and B's - a complete turnaround from where I was before.",
            author: "Michael R., Student"
          },
          {
            text: "School Goat is like having a private tutor available whenever I need help. Not only have my grades improved dramatically, but my confidence has soared. I finally feel like I can succeed in school.",
            author: "Lisa K., Student"
          }
        ].map((testimonial, index) => (
          <div key={index} style={{    backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: '8px', display: 'flex', flexDirection: 'column'}}>
            <p style={styles.testimonialText}>
              "{testimonial.text}"
            </p>
            <p style={{...styles.testimonialAuthor, marginTop: 'auto'}}>
              - {testimonial.author}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

const TrustSection = ({ isPhoneAndBelow }) => {
  const stats = [
    {
      icon: Users,
      number: "20,000+",
      label: "Students Served"
    },
    {
      icon: Trophy,
      number: "1,000+",
      label: "Schools Worldwide"
    },
    {
      icon: Star,
      number: "100,000+",
      label: "Homework Assignments"
    },

  ];

  const schools = [
    "Stanford University",
    "Harvard University",
    "MIT",
    "UCLA",
    "Berkeley",
    "Phillips Academy"
  ];

  return (
    <section style={styles.section}>
      <SectionHeader title="Thousands of students are now reaching their potential" />

      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))', gap: '2rem', }}>
        {stats.map((stat, index) => (
          <div key={index} style={{padding: isPhoneAndBelow ? '1rem' : '2rem', backgroundColor: '#fff', borderRadius: '12px', textAlign: 'center'}}>
            <div style={{backgroundColor: '#f8fafc', width: '48px', height: '48px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 1rem auto'}}>
              <stat.icon size={24} color="#2563EB" />
            </div>
            <div style={{fontSize: '2rem', fontWeight: '700', color: '#2563EB', marginBottom: '0.5rem'}}>{stat.number}</div>
            <div style={{color: '#334155', fontSize: '1.125rem'}}>{stat.label}</div>
          </div>
        ))}
      </div>
{/* 
      <div>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))', gap: '2rem', alignItems: 'center', justifyItems: 'center'}}>
          {schools.map((school, index) => (
            <div key={index} style={{backgroundColor: '#f8fafc', padding: '1.5rem', borderRadius: '8px', width: '100%', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#0f172a', fontWeight: '600'}}>
              {school}
            </div>
          ))}
        </div>
      </div> */}
    </section>
  );
};

const PriceRevealSection = () => {
  return (
    <div style={{width: '100%', }}>
      <div style={{background: 'linear-gradient(180deg, #EBF5FF 0%, #FFFFFF 100%)', borderRadius: '16px', padding: '32px', textAlign: 'center'}}>
        <h2 style={{fontSize: '30px', fontWeight: 'bold', marginBottom: '24px', }}>
          And the best part?
        </h2>
        
        <div style={{color: '#4B5563', fontSize: '20px', marginBottom: '1.5rem', lineHeight: '1.4'}}>
          It costs less than ONE tutoring session...
        </div>
        
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px', marginBottom: '32px'}}>
          <div style={{fontSize: '48px', fontWeight: 'bold', color: '#2563EB'}}>
            $29<span style={{fontSize: '1rem', fontWeight: 'normal'}}>/mo</span>
          </div>
          {/* <div style={{color: '#6B7280', fontSize: '16px'}}>
            Less than a single tutoring session
          </div> */}
        </div>
        
        <div style={{maxWidth: '512px', margin: '0 auto', background: '#EBF5FF', borderRadius: '12px', padding: '24px'}}>
          <div style={{fontSize: '18px', fontWeight: '600', marginBottom: '8px'}}>
            Why so affordable?
          </div>
          <div style={{color: '#4B5563', fontSize: '16px', lineHeight: '1.5'}}>
            Our mission is to make top-tier academic support accessible to every student and parent who needs it. 
            No student should miss out on reaching their potential because of cost.
          </div>
        </div>
      </div>
    </div>
  );
};

const TutoringComparison = () => {

  const Tile = ({ title, price, features, backgroundColor, textColor, icon: Icon, iconColor }) => {
    return (
      <div style={{background: '#FFFFFF', }}>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem', padding: '16px', borderRadius: '8px', background: backgroundColor, color: textColor}}>
        <div>
        <div style={{fontSize: '16px', color: textColor, marginBottom: "4px"}}>{title}</div>
          <div style={{fontSize: '24px', fontWeight: 'bold', }}>{price}</div>
        </div>
      </div>
      <ul style={{listStyle: 'none', padding: '0 8px', margin: 0}}>
        {features.map((feature, index) => (
          <li key={index} style={{display: 'flex', alignItems: 'center', marginBottom: '16px', fontSize: '16px', color: '#374151'}}>
            <Icon style={{width: '24px', height: '24px', marginRight: '12px', color: iconColor}} />
            {feature}
          </li>
        ))}
      </ul>
    </div>
    )
  }


  return (
    <div style={styles.section}>
      <SectionHeader title="School Goat is 90% cheaper than tutoring and is available 24/7" />

      <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '24px'}}>
        
        <Tile title="School Goat" price="$29/month" features={[
          "24/7 instant help, whenever needed",
          "All subjects included", 
          "In-class AND homework support",
          "No scheduling or coordination needed"
        ]} backgroundColor="#EBF5FF" textColor="#2563EB" icon={CheckIcon} iconColor="#2563EB" />

        <Tile title="Traditional Tutoring" price="$400+/month" features={[
          "Limited weekly sessions only",
          "Single subject focus",
          "No in-class support", 
          "Complex scheduling & coordination"
        ]} backgroundColor="#FEF2F2" textColor="#DC2626" icon={X} iconColor="#DC2626" />

      </div>
    </div>
  );
};

const ValueStack = () => {
  return (
    <div style={styles.section}>
      <SectionHeader title="So your student gets everything they need and you don't break the bank" />

      <div style={{display: 'flex', flexDirection: 'column', maxWidth: '700px', margin: '0 auto'}}>
        {[
          {
            title: 'In-Class Note-Taking Support',
            description: 'Never miss important class content again',
            value: '$300/mo',
            icon: <NotebookPen />
          },
          {
            title: '24/7 Homework Help', 
            description: 'Instant help whenever your child needs it',
            value: '$400/mo', 
            icon: <HelpCircle />
          },
          {
            title: 'Guided Exam Preparation',
            description: 'Personalized materials that match their learning style',
            value: '$250/mo',
            icon: <BookOpen />
          },
          {
            title: 'Instant Study Tools',
            description: 'Build confidence before exams',
            value: '$200/mo',
            icon: <FileEdit />
          },
          {
            title: 'Essay Review & Feedback',
            description: 'Professional writing support',
            value: '$300/mo',
            icon: <PenLine />
          },
          {
            title: 'Personal Subject Tutor',
            description: 'One-on-one help in every subject',
            value: '$400/mo',
            icon: <GraduationCap />
          },
          {
            title: 'Total Value',
            description: 'Everything your child needs to succeed',
            value: '$1,850/mo',
            icon: <Calculator />,
            isHighlighted: true
          },
          {
            title: 'Your Price',
            description: 'Everything your child needs to succeed',
            value: 'Only $29',
            icon: <Calculator />,
            isHighlighted: true
          }
        ].map((item, index) => (
          <div key={index} style={{display: 'flex', alignItems: 'center', borderTop: '1px solid #E5E7EB', padding: '16px', backgroundColor: item.isHighlighted ? '#F3F4F6' : 'transparent', }}>
            <div style={{flex: 1}}>
              <div style={{fontSize: item.isHighlighted ? '20px' : '1rem', fontWeight: item.isHighlighted ? 'bold' : 'normal'}}>
                {item.title}
              </div>
            </div>
            <div style={{fontSize: item.isHighlighted ? '20px' : '1rem', fontWeight: item.isHighlighted ? 'bold' : 'normal', color: '#059669', textAlign: 'right'}}>
              {item.value}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RiskFreeSection = () => {
  return (
    <section style={styles.section}>

      <SectionHeader title="And you can try it out risk-free" />
      <div style={{backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '12px', textAlign: 'center', marginBottom: '2rem'}}>
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '2rem', marginBottom: '2rem'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Clock style={{width: '2.5rem', height: '2.5rem', color: '#2563EB', marginBottom: '1rem'}} />
            <h3 style={{fontSize: '1.25rem', fontWeight: '600', color: '#0f172a', marginBottom: '.5rem'}}>3-Day Free Trial</h3>
            <p style={{color: '#475569', lineHeight: '1.5'}}>Test drive all features with no limitations for 3 full days</p>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CreditCard style={{width: '2.5rem', height: '2.5rem', color: '#2563EB', marginBottom: '1rem'}} />
            <h3 style={{fontSize: '1.25rem', fontWeight: '600', color: '#0f172a', marginBottom: '.5rem'}}>Nothing Due Today</h3>
            <p style={{color: '#475569', lineHeight: '1.5'}}>Only pay if you decide to continue after the trial</p>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Mail style={{width: '2.5rem', height: '2.5rem', color: '#2563EB', marginBottom: '1rem'}} />
            <h3 style={{fontSize: '1.25rem', fontWeight: '600', color: '#0f172a', marginBottom: '.5rem'}}>Trial Reminder</h3>
            <p style={{color: '#475569', lineHeight: '1.5'}}>We'll email you before the trial ends - no surprises</p>
          </div>
        </div>
      </div>
    </section>
  );
};

const FinalCTA = ({ handleCheckout, timeLeft }) => {
  return (
    <div style={{...styles.section,}}>
      <SectionHeader title="Don't let another week go by watching your child struggle" />

      <div style={{backgroundColor: '#f0f9ff', padding: '3rem', borderRadius: '16px', textAlign: 'center', }}>

        <div style={{width: '64px', height: '64px', backgroundColor: '#fff', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 1.5rem auto', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',}}>
          <img 
            src={SchoolGoat}
            alt="School Goat Logo"
            style={{width: '48px', height: '48px'}}
          />
        </div>

        <h2 style={{fontSize: '1.75rem', fontWeight: '700', color: '#0c4a6e', marginBottom: '1rem',}}>
          Start Your Free Trial Today
        </h2>
        
        <p style={{fontSize: '1.125rem', color: '#444', maxWidth: '600px', margin: '0 auto 1.5rem', lineHeight: 1.6,}}>
          Give your child the help they need today. Join thousands of parents who've already helped their children achieve better grades with School Goat. Start your 3-day free trial now.
        </p>

        <button style={{padding: '1rem 2rem', fontSize: '1.125rem', fontWeight: '600', backgroundColor: '#2563EB', color: 'white', border: 'none', borderRadius: '8px', cursor: 'pointer', width: '100%'}} onClick={handleCheckout}
        >
          Start Your Free Trial
        </button>

        <p style={{fontSize: '0.875rem', color: '#64748b', marginTop: '1rem', textAlign: 'center'}}>
          Nothing due today • Cancel anytime
        </p>


      </div>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1.5rem', gap: '0.5rem', backgroundColor: '#fee2e2', padding: '0.75rem', borderRadius: '8px', }}>
          {/* <Clock style={{width: '1.25rem', height: '1.25rem', color: '#dc2626'}} /> */}
          <p style={{fontSize: '1rem', color: '#dc2626', fontWeight: '600', textAlign: 'center', whiteSpace: 'pre-wrap'}}>
            This offer for free access is available today only!
            <br/>
            Time Remaining: {timeLeft}
          </p>
        </div>
          
    </div>
  );
};

const TileFaq = ({ item, index, isOpen, toggleItem }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (isOpen) {
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div role="listitem" style={{ width: "100%" }}>
      <h3>
        <button style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "left", padding: "1.5rem 1rem", cursor: "pointer", backgroundColor: "transparent", border: "none", borderTop: index !== 0 ? '1px solid var(--color-separatorOpaque)' : 'none' }}
          aria-controls={`content-${index}`}
          onClick={() => toggleItem(index)}
          
        >
          <span style={{ fontWeight: "600", color: 'var(--color-text2)', fontSize: "1.1rem", textAlign: "left" }}>
            {item.title}
          </span>
          <span aria-hidden="true" style={{ marginLeft: '10px' }}>
            {isOpen ? <ChevronUp size={20} color={'var(--color-text2)'} /> : <ChevronDown size={20} color={'var(--color-text2)'}/>}
          </span>
        </button>
      </h3>
      <div
        id={`content-${index}`}
        role="region"
        aria-labelledby={`heading-${index}`}
        style={{ overflow: "hidden", transition: 'height 300ms ease-in-out, opacity 300ms ease-in-out', height: `${height}px`, opacity: isOpen ? 1 : 0, padding: "0 1rem" }}
        ref={contentRef}
      >
        <div style={{ paddingBottom: '20px' }}>
          <p style={{ color: 'var(--color-text3)', lineHeight: "1.4", fontSize: "1rem", textAlign: "left" }}>
            {item.description}
          </p>
        </div>
      </div>
    </div>
  );
};

const FaqSection = ({  }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = (index) => {
    setOpenIndex(prevIndex => prevIndex === index ? null : index);
  };

  const data = [
    {
      title: "What is School Goat?",
      description: "School Goat is an all-in-one educational tool for students. It helps students with everything from taking notes in class, to studying for exams, to writing essays, and more.",
    },
    {
      title: "Is it easy to use?", 
      description: "School Goat is extremely easy to use. All ages can get the hang of it in minutes.",
    },
    {
      title: "What age students can use it?", 
      description: "Students of any age can use School Goat. Whether you're in middle school, high school, college, or graduate school, School Goat adapts to your level and needs.",
    },
    {
      title: "What types of classes does it support?",
      description: "School Goat supports all subjects and difficulty levels. From basic math to advanced physics, English literature to computer science, we help with any class or subject.",
    },
    
    {
      title: "Can I try it before being charged?",
      description: "Yes! Start with a 3-day free trial to test all features. You can cancel anytime during the trial.",
    },
    {
      title: "Can you remind me before trial ends?",
      description: "Absolutely. We'll email you before your free trial ends and at the time of any renewal charges. We believe in complete transparency - no surprise charges ever.",
    },
    {
      title: "Is it easy to cancel?",
      description: "Yes, you can easily cancel anytime directly on the website. No phone calls needed, no questions asked. Your subscription will continue until the end of your billing period.",
    },
  ]

  return (
    <section aria-label={"FAQs"} style={styles.section}>
      <SectionHeader title={"FAQs"} />

      <div style={{ paddingTop: '.5rem', paddingBottom: '.5rem', display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center"}}
        role="list"
      >
        {data.map((item, index) => (
          <TileFaq 
            key={index} 
            item={item} 
            index={index} 
            isOpen={openIndex === index} 
            toggleItem={toggleItem}
          />
        ))}
      </div>
    </section>
  );
}

const ClaimPopup = ({ handleClose, subscriptionClaimId, showClaimPopup }) => {
  if (!subscriptionClaimId || !showClaimPopup) return null;

  const claimUrl = `${process.env.REACT_APP_FRONTEND_URL}/${Routes.SUBSCRIPTION_CLAIM}/${subscriptionClaimId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(claimUrl);
    alert('Link copied to clipboard!');
  };  


  return (
    <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 999,
        animation: 'fadeIn 0.2s ease-in',
      }} />
      <div style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '2rem', borderRadius: '12px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', maxWidth: '90%', width: '600px', zIndex: 1000, animation: 'slideIn 0.3s ease-out'}}>
        <h3 style={{fontSize: '1.5rem', fontWeight: '600', color: '#111', marginBottom: '1rem'}}>
          Subscription Activated! 🎉
        </h3>
        
        <p style={{color: '#555', lineHeight: 1.5, marginBottom: '1.5rem'}}>
          Below is your activation link. Have your child visit this link to claim their School Goat account and get instant access to all features. We've also emailed this link to you for safekeeping.
        </p>

        <div style={{display: 'flex', gap: '0.5rem', backgroundColor: '#f5f5f5', padding: '0.75rem', borderRadius: '8px', marginBottom: '1rem'}}>
          <input 
            type="text"
            value={claimUrl}
            readOnly
            style={{flex: 1, border: 'none', backgroundColor: 'transparent', fontSize: '0.875rem'}}
          />
          <button 
            onClick={copyToClipboard}
            style={{padding: '0.5rem 1rem', backgroundColor: '#2563EB', color: 'white', border: 'none', borderRadius: '6px', cursor: 'pointer', fontSize: '0.875rem'}}
          >
            Copy
          </button>
        </div>

        <button 
          onClick={handleClose}
          style={{width: '100%', padding: '0.75rem', backgroundColor: '#f5f5f5', border: 'none', borderRadius: '6px', cursor: 'pointer', color: '#555'}}
        >
          Close
        </button>

        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; }
              to { opacity: 1; }
            }
            @keyframes slideIn {
              from { 
                opacity: 0;
                transform: translate(-50%, -45%);
              }
              to { 
                opacity: 1;
                transform: translate(-50%, -50%);
              }
            }
          `}
        </style>
      </div>
    </>
  );
}

const LandingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isPhoneAndBelow } = useBreakpoint();
  const { user, paddle } = useContext(UserContext);
  const [ openAuthModal, setOpenAuthModal ] = useState(false); 
  const [ subscriptionClaimId, setSubscriptionClaimId ] = useState(null);
  const [showClaimPopup, setShowClaimPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    Pixel.pageView();
  }, []);
              
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const checkoutSuccessId = searchParams.get(SEARCH_PARAMS.checkout_success);
    
    if (checkoutSuccessId) {
      setSubscriptionClaimId(checkoutSuccessId);
      setShowClaimPopup(true);
      Pixel.track('Purchase');
    }
  }, [location.search]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(23, 59, 59, 999);
      
      const diff = midnight - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      
      return `${hours}h ${minutes}m ${seconds}s`;
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    setTimeLeft(calculateTimeLeft());

    return () => clearInterval(timer);
  }, []);

  const handleCheckout = async () => {
    if (!paddle) return;

    try {
      await Promise.allSettled([
        sendSlackNotification(`Going to checkout (PARENT)`, SLACK_WEBHOOK_CHANNELS.stripe)
      ]);
      Pixel.track('InitiateCheckout');
    }
    catch (error) {
      console.error('Error creating checkout session:', error);
    }

    const subscriptionClaimId = uuidv4();

    const checkoutOptions = {
      items: [ { priceId: 'pri_01jk3kzhz58scs02ntssen03m7', quantity: 1 } ],
      settings: {
        theme: 'light',  
        successUrl: `${process.env.REACT_APP_FRONTEND_URL}/parents?${SEARCH_PARAMS.checkout_success}=${subscriptionClaimId}`,
        variant: "one-page"
      },
      // discountId: "dsc_01jk24aqx1fehz4c8zhyk2yzya",
      customData: {
        mongo_subscription_claim_id: subscriptionClaimId,
      }
    };

    paddle.Checkout.open(checkoutOptions);
  };

  const handleClose = () => {
    setShowClaimPopup(false);
    setSubscriptionClaimId(null);
    navigate(location.pathname, { replace: true });
  }


  return (
    <div id="wrapper" >
      <div id="main" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "var(--color-backgroundSecondary)", paddingTop: '2rem'}}>
        <div className="inner" style={{ maxWidth: "800px", gap: "4rem" }}>

          <HeaderSection isPhoneAndBelow={isPhoneAndBelow} />
          <PainPoints />
          <EmpathySection />
          <ConsequencesSection isPhoneAndBelow={isPhoneAndBelow} />
          <BadOptionsSection isPhoneAndBelow={isPhoneAndBelow} />
          <TransitionSection />
          <SolutionSection isPhoneAndBelow={isPhoneAndBelow} />
          <TestimonialsSection />
          <TrustSection isPhoneAndBelow={isPhoneAndBelow} />
          <PriceRevealSection />
          <TutoringComparison />
          <ValueStack />
          <RiskFreeSection />
          <FinalCTA handleCheckout={handleCheckout} timeLeft={timeLeft} />
          <FaqSection />
        </div>
      </div>

      <AuthModal open={openAuthModal} setOpen={setOpenAuthModal} />
      <ClaimPopup handleClose={handleClose} subscriptionClaimId={subscriptionClaimId} showClaimPopup={showClaimPopup} />
    </div>
  );
};

export default LandingScreen;



// const TestimonialsSection = () => { 
//   return (
//     <section style={styles.section}>
//       <SectionHeader title="Parents rave about School Goat." />
//       <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '1rem'}}>
//         {[
//           {
//             text: "My daughter went from a C- to a B+ in Chemistry within two months. The difference? She finally understood the material, thanks to School Goat breaking everything down in a way that made sense to her.",
//             author: "Sarah M., Parent"
//           },
//           {
//             text: "No more homework battles! My son actually enjoys studying now because he has the support he needs. His last report card showed all A's and B's - a complete turnaround.",
//             author: "Michael R., Parent"
//           },
//           {
//             text: "As a single mom working two jobs, I couldn't always be there to help with homework. School Goat has been like having a private tutor available whenever my kids need help. Their grades have improved, but more importantly, their confidence has soared.",
//             author: "Lisa K., Parent"
//           }
//         ].map((testimonial, index) => (
//           <div key={index} style={{    backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: '8px', display: 'flex', flexDirection: 'column'}}>
//             <p style={styles.testimonialText}>
//               "{testimonial.text}"
//             </p>
//             <p style={{...styles.testimonialAuthor, marginTop: 'auto'}}>
//               - {testimonial.author}
//             </p>
//           </div>
//         ))}
//       </div>
//     </section>
//   )
// }


// const RiskReversal = () => {
//   const Card = ({ icon: Icon, title, text }) => (
//     <div style={{
//       backgroundColor: '#fff',
//       padding: '2rem',
//       borderRadius: '12px',
//       boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       textAlign: 'center',
//     }}>
//       <div style={{
//         width: '48px',
//         height: '48px',
//         backgroundColor: '#f8fafc',
//         borderRadius: '12px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         marginBottom: '1.5rem',
//       }}>
//         <Icon size={24} color="#0ea5e9" />
//       </div>
      
//       <h3 style={{
//         fontSize: '1.25rem',
//         fontWeight: '600',
//         color: '#111',
//         marginBottom: '1rem',
//       }}>
//         {title}
//       </h3>
      
//       <p style={{
//         color: '#555',
//         lineHeight: 1.5,
//       }}>
//         {text}
//       </p>
//     </div>
//   );

//   return (
//     <section style={{
//       maxWidth: '1000px',
//       margin: '0 auto',
//       padding: '60px 20px',
//     }}>
//       <div style={{
//         backgroundColor: '#f0f9ff',
//         padding: '3rem',
//         borderRadius: '16px',
//         textAlign: 'center',
//         marginBottom: '3rem',
//       }}>
//         <div style={{
//           width: '64px',
//           height: '64px',
//           backgroundColor: '#fff',
//           borderRadius: '50%',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           margin: '0 auto 1.5rem auto',
//           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
//         }}>
//           <Shield size={32} color="#0ea5e9" />
//         </div>
        
//         <h2 style={{
//           fontSize: '1.75rem',
//           fontWeight: '700',
//           color: '#0c4a6e',
//           marginBottom: '1rem',
//         }}>
//           30-Day Money-Back Guarantee
//         </h2>
        
//         <p style={{
//           fontSize: '1.125rem',
//           color: '#444',
//           maxWidth: '600px',
//           margin: '0 auto',
//           lineHeight: 1.6,
//         }}>
//           If you're not completely satisfied with School Goat, or if your child's grades 
//           don't improve, we'll refund every penny. No questions asked. We're that confident 
//           in our ability to help your child succeed.
//         </p>
//       </div>

//       <div style={{
//         display: 'grid',
//         gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
//         gap: '2rem',
//       }}>
//         <Card
//           icon={Calendar}
//           title="Try Your First Month for $1"
//           text="See the difference School Goat can make in your child's academic life. Get full access to all features for just $1 for your first month."
//         />
        
//         <Card
//           icon={X}
//           title="Cancel Anytime"
//           text="No long-term contracts. No commitments. Cancel instantly at any time with just one click. We earn your business month after month."
//         />
//       </div>
//     </section>
//   );
// };

// const TileBenefits_Phone = ({ item, index, isActive }) => (
//   <article style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: '0 0 85%', maxWidth: '90%', scrollSnapAlign: 'center', opacity: isActive ? 1 : 0.5, transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out", marginRight: "20px" }}
//     key={index}
//     aria-hidden={!isActive}
//   >
//     <img
//       style={{ width: "100%", aspectRatio: ".9", objectFit: "cover", borderRadius: "15px", boxShadow: `var(--shadow)` }}
//       src={item.image}
//       alt={item.alt}
//     />

//     <div style={{ display: "flex", flexDirection: "column", padding: "0 .5rem", opacity: isActive ? 1 : 0, transition: "opacity 0.5s ease-in-out" }}>
//       <h4 style={{ fontSize: "1.3rem", lineHeight: "1.4", whiteSpace: "pre-wrap", maxWidth: "100%", marginTop: "1rem", fontWeight: "600", textAlign: "left" }}>
//         {item.title}
//       </h4>
//       <p style={{ marginTop: ".2rem", color: "var(--color-text3)", lineHeight: "1.4", fontSize: "1.1rem", textAlign: "left" }}>
//         {item.text}
//       </p>
//     </div>

//   </article>
// )

// const BenefitsSectionPhone = ({  }) => {

//   const [activeIndex, setActiveIndex] = useState(0);
//   const activeIndexRef = useRef(0);
//   const scrollContainerRef = useRef(null);
//   const updateActiveIndex = () => {
//     if (scrollContainerRef.current) {
//       const scrollLeft = scrollContainerRef.current.scrollLeft;
//       const itemWidth = scrollContainerRef.current.offsetWidth;
//       const newActiveIndex = Math.round(scrollLeft / itemWidth);
//       activeIndexRef.current = newActiveIndex;
//       setActiveIndex(newActiveIndex);
//     }
//   };

//   useEffect(() => {
//     const scrollContainer = scrollContainerRef.current;
//     if (scrollContainer) {
//       scrollContainer.addEventListener('scroll', updateActiveIndex);
//       return () => scrollContainer.removeEventListener('scroll', updateActiveIndex);
//     }
//   }, []);

//   const dataBenefits = [
// {
//   title: "Better grades",
//   text: "With a little help from School GOAT, you can reach your potential and get the best grades possible.",
//   component: <ChartColumnIncreasing />,
//   image: Benefit4,
//   alt: "Girl writing on chalkboard",
// }, 
// {
//   title: "Better life",
//   text: "School Goat lightens your load. Meaning you can spend more time with friends, family, and your social life.",
//   component: <BatteryCharging />,
//   image: Benefit3,
//   alt: "College graduates throwing their caps up",
// },
// {
//   title: "Better mental health",
//   text: "School doesn't have to be constant stress. With a personal assistant, you'll get more done with less stress.",
//   component: <Smile />,
//   image: Benefit1,
//   alt: "College students relaxing and smiling",
//   },
// {
//   title: "Better focus",
//   text: "You don't have to do everything yourself. School Goat will help. Which will make it easier for you to focus.",
//   component: <ScanFace />,
//   image: Benefit2,
//   alt: "College guy carefully writing notes",
// },
// ]


//   return (
//     <section style={styles.section}>
//     <SectionHeader title={"What this can do for you"} />

//       <div className="horizontalScrollContainer" style={{ display: 'flex', alignItems: 'flex-start', overflowX: 'auto', width: '100%', paddingBottom: '20px', scrollSnapType: 'x mandatory' }}
//         ref={scrollContainerRef}
//         aria-label="Scrollable benefits list"
//         role="region"
//         tabIndex="0"
//       >
//         {dataBenefits.map((item, index) => (
//           <TileBenefits_Phone key={index} item={item} index={index} isActive={activeIndexRef.current === index} role="listitem" />
//         ))}
//       </div>
//     </section>
//   );
// };


// const ComparisonSection2 = () => {    
//   return (
//     <section style={styles.section}>
//       <SectionHeader title="6. And it's a no-brainer compared to the alternatives." />

//       <div style={{padding: '2.5rem', backgroundColor: '#f0f9ff', borderRadius: '12px', border: '2px solid #0ea5e9'}}>
//         <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '2.5rem'}}>
//           {[
//             "In-class support with note taking",
//             "Help across all subjects and levels", 
//             "Creates custom study materials",
//             "24/7 homework guidance and solutions",
//             "Restores parent-child relationship",
//             "Costs less than ONE tutoring session",
//             "Builds lasting academic confidence",
//             "Personalized learning experience",
//             "Improves grades and test scores"
//           ].map((benefit, index) => (
//             <div key={index} style={{display: 'flex', alignItems: 'flex-start'}}>
//               <CheckCircle2 size={20} color="#0ea5e9" style={{marginRight: '0.75rem', marginTop: '0.25rem', flexShrink: 0}} />
//               <span>{benefit}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };


// const OfferStackSimple = () => {
//   const features = [
//     {
//       icon: BookOpen,
//       title: "In-Class Notes", 
//       value: "$249 value",
//     },
//     {
//       icon: FileEdit,
//       title: "Study Guides",
//       value: "$199 value",
//     },
//     {
//       icon: ListChecks,
//       title: "Practice Exams",
//       value: "$249 value",
//     },
//     {
//       icon: BookCheck,
//       title: "Flashcards",
//       value: "$149 value",
//     },
//     {
//       icon: CheckSquare,
//       title: "Interactive Tests",
//       value: "$249 value",
//     },
//     {
//       icon: Users,
//       title: "Topic Tutorials",
//       value: "$399 value",
//     },
//     {
//       icon: PenTool,
//       title: "Homework Help",
//       value: "$299 value",
//     },
//     {
//       icon: BarChart3,
//       title: "Essay Review",
//       value: "$199 value",
//     },
//     {
//       icon: FileIcon,
//       title: "Study Summaries",
//       value: "$199 value",
//     },
//   ];

//   return (
//     <section style={{maxWidth: '1000px', margin: '0 auto', padding: '60px 20px'}}>
//       <h2 style={{...styles.mainHeading, fontSize: '2rem'}}>
//         8. Get all this for less than the cost of ONE tutoring session
//       </h2>

//       <div style={{display: 'flex', flexDirection: 'column', border: '1px solid #e5e7eb', padding: '2rem', borderRadius: '12px'}}>
//         <div style={{textAlign: 'center', marginBottom: '2rem'}}>
//           <h3 style={{fontSize: '1.75rem', fontWeight: '600', color: '#0c4a6e', marginBottom: '.5rem'}}>
//             Total Value Included
//           </h3>
//           <p style={{fontSize: '1.125rem', color: '#555'}}>Everything your child needs to succeed</p>
//         </div>

//         <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem', marginBottom: '1rem'}}>
//           {features.map((feature, index) => (
//             <div key={index} style={{backgroundColor: '#fff', padding: '.75rem', borderRadius: '12px', display: 'flex', flexDirection: 'column'}}>
//               <h3 style={{fontSize: '1.25rem', fontWeight: '600', color: '#111', marginBottom: '0.5rem', textAlign: 'center'}}>{feature.title}</h3>
//               <div style={{color: 'var(--color-text4)', fontSize: '1.125rem', fontWeight: '600', marginBottom: '1rem', textAlign: 'center'}}>{feature.value}</div>
//             </div>
//           ))}
//         </div>
//       </div>

//       <div style={{backgroundColor: '#f8f9fa', padding: '3rem', borderRadius: '16px', textAlign: 'center', position: 'relative'}}>
//         <div style={{fontSize: '1.25rem', color: '#666', marginBottom: '3rem'}}>
//           <div>Combined Value</div>
//           <div style={{fontSize: '2.5rem', fontWeight: '700', color: '#111', marginTop: '0.5rem'}}>$946/month</div>
//         </div>

//         <div style={{backgroundColor: '#fff', padding: '2rem', borderRadius: '12px', maxWidth: '400px', margin: '0 auto', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'}}>
//           <div style={{color: '#666'}}>Your Price</div>
//           <div style={{fontSize: '3rem', fontWeight: '800', color: '#0ea5e9', marginTop: '0.5rem'}}>$29</div>
//           <div style={{color: '#666', fontSize: '1rem'}}>per month</div>
//           <button style={{backgroundColor: '#0ea5e9', color: '#fff', padding: '1.25rem 3rem', borderRadius: '8px', fontSize: '1.25rem', fontWeight: '600', border: 'none', cursor: 'pointer', marginTop: '2rem', width: '100%'}}>
//             Get Started Now
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// const TransformationSection = () => {
//   return (
//     <section style={styles.section}>
//       <h2 style={{...styles.mainHeading, fontSize: '2rem'}}>
//         The Bridge to Your Child's Academic Success
//       </h2>

//       <div style={{ fontSize: '1.125rem', color: '#444', marginBottom: '2rem', lineHeight: 1.6, }}>
//         <p style={{ marginBottom: '1.5rem' }}>
//           Your child is struggling now - falling behind in class, losing confidence, and feeling overwhelmed. 
//           You've tried tutoring, study groups, and endless hours of homework help. But nothing seems to stick.
//         </p>
        
//         <p style={{ marginBottom: '2rem' }}>
//           What's missing is a complete support system that works the way your child needs to learn.
//         </p>
        
//         <p style={{ 
//           fontSize: '1.25rem',
//           fontWeight: '500',
//           marginBottom: '2rem',
//         }}>
//           School Goat is that missing piece - working alongside your child at every step of their academic journey:
//         </p>
//       </div>

//       <div style={{
//         display: 'grid',
//         gap: '2rem',
//       }}>
//         {[
//           {
//             phase: "In the Classroom",
//             actions: [
//               "Takes detailed notes while your child focuses on understanding",
//               "Captures everything they might miss"
//             ],
//             result: "No more gaps in learning or falling behind"
//           },
//           {
//             phase: "During Study Time",
//             actions: [
//               "Instantly creates personalized study materials",
//               "Explains concepts in ways they understand"
//             ],
//             result: "Better comprehension and test scores"
//           },
//           {
//             phase: "During Homework",
//             actions: [
//               "Provides step-by-step guidance",
//               "Available whenever they're stuck"
//             ],
//             result: "Less frustration, more confidence"
//           },
//           {
//             phase: "Through Assignments",
//             actions: [
//               "Helps organize and structure essays",
//               "Gives instant feedback for improvement"
//             ],
//             result: "Better grades and writing skills"
//           }
//         ].map((step, index) => (
//           <div key={index} style={{
//             padding: '1.5rem',
//             backgroundColor: '#f8f9fa',
//             borderRadius: '8px',
//             border: '1px solid #eee',
//           }}>
//             <h3 style={{
//               fontSize: '1.25rem',
//               fontWeight: '600',
//               color: '#111',
//               marginBottom: '1rem',
//             }}>
//               {step.phase}
//             </h3>
            
//             <ul style={{
//               listStyle: 'none',
//               padding: 0,
//               margin: '0 0 1rem 0',
//             }}>
//               {step.actions.map((action, i) => (
//                 <li key={i} style={{
//                   marginBottom: '0.5rem',
//                   paddingLeft: '1.5rem',
//                   position: 'relative',
//                 }}>
//                   <span style={{
//                     position: 'absolute',
//                     left: 0,
//                     color: '#666',
//                   }}>•</span>
//                   {action}
//                 </li>
//               ))}
//             </ul>
            
//             <div style={{
//               marginTop: 'auto',
//               paddingTop: '1rem',
//               borderTop: '1px solid #eee',
//               color: '#065f46',
//               fontWeight: '500',
//             }}>
//               Result: {step.result}
//             </div>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };

// const SalesPage = () => {
//   return (
//     <div style={styles.container}>

//       {/* Solution Section */}
      

//          {/* Why Trust Us Section */}
//          <section style={styles.section}>
//         <h2 style={{...styles.mainHeading, fontSize: '2rem'}}>
//           The School Goat Difference
//         </h2>
        
//         <div style={{marginBottom: '2rem'}}>
//           <p style={{fontSize: '1.1rem', marginBottom: '1.5rem'}}>
//             As an educator and parent, I've spent over a decade understanding how students learn 
//             and what makes the difference between struggle and success. School Goat isn't just 
//             another AI tool - it's a comprehensive academic support system built on proven 
//             educational principles.
//           </p>
//         </div>

//         <div style={{
//           display: 'grid',
//           gap: '2rem',
//           marginTop: '2rem'
//         }}>
//           <div style={{
//             padding: '1.5rem',
//             backgroundColor: '#fff',
//             borderRadius: '8px',
//             boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
//           }}>
//             <h3 style={{
//               fontSize: '1.25rem',
//               fontWeight: 600,
//               marginBottom: '1rem',
//               color: '#333'
//             }}>
//               Comprehensive Support
//             </h3>
//             <p style={{color: '#555', marginBottom: '0.75rem'}}>
//               Not just tutoring, but a complete academic toolkit that helps with everything from 
//               note-taking to essay writing.
//             </p>
//             <p style={{color: '#666', fontStyle: 'italic'}}>
//               Impact: Your child never feels lost or overwhelmed, building confidence across all subjects.
//             </p>
//           </div>

//           <div style={{
//             padding: '1.5rem',
//             backgroundColor: '#fff',
//             borderRadius: '8px',
//             boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
//           }}>
//             <h3 style={{
//               fontSize: '1.25rem',
//               fontWeight: 600,
//               marginBottom: '1rem',
//               color: '#333'
//             }}>
//               Personalized Learning
//             </h3>
//             <p style={{color: '#555', marginBottom: '0.75rem'}}>
//               Adapts to your child's learning style and pace, breaking down complex concepts 
//               in ways they understand.
//             </p>
//             <p style={{color: '#666', fontStyle: 'italic'}}>
//               Impact: Better grades and deeper understanding, leading to genuine academic confidence.
//             </p>
//           </div>

//           <div style={{
//             padding: '1.5rem',
//             backgroundColor: '#fff',
//             borderRadius: '8px',
//             boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
//           }}>
//             <h3 style={{
//               fontSize: '1.25rem',
//               fontWeight: 600,
//               marginBottom: '1rem',
//               color: '#333'
//             }}>
//               Always Available Support
//             </h3>
//             <p style={{color: '#555', marginBottom: '0.75rem'}}>
//               Instant help whenever your child needs it, eliminating the frustration of being stuck 
//               without support.
//             </p>
//             <p style={{color: '#666', fontStyle: 'italic'}}>
//               Impact: No more homework battles or late-night stress - just steady progress and growing independence.
//             </p>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };





// const OfferStack = () => {
//   return (
//     <section style={styles.section}>
//       <SectionHeader title="8. You get everything your child needs, for less than the cost of ONE tutoring session" />

//       <div style={{display: 'flex', flexDirection: 'column', gap: '1.25rem', marginTop: '1rem'}}>
//         {[
//           ['AI Note-taking Assistant', '$1,200 value'],
//           ['24/7 Homework Helper', '$2,400 value'], 
//           ['Custom Study Guides', '$1,200 value'],
//           ['Custom Practice Tests', '$950 value'],
//           ['Personal Subject Tutor', '$1,550 value'],
//           ['Essay Review & Feedback', '$1,550 value'],
//         ].map(([name, value], index) => (
//           <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
//             <div style={{display: 'flex', alignItems: 'center'}}>
//               <CheckCircle2 size={16} color="#0ea5e9" style={{marginRight: '0.5rem'}} />
//               <span>{name}</span>
//             </div>
//             <span style={{color: '#666'}}>{value}</span>
//           </div>
//         ))}
//       </div>

//       <div style={{backgroundColor: '#f8f9fa', padding: '3rem', borderRadius: '16px', textAlign: 'center', position: 'relative'}}>
//         <div style={{fontSize: '1.25rem', color: '#666', marginBottom: '3rem'}}>
//           <div>Combined Value</div>
//           <div style={{fontSize: '2.5rem', fontWeight: '700', color: '#111', marginTop: '0.5rem'}}>$946/month</div>
//         </div>

//         <div style={{backgroundColor: '#fff', padding: '2rem', borderRadius: '12px', maxWidth: '400px', margin: '0 auto', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'}}>
//           <div style={{color: '#666'}}>Your Price</div>
//           <div style={{fontSize: '3rem', fontWeight: '800', color: '#0ea5e9', marginTop: '0.5rem'}}>$29</div>
//           <div style={{color: '#666', fontSize: '1rem'}}>per month</div>
//           <button style={{backgroundColor: '#0ea5e9', color: '#fff', padding: '1.25rem 3rem', borderRadius: '8px', fontSize: '1.25rem', fontWeight: '600', border: 'none', cursor: 'pointer', marginTop: '2rem', width: '100%'}}>
//             Get Started Now
//           </button>
//         </div>
//       </div>
//           </section>
//   );
// };

// const SolutionSection = () => {
//   const styles2 = {
//     tableWrapper: {
//       overflowX: 'auto',
//       width: '100%'
//     },
//     table: {
//       width: '100%',
//       borderCollapse: 'collapse',
//       backgroundColor: '#ffffff'
//     },
//     th: {
//       textAlign: 'left',
//       padding: '16px 16px',
//       fontSize: '0.875rem',
//       fontWeight: '500',
//       color: '#6b7280',
//       textTransform: 'uppercase',
//       letterSpacing: '0.05em',
//       borderBottom: '1px solid #e5e7eb'
//     },
//     td: {
//       padding: '16px 16px',
//       borderBottom: '1px solid #f3f4f6'
//     },
//     typeCell: {
//       width: '15%',
//       display: 'flex',
//       alignItems: 'center',
//       gap: '12px'
//     },
//     descriptionCell: {
//       width: '55%',
//       color: '#4b5563',
//       lineHeight: '1.5'
//     },
//     benefitCell: {
//       width: '30%',
//       color: '#1e40af',
//       fontSize: '0.875rem',
//       fontWeight: '500'
//     },
//     iconWrapper: {
//       backgroundColor: '#f0f9ff',
//       padding: '8px',
//       borderRadius: '8px',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center'
//     },
//     typeName: {
//       fontWeight: '500',
//       color: '#111827'
//     },
//     tr: {
//       transition: 'background-color 0.2s'
//     }
//   };

//   const solutions = [
//     {
//       icon: BookOpen,
//       type: "InClass",
//       description: "School Goat can take detailed notes for your child in class. This allows them to focus on understanding concepts. Captures key examples, important due dates, and class discussions.",
//       benefit: "Better comprehension and engagement in class"
//     },
//     {
//       icon: GraduationCap,
//       type: "Learning",
//       description: "School Goat acts as a personal tutor, walking students through any topic step-by-step. It adapts to their learning style, breaks down complex concepts, and helps build deep understanding.",
//       benefit: "Mastery of challenging subjects and concepts"
//     },
//     {
//       icon: PenTool,
//       type: "Homework",
//       description: "School Goat can provide step-by-step guidance through difficult problems. It explains concepts clearly and is available 24/7 whenever they get stuck.",
//       benefit: "Reduced frustration and improved homework completion"
//     },
//     {
//       icon: BookCheck,
//       type: "Tests",
//       description: "School Goat can create transform their class materials into every study tool they need. It creates personalized study guides, practice quizzes, flashcards, and more.",
//       benefit: "Better test scores and reduced study stress"
//     },
//     {
//       icon: FileEdit,
//       type: "Essays",
//       description: "Analyzes essays against assignment rubrics and provides detailed, actionable feedback. Helps structure arguments, suggests improvements with specific examples, and ensures all prompt requirements are met.",
//       benefit: "Stronger writing skills and better grades"
//     }
//   ];

//   return (
//     <section style={styles.section}>
//       <h2 style={{...styles.mainHeading, fontSize: '2rem'}}>
//         4. School Goat helps your child reach their potential in all aspects of school.
//       </h2>

//       <div style={styles2.tableWrapper}>
//         <table style={styles2.table}>
//           <thead>
//             <tr>
//               <th style={styles2.th}>Context</th>
//               <th style={styles2.th}>What It Does</th>
//               <th style={styles2.th}>The Result</th>
//             </tr>
//           </thead>
//           <tbody>
//             {solutions.map((solution, index) => (
//               <tr 
//                 key={index} 
//                 style={styles2.tr}
//                 onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f9fafb'}
//                 onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#ffffff'}
//               >
//                 <td style={styles2.td}>
//                   <div style={styles2.typeCell}>
//                     <p style={styles2.typeName}>{solution.type}</p>
//                   </div>
//                 </td>
//                 <td style={{...styles2.td, ...styles2.descriptionCell}}>
//                   {solution.description}
//                 </td>
//                 <td style={{...styles2.td, ...styles2.benefitCell}}>
//                   {solution.benefit}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </section>
//   );
// };


// const SolutionSection = () => {
//   const steps = [
//     {
//       icon: BookOpen,
//       title: "In Class Support",
//       description: "Never miss important information again",
//       features: [
//         "Takes detailed notes while your child focuses on understanding",
//         "Captures key concepts and examples",
//         "Records important assignments and due dates"
//       ],
//       impact: "Your child can focus on learning instead of frantically copying notes"
//     },
//     {
//       icon: PenTool,
//       title: "Homework Help",
//       description: "Turn frustration into understanding",
//       features: [
//         "Step-by-step guidance through difficult problems",
//         "Explains concepts in ways they'll understand",
//         "Available 24/7 whenever they get stuck"
//       ],
//       impact: "No more homework battles or late-night frustrations"
//     },
//     {
//       icon: BookCheck,
//       title: "Test Preparation",
//       description: "Make studying effective and stress-free",
//       features: [
//         "Creates personalized study guides",
//         "Generates practice quizzes and flashcards",
//         "Identifies key topics to focus on"
//       ],
//       impact: "Better test scores through confident, focused preparation"
//     },
//     {
//       icon: FileEdit,
//       title: "Essay Writing Support",
//       description: "From blank page to polished paper",
//       features: [
//         "Helps organize and structure essays",
//         "Provides constructive feedback",
//         "Suggests improvements for clarity and flow"
//       ],
//       impact: "Develop stronger writing skills and better grades"
//     }
//   ];

//   const styles2 = {
//     subtitle: {
//       fontSize: '1.25rem',
//       color: '#555',
//       maxWidth: '600px',
//       margin: '0 auto',
//     },
//     grid: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
//       gap: '2rem',
//       marginTop: '2rem',
//     },
//     card: {
//       padding: '2rem',
//       backgroundColor: '#fff',
//       borderRadius: '12px',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
//       display: 'flex',
//       flexDirection: 'column',
//     },
//     iconWrapper: {
//       backgroundColor: '#f0f9ff',
//       width: '60px',
//       height: '60px',
//       borderRadius: '12px',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       marginBottom: '1.5rem',
//     },
//     title: {
//       fontSize: '1.5rem',
//       fontWeight: '600',
//       color: '#111',
//       marginBottom: '0.5rem',
//     },
//     description: {
//       fontSize: '1.125rem',
//       color: '#666',
//       marginBottom: '1.5rem',
//     },
//     featureList: {
//       margin: '0',
//       padding: '0',
//       listStyle: 'none',
//     },
//     featureItem: {
//       marginBottom: '0.75rem',
//       paddingLeft: '1.5rem',
//       position: 'relative',
//       color: '#444',
//     },
//     bullet: {
//       position: 'absolute',
//       left: '0',
//       color: '#0ea5e9',
//     },
//     impact: {
//       marginTop: 'auto',
//       paddingTop: '1rem',
//       borderTop: '1px solid #eee',
//       color: '#0c4a6e',
//       fontStyle: 'italic',
//     }
//   };

//   return (
//     <section style={styles.section}>
//       <h2 style={{...styles.mainHeading, fontSize: '2rem', }}>
//         4. School Goat can help your child succeed with all parts of school.
//       </h2>

//       <div style={styles2.grid}>
//         {steps.map((step, index) => (
//           <div key={index} style={styles2.card}>
//             <div style={styles2.iconWrapper}>
//               <step.icon size={30} color="#0ea5e9" />
//             </div>
            
//             <h3 style={styles2.title}>{step.title}</h3>
//             <p style={styles2.description}>{step.description}</p>
            
//             <ul style={styles2.featureList}>
//               {step.features.map((feature, i) => (
//                 <li key={i} style={styles2.featureItem}>
//                   <span style={styles2.bullet}>•</span>
//                   {feature}
//                 </li>
//               ))}
//             </ul>

//             <p style={styles2.impact}>{step.impact}</p>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// };