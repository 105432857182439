import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useBreakpoint } from '../misc/useBreakpoint';
import ReactPlayer from 'react-player/youtube'
import { Play, Pause, RotateCcw } from 'lucide-react';
import { RESOURCES_UPLOAD_METHODS } from '../misc/constants';

const TextPreview = ({ isActive = false, data }) => {
  const [ content, setContent ] = useState(null)
  const { isMobileAndBelow } = useBreakpoint()

  useEffect(() => {
    if ( isActive ) {
      loadContent()
    }
  }, [isActive, data?._id])

  const loadContent = async () => {
    try {
      const result = await axios.get(data.sourceContent.url);
      const results = result?.data;
      setContent(results);
    }
    catch (error) {
      console.error('Error fetching text content:', error);
    }
  }

  if ( !isActive ) return null
  return (
    <div style={{ whiteSpace: 'pre-wrap', color: 'var(--color-text1)', fontSize: '1rem', maxWidth: '910px', width: '100%', alignSelf: 'center', minHeight: '100%',
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '.75rem',  }}>
        <h1 style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text4)', textTransform: 'uppercase' }}>
          Source Text
        </h1>
      </div>
      <div style={{ whiteSpace: 'pre-wrap', color: 'var(--color-text1)', fontSize: '1rem', lineHeight: '1.5rem', }}>
        {content}
      </div>
    </div>
  )
}

const PdfPreview = ({ isActive = false, data }) => {

  const pdfUrl = data?.sources?.[0]?.urlPdf || (data?.sources?.[0]?.fileType === 'pdf' ? data?.sources?.[0]?.url : null) || null

  if ( !isActive ) return null
  return (          
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer
        fileUrl={pdfUrl}
      />
    </Worker>
  )
}

const YoutubePreview = ({ isActive = false, data }) => {
  if ( !isActive ) return null

  const url = data?.sources?.[0]?.url

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        controls={true}
        playing={false}
        config={{
          youtube: {
            playerVars: {
              controls: 1,
              showinfo: 1,
              modestbranding: 1,
              playsinline: 1,
              enablejsapi: 1,
              origin: window.location.origin,
              rel: 0,
              iv_load_policy: 3,
            },
          },
        }}
      />  
    </div>
  )
}

const RecordingPreview = ({ isActive = false, data: recording }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(0);
  const SEGMENT_DURATION = 300; // 5 minutes in seconds

  const totalDuration = recording?.sources?.length ? SEGMENT_DURATION * recording.sources.length : 0;

  useEffect(() => {
    if (!recording?.sources?.length || !isActive) return;
    audioRef.current.src = recording.sources[0].url;
  }, [recording, isActive]);

  const handleEnded = () => {
    if (currentSegment < recording.sources.length - 1) {
      const nextSegment = currentSegment + 1;
      setCurrentSegment(nextSegment);
      audioRef.current.src = recording.sources[nextSegment].url;
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => console.log("Playback error:", error));
      }
    } else {
      setIsPlaying(false);
      setCurrentSegment(0);
      setCurrentTime(0);
      audioRef.current.src = recording.sources[0].url;
    }
  };

  const formatTime = (seconds) => {
    if (!isFinite(seconds)) return '0:00';
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    if (hrs > 0) {
      return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleTimeUpdate = () => {
    let totalTime = audioRef.current.currentTime + (currentSegment * SEGMENT_DURATION);
    setCurrentTime(totalTime);
  };

  const handlePlayPause = async () => {
    try {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        await audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } catch (error) {
      console.error('Playback error:', error);
      setIsPlaying(false);
      alert("Error playing recording, please refresh and try again.")
    }
  };

  const handleSeek = async (e) => {
    try {
      const seekTime = parseFloat(e.target.value);
      const targetSegment = Math.floor(seekTime / SEGMENT_DURATION);
      
      if (targetSegment >= recording.sources.length) return;
      
      const segmentTime = seekTime % SEGMENT_DURATION;
      
      setCurrentSegment(targetSegment);
      setCurrentTime(seekTime);
      
      audioRef.current.src = recording.sources[targetSegment].url;
      audioRef.current.currentTime = segmentTime;
      
      if (isPlaying) {
        await audioRef.current.play();
      }
    } catch (error) {
      console.error('Seek error:', error);
      setCurrentTime(currentTime);
      alert("Error seeking, please try again.")
    }
  };

  const handleReset = () => {
    setIsPlaying(false);
    setCurrentSegment(0);
    setCurrentTime(0);
    audioRef.current.src = recording.sources[0].url;
  };

  if (!recording?.sources?.length || !isActive) return null;

  return (
    <div style={{ width: '100%', maxWidth: '400px', margin: 'auto', padding: '1.5rem', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', boxShadow: 'var(--borderShadow)' }}>
      <audio 
        ref={audioRef}
        onEnded={handleEnded}
        onTimeUpdate={handleTimeUpdate}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
        <button style={{ backgroundColor: '#3b82f6', color: 'white', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
          onClick={handlePlayPause}
        >
          {isPlaying ? <Pause size={20} /> : <Play size={20} />}
        </button>

        <button style={{ backgroundColor: '#e5e7eb', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} 
          onClick={handleReset}
        >
          <RotateCcw size={20} />
        </button>
      </div>
      
      <div style={{ width: '100%', backgroundColor: 'var(--color-separatorOpaque)', height: '4px', borderRadius: '2px', cursor: 'pointer', position: 'relative' }}>
        <input 
          type="range"
          min="0"
          max={totalDuration}
          value={currentTime}
          onChange={handleSeek}
          step="1"
          style={{ width: '100%', position: 'absolute', top: '-6px', height: '16px', opacity: 0, cursor: 'pointer' }}
        />
        <div style={{ width: `${(currentTime / totalDuration) * 100}%`, backgroundColor: '#3b82f6', height: '100%', borderRadius: '2px', transition: 'width 0.1s' }} />
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', color: '#6b7280', fontSize: '14px' }}>
        <span>{formatTime(currentTime)}</span>
        <span>{formatTime(totalDuration)}</span>
      </div>
      
      {/* <div style={{ marginTop: '8px', fontSize: '14px', color: '#6b7280', textAlign: 'center' }}>
        Segment {currentSegment + 1} of {recording.sources.length}
      </div> */}
    </div>
  );
};


const ResourceSourceScreen = ({  }) => {
  const { data } = useOutletContext()
  const { isMobileAndBelow } = useBreakpoint()
  const textPreviewTrue = data?.uploadMethod === RESOURCES_UPLOAD_METHODS.text
  const pdfPreviewTrue = data?.uploadMethod === RESOURCES_UPLOAD_METHODS.file && ( data?.sources?.[0]?.fileType === 'pdf' || data?.sources?.[0]?.urlPdf )
  const youtubePreviewTrue = data?.uploadMethod === RESOURCES_UPLOAD_METHODS.youtube && data?.sources?.[0]?.url
  const recordingPreviewTrue = data?.uploadMethod === RESOURCES_UPLOAD_METHODS.recording && data?.sources?.[0]?.url
  const isCropped = data?.sourceContent?.cropped

  return (
    <div id="wrapper">
      <div id="main" >
        <div className="inner" >

          { isCropped && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'var(--color-text3)', fontSize: '1.1rem', padding: '2rem', gap: '1rem', textAlign: 'center' }}>
              <div style={{ fontSize: '1rem', color: 'var(--color-text3)' }}
                >FYI: We cropped this file at 25,000 words
              </div>
              <div style={{ fontSize: '0.9rem', color: 'var(--color-text4)', lineHeight: '1.3rem', width: isMobileAndBelow ? '100%' : '80%', maxWidth: '700px' }}
                >All outputs (summary, notes, etc.) will be based on the first 25,000 words. If you'd like to include the later material, we recommend splitting the file and uploading the latter part separately.
              </div>
            </div>
          )}

          <TextPreview isActive={textPreviewTrue} data={data} />
          <PdfPreview isActive={pdfPreviewTrue} data={data} />
          <YoutubePreview isActive={youtubePreviewTrue} data={data} />
          <RecordingPreview isActive={recordingPreviewTrue} data={data} />

          { !textPreviewTrue && !pdfPreviewTrue && !youtubePreviewTrue && !recordingPreviewTrue && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'var(--color-text3)', fontSize: '1.1rem', padding: '2rem' }}>
              No file preview available
            </div>
          )}

      </div>
      </div>
    </div>
  );
};

export default ResourceSourceScreen;