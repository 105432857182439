import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './App.css';
// import './screens/LandingScreen.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
const firebaseConfig = {
  apiKey: "AIzaSyDb5amfXNLLk5F0Jj9N-aONjChX3ISKfHo",
  authDomain: "academicweapon-add38.firebaseapp.com",
  projectId: "academicweapon-add38",
  storageBucket: "academicweapon-add38.appspot.com",
  messagingSenderId: "347924000168",
  appId: "1:347924000168:web:4964fc7971b56f087b019d",
  measurementId: "G-54F08M4G3Z"
};

const app = initializeApp(firebaseConfig);

mixpanel.init("4fc38a7858f18bfb6a5f3e4d053a851c", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

Sentry.init({
  dsn: "https://526a84e507c44f40ae7b313949de7fd9@o4508778878926848.ingest.us.sentry.io/4508778881417216",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [    
    "localhost", 
    "schoolgoat.com",
    "api.schoolgoat.com",
    /^https:\/\/schoolgoat\.com/,
    /^https:\/\/api\.schoolgoat\.com/
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development', // Only enable in production
  environment: process.env.NODE_ENV,
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);



reportWebVitals();
