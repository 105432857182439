import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('2129331900858887', { autoConfig: true, debug: false });

export const Pixel = {
  pageView: () => {
      ReactPixel.pageView();
  },
  
  track: (event, data) => {
      ReactPixel.track(event, data);
  },
  
  trackCustom: (event, data) => {
      ReactPixel.trackCustom(event, data);
  }
};
