import React, { useEffect, useRef, useState, useContext } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Resource_Quiz from '../components/Resource_Quiz';
import { Resource_Loader } from '../components/Resource_Loader';
import Subheader from '../components/Subheader';
import Resource_Quiz_Edit from '../components/Resource_Quiz_Edit';
import { getResourceTextUrl } from '../misc/utils';
import { GenerateComponent } from '../components/Resource_Components';
import { UserContext } from '../contexts/UserProvider';

const tabs = { active: "Quiz", edit: "Edit" }

const ResourceQuizScreen = ({ }) => {
  const { user } = useContext(UserContext);
  const { data, getData, } = useOutletContext();
  const [ loading, setLoading ] = useState(false);
  const [ activeTab, setActiveTab ] = useState(tabs.active);

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      await axios.post(`${API}/generateQuiz2`, { resource_id: data._id, content_url });
      await Promise.all([
        axios.put(`${API}/generalUpdateOne`, { matchObj: { _id: user._id }, updateObj: { $inc: { ['usage.resource_quiz']: 1,  } }, dbRef: dbRefs.users }),
        getData()
      ]);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
      alert("Error generating quiz, please try again.")
    }
    finally {
      setLoading(false);
    }
  }

  const handleEdit = async (instructions) => {
    setLoading(true);

    try {
      await axios.post(`${API}/updateQuiz`, { resource_id: data._id, instructions: instructions });
      await getData()
      setActiveTab(tabs.active);
    } 
    catch (error) {
      console.error('Error updating quiz:', error);
      alert("Error updating quiz, please try again.")
    }
    finally {
      setLoading(false);
    }

    
  }

  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner" style={{ maxWidth: '1000px', margin: '0 auto' }}>

          { loading ?
            <Resource_Loader isActive={true} text="Generating quiz" subText="This may take ~15 seconds" />
          
          : !data?.quiz?.content ?
            <GenerateComponent title="Generate Quiz" onClick={generateContent} isActive={true} />
          
          :
            <>
              <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
              
              { activeTab === tabs.active && (
                <Resource_Quiz isActive={!loading} data={data} />
              )}

              { activeTab === tabs.edit && (
                <Resource_Quiz_Edit data={data} handleEdit={handleEdit} />
              )}

            </>
          }

        </div>
      </main>
    </div>
  );  
};


export default ResourceQuizScreen;

