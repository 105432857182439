import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useMemo, useCallback } from 'react';
import { MdArrowForward, MdArrowDownward } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import { UserContext } from '../contexts/UserProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { ChevronDown, ChevronUp, CodeIcon, GraduationCap, ListChecks } from 'lucide-react';
import { IoSparklesSharp } from "react-icons/io5";
import { COLOR_ARRAY, REFERRAL_CODE_USED_KEY, SEARCH_PARAMS, } from '../misc/constants';
import AuthModal from '../modals/AuthModal2';
import Benefit1 from '../assets/benefit-1.jpg';
import Benefit2 from '../assets/benefit-2.jpg';
import Benefit3 from '../assets/benefit-3.jpg';
import Benefit4 from '../assets/benefit-4.jpg';

import SchoolGoat from '../assets/schoolgoat.webp';
import { PenLine, Clock, Calendar, BookOpen, Calculator, CheckSquare, Zap, Star, Mail } from 'lucide-react';
import { Camera, MousePointer, Sparkles, NotebookPen, Trophy, ChartColumnIncreasing, Smile, BatteryCharging, ScanFace, DollarSign, HandCoins, HandHeart, Mic, FileIcon, Youtube, ArrowRight, CheckIcon, CheckCircleIcon, ZapIcon, Rabbit, ChartNoAxesCombined,  } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import ChillGirl from '../assets/chillGirl.png';
import { Pixel } from '../misc/analytics';
import axios from 'axios';
import { API, dbRefs } from '../misc/constants';

const buttonTextStyle = {cursor: "pointer", padding: ".5rem .8rem", borderRadius: "10px", shadow: "var(--shadow)", backgroundColor: "transparent", border: "none", color: "inherit", marginRight: ".2rem"}

const dataBenefits = [
  {
    title: "Better grades",
    text: "With a little help from School GOAT, you can reach your potential and get the best grades possible.",
    component: <ChartColumnIncreasing />,
    image: Benefit4,
    alt: "Girl writing on chalkboard",
  }, 
  {
    title: "Better life",
    text: "School Goat lightens your load. Meaning you can spend more time with friends, family, and your social life.",
    component: <BatteryCharging />,
    image: Benefit3,
    alt: "College graduates throwing their caps up",
  },
  {
    title: "Better mental health",
    text: "School doesn't have to be constant stress. With a personal assistant, you'll get more done with less stress.",
    component: <Smile />,
    image: Benefit1,
    alt: "College students relaxing and smiling",
    },
  {
    title: "Better focus",
    text: "You don't have to do everything yourself. School Goat will help. Which will make it easier for you to focus.",
    component: <ScanFace />,
    image: Benefit2,
    alt: "College guy carefully writing notes",
  },
]

const chunk = (array, size) => {
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
};

const PageDivider = () => {
  return (      
    <hr style={{ width: "100%", backgroundColor: "var(--color-divider)", height: "1px", marginTop: "4.25rem", marginBottom: "4.25rem", padding: 0, display: "flex", border: 0 }} aria-hidden="true" />
  )
}

const Spacer = () => {
  return (
    <div style={{ fontSize: "1rem", lineHeight: "1.5", marginTop: "1rem"}} aria-hidden="true">&nbsp;</div>
  )
}

const SectionHeader = ({ label }) => {
  return (
    <h2 style={{ fontSize: "1rem", lineHeight: "1.5", fontWeight: "800", marginTop: "1rem", textTransform: "uppercase", color: "var(--color-primary)", textAlign: "center" }} 
      >{label}
    </h2>
  )
}

const LandingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isTabletAndAbove, isMobileAndBelow, isPhoneAndBelow } = useBreakpoint();
  const { user, logout} = useContext(UserContext);
  const [ openAuthModal, setOpenAuthModal ] = useState(false); 
  const [ openAbandonedCheckoutModal, setOpenAbandonedCheckoutModal ] = useState(false); 


  useEffect(() => {
    Pixel.pageView();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get(SEARCH_PARAMS.ref);
    const email_id = searchParams.get(SEARCH_PARAMS.email_id);
    
    if (referralCode) {
      localStorage.setItem(REFERRAL_CODE_USED_KEY, referralCode);
    }

    if (email_id) {
      const updateEmailClick = async () => {
        try {
          await axios.put(`${API}/generalUpdateOne`, {
            matchObj: { _id: email_id },
            updateObj: { $set: { clicked: true } },
            dbRef: dbRefs.emails
          });
          navigate(location.pathname, { replace: true });
        } catch (error) {
          console.error('Failed to update email click:', error);
        }
      };
      updateEmailClick();
    }
  }, [location, navigate]);

  const CTAButton = ({ label }) => {
  
    const handleClick = (e) => {
      if (!user) {
        setOpenAuthModal(true);
      } else {
        navigate(Routes.APP);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        handleClick(e);
      }
    };
  
    return (
      <button style={{ display: "inline-flex", alignItems: "center", justifyContent: "center", width: "auto", height: "3.5rem", padding: "0 2.75rem", fontSize: "1rem", fontWeight: "600", borderRadius: "100px", backgroundColor: "var(--color-primary)", color: "var(--color-textButton)", border: "none", cursor: "pointer", transition: "all 0.25s ease-in-out", marginTop: ".5rem", boxShadow: "var(--borderShadow)",  }}
        onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
        onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={user ? "Enter app" : "Try it out (for free)"}
      >
      <span>{user ? "Enter" : "Try it out (free)"}</span>
      { user && <MdArrowForward style={{ fill: "var(--color-textButton)", height: "1em", width: "1em", marginLeft: "0.75rem"}} aria-hidden="true" /> }  
    </button>
    );
  };

  const NavBar = () => {
    const handleLoginClick = () => {
      setOpenAuthModal(true);
    };
  
    const handleSignUpClick = () => {
      setOpenAuthModal(true);
    };
  
    return (
      <nav style={{ padding: '.5rem 1rem', borderBottom: '1px solid var(--color-separatorOpaque)', alignItems: 'center', justifyContent: 'space-between', width: '100%', display: 'flex' }}>

        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <span style={{ color: 'var(--color-text1)', textTransform: "uppercase", fontWeight: "300", letterSpacing: 1, fontSize: "1.1rem" }}
            >School Goat
          </span>
        </div>
  
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "flex-end" }}>
          { !user ?
            <>
              <button style={{ ...buttonTextStyle, fontSize: "1rem", color: 'var(--color-text2)'}}
                onClick={handleLoginClick}
                role="button"
                tabIndex="0"
              >
                Login
              </button>

              <button style={{ backgroundColor: `var(--color-backgroundSecondary)`, color: `var(--color-text2)`, marginLeft: ".2rem", fontSize: "1rem", border: "1px solid var(--color-separatorOpaque)", boxShadow: "var(--borderShadow)", padding: ".75rem 1rem", borderRadius: ".5rem"}}
                onClick={handleSignUpClick}
                >
                Start now
              </button>
            </>
          :
            <span style={{ ...buttonTextStyle, fontSize: "1rem", color: 'var(--color-text2)', padding: ".75rem 1rem", borderRadius: ".5rem", border: "1px solid transparent", }} 
              onClick={logout}
              >
              Logout
            </span>
          }
        </div>
      </nav>
    );
  };

  const HeroSection = ({  }) => {

    return (
      <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", gap: "1.5rem"}}>
        <img style={{ width: isTabletAndAbove ? "7rem" : "7rem", objectFit: "contain", marginTop: "1.5rem" }} 
          src={SchoolGoat} 
          alt="School GOAT logo" 
        />

        <h1 style={{ fontSize: !isMobileAndBelow ? "3.8rem" : "2.6rem", fontWeight: "600", color: "var(--color-text1)", lineHeight: "1.3", marginTop: "-1rem", maxWidth: "650px", textAlign: "center", letterSpacing: "-2px", color: 'var(--color-text1)',  }}
          >Overcome{isMobileAndBelow ? <br/> : ""} Hearing Loss
        </h1>

        <h1 style={{ fontSize: !isMobileAndBelow ? "2.8rem" : "1.9rem", fontWeight: "500", color: "var(--color-text1)", lineHeight: "1.1", marginTop: "-1rem", maxWidth: "650px", textAlign: "center", letterSpacing: "-2px" }}
          // >The website that<br/>maxes your grades
          >+ get the best grades of your life
        </h1>

        <p style={{ fontSize: "1.4rem", lineHeight: "1.6", textAlign: "center", maxWidth: "600px", color: "var(--color-text3)", fontWeight: "400",
          ...(isMobileAndBelow && { fontSize: "1.2rem", lineHeight: "1.5" })
         }}
          // >With your own school assistant,<br/> you can reach your academic potential.
          // >The one tool you need to 10x your academic performance
          // >Get the best grades of your life
          >The only tool you need to reach your academic potential
        </p>

        <CTAButton />
        <FeatureGrid />
      </section>
    )
  };

  const FeatureGrid = () => {
    const data = [
      { icon: NotebookPen, text: "Take notes in class", textPhone: "Take notes in class" },
      { icon: CheckSquare, text: "Help with homework", textPhone: "Help with homework" },
      { icon: BookOpen, text: "Shortcut readings", textPhone: "Shortcut readings" },
      { icon: Trophy, text: "Exam prep", textPhone: "Exam prep materials" },
      { icon: Zap, text: "Practice tests", textPhone: "Practice tests" },
      { icon: Sparkles, text: "Study guides", textPhone: "Study guides" },
      { icon: PenLine, text: "Essay guidance", textPhone: "Essay guidance" },
      { icon: GraduationCap, text: "Personal tutor", textPhone: "Personal tutor" },
    ];

    const Tile = ({ item, index }) => {
      if ( isTabletAndAbove ) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.79rem', backgroundColor: 'var(--color-background)', padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', transition: 'transform 0.2s ease, box-shadow 0.2s ease', boxShadow: "var(--borderShadow)", flexBasis: "300px" }}>
            <item.icon size={"1rem"} style={{color: 'var(--color-text2)', flexShrink: 0}} />
            <span style={{color: 'var(--color-text2)', fontSize: '1rem', fontWeight: '500', whiteSpace: 'nowrap'}}>
              {item.text}
            </span>
          </div>
        )
      }
      else {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem', backgroundColor: 'var(--color-background)', padding: '.8rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', transition: 'transform 0.2s ease, box-shadow 0.2s ease', width: '100%', boxShadow: "var(--borderShadow)" }}>
            {/* <item.icon size={"1rem"} style={{color: 'var(--color-primary)', flexShrink: 0}} /> */}
            <span style={{color: 'var(--color-text2)', fontSize: '1rem', fontWeight: '500', textAlign: 'center'}}>
              {item.textPhone || item.text}
            </span>
          </div>
        )
      }
    }
  
    return (
      <div style={{maxWidth: '700px', margin: '0 auto', marginTop: !isMobileAndBelow ? "1.5rem" : "1rem", width: "100%"}}>
        <h5 style={{textAlign: 'center', fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '1.5rem', fontWeight: '400',}}
          >What School Goat can do for you
        </h5>

        { isTabletAndAbove &&
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center'}}>
            {data.map((feature, index) => (
            <Tile key={index} item={feature} index={index} />
          ))}
        </div>
        }

        { !isTabletAndAbove &&
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '.5rem', justifyContent: 'center', width: "100%", }}>
            {data.map((feature, index) => (
              <Tile key={index} item={feature} index={index} />
            ))}
          </div>
        }

      </div>
    );
  };

  const HowItWorksSection = () => {
    const data = useMemo(() => [
      {
        title: `**Takes notes in class** for you, so you can just listen!`,
        titleShort: "Automatic note-taking",
        description: "School Goat will listen to your lecture and take notes for you, so you can focus and learn.",
        component: <NotebookPen />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_InClass.mp4",
        alt: "Image of a person taking notes in class",
      },
      {
        title: `**Solves your homework** problems and teaches you how`,
        titleShort: "Homework solutions",
        description: "Get instant solutions to your assignments and step by step explanations of how to solve them.",
        component: <ListChecks />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ReadingPDF.mp4",
        alt: "Image of a dense textbook page",
      },
      {
        title: `**Prepares you for exams** with class-specific practice tests`,
        titleShort: "Exam prep",
        description: "Get all the practice and guidance you need so you can walk into exams with confidence.",
        component: <ChartNoAxesCombined />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Auto-creates flashcards** so you can study efficiently`,
        titleShort: "Instant study materials",
        description: "Get flashcards, practice tests, and study guides created for you so you can study more efficiently.",
        component: <Zap />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Improves your essays** with estimated grades + feedback`,
        titleShort: "Essay help",
        description: "School GOAT will help you write your essays and give you grade estimates prior to turning them in.",
        component: <PenLine />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
      {
        title: `**Personal tutor** for your classes`,
        titleShort: "Personal tutor",
        description: "Get any subject broken down and explained to you step by step, so you master the material.",
        component: <GraduationCap />,
        videoUrl: "https://academicweapon.s3.amazonaws.com/admin/Demo_ArticleText.mp4",
        alt: "Image of Google search results",
      },
    ], []);

    return (
      <section aria-label={"How it works"}>
        <PageDivider />
        <SectionHeader label={"How it works"} />
        
        { isPhoneAndBelow ?
          <h3 className="headerText" style={{ marginBottom: "2rem"}}
            >Get help with<br/>anything you need
          </h3>
        :
          <h3 className="headerText" style={{ marginBottom: "3rem"}}
            >Help with anything you need
          </h3>
        }

        { isPhoneAndBelow ? (
          <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
            {data.map((item, index) => (
              <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, backgroundColor: "var(--color-backgroundSecondary)", padding: "1.5rem 1rem", borderTop: index !== 0 ? "1px solid var(--color-separatorOpaque)" : "none", position: "relative"}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: "1.5rem" }}>
                  {React.cloneElement(item.component, { style: { width: "1.75rem", height: "1.75rem", color: "var(--color-text1)", strokeWidth: 1.25 }})}
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: 1, }}>
                  <h3 style={{fontSize: '1.3rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}>
                    {item.titleShort}
                  </h3>
                  <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.5rem'}}>
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : 
          <div style={{display: 'flex', flexWrap: 'wrap', gap: '2rem', justifyContent: 'center', }}>
            { data.map((item, index) => (
              <div key={index} style={{ flexBasis: '325px', minWidth: '200px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.75rem', borderRadius: '.75rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', height: "350px", position: "relative"}}>
                <h3 style={{fontSize: '1.5rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}>
                  {item.titleShort}
                </h3>
                <p style={{fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.4', marginTop: '0.5rem'}}>
                  {item.description}
                </p>
      
                <div style={{ position: "absolute", bottom: "0", left: "0", right: "0", height: "225px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  {React.cloneElement(item.component, { color: 'black', size: "5rem", strokeWidth: .95 })}
                </div>
              </div>
            ))}
          </div>
        }
      </section>
    );
  };

  const BenefitsSection = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return (
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '24px' }}>
        <PageDivider />
        <SectionHeader label={"Benefits"} />
        { isPhoneAndBelow ?
          <h3 className="headerText" style={{ marginBottom: '2rem' }}>
            For maximum<br/>academic results
          </h3>
        :
          <h3 className="headerText" style={{ marginBottom: '3rem' }}>
            For maximum academic results
          </h3>
        }

        <div style={{ display: 'flex', flexDirection: 'row', gap: '32px',
        ...(windowWidth < 1024 && { flexDirection: 'column', alignItems: 'center' })

         }}>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', justifyContent: 'center', alignItems: 'center' }}>
            {dataBenefits.map((service, index) => (
              <div key={index} style={{ padding: '1rem', borderRadius: '.5rem', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', maxWidth: '600px' }}>
                <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                  <div style={{ padding: '12px', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {React.cloneElement(service.component, { color: 'var(--color-text1)' })}
                  </div>
                  <div>
                    <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '8px' }}>{service.title}</h3>
                    <p style={{ color: '#666', lineHeight: '1.5' }}>{service.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>


          <img src={ChillGirl} alt="Stylized illustration" style={{ width: "50%", height: 'auto' }} />
        </div>
      </div>
    );
  };

  const TileBenefits_Phone = ({ item, index, isActive }) => (
    <article style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flex: '0 0 85%', maxWidth: '90%', scrollSnapAlign: 'center', opacity: isActive ? 1 : 0.5, transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out", marginRight: "20px" }}
      key={index}
      aria-hidden={!isActive}
    >
      <img
        style={{ width: "100%", aspectRatio: ".9", objectFit: "cover", borderRadius: "15px", boxShadow: `var(--shadow)` }}
        src={item.image}
        alt={item.alt}
      />

      <div style={{ display: "flex", flexDirection: "column", padding: "0 .5rem", opacity: isActive ? 1 : 0, transition: "opacity 0.5s ease-in-out" }}>
        <h4 style={{ fontSize: "1.3rem", lineHeight: "1.4", whiteSpace: "pre-wrap", maxWidth: "100%", marginTop: "1rem", fontWeight: "600", textAlign: "left" }}>
          {item.title}
        </h4>
        <p style={{ marginTop: ".2rem", color: "var(--color-text3)", lineHeight: "1.4", fontSize: "1.1rem", textAlign: "left" }}>
          {item.text}
        </p>
      </div>

    </article>
  )

  const BenefitsSectionPhone = ({  }) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const activeIndexRef = useRef(0);
    const scrollContainerRef = useRef(null);
    const updateActiveIndex = () => {
      if (scrollContainerRef.current) {
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const itemWidth = scrollContainerRef.current.offsetWidth;
        const newActiveIndex = Math.round(scrollLeft / itemWidth);
        activeIndexRef.current = newActiveIndex;
        setActiveIndex(newActiveIndex);
      }
    };

    useEffect(() => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', updateActiveIndex);
        return () => scrollContainer.removeEventListener('scroll', updateActiveIndex);
      }
    }, []);

    return (
      <section aria-label={"Benefits"}>
        <PageDivider />
        <SectionHeader label={"Benefits"} />
        <h3 className="headerText">What this can do for you</h3>
        <Spacer />
        <div className="horizontalScrollContainer" style={{ display: 'flex', alignItems: 'flex-start', overflowX: 'auto', width: '100%', paddingBottom: '20px', scrollSnapType: 'x mandatory' }}
          ref={scrollContainerRef}
          aria-label="Scrollable benefits list"
          role="region"
          tabIndex="0"
        >
          {dataBenefits.map((item, index) => (
            <TileBenefits_Phone key={index} item={item} index={index} isActive={activeIndexRef.current === index} role="listitem" />
          ))}
        </div>
      </section>
    );
  };


  const TileFaq = ({ item, index, isOpen, toggleItem }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
      if (isOpen) {
        const contentHeight = contentRef.current.scrollHeight;
        setHeight(contentHeight);
      } else {
        setHeight(0);
      }
    }, [isOpen]);

    const renderTextWithUnderline = (text) => {
      const lines = text.split('\n');
      return lines.map((line, lineIndex) => {
        const parts = line.split('__');
        const renderedParts = parts.map((part, partIndex) => 
          partIndex % 2 === 0 ? part : <span key={`${lineIndex}-${partIndex}`} style={{ textDecoration: "underline" }}>{part}</span>
        );
        return (
          <React.Fragment key={lineIndex}>
            {lineIndex > 0 && <br />}
            {renderedParts}
          </React.Fragment>
        );
      });
    };

    return (
      <div role="listitem" style={{ width: "100%" }}>
        <h3>
          <button style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", textAlign: "left", padding: "1.5rem 1rem", cursor: "pointer", backgroundColor: "transparent", border: "none", borderTop: index !== 0 ? '1px solid var(--color-separatorOpaque)' : 'none' }}
            aria-controls={`content-${index}`}
            onClick={() => toggleItem(index)}
            
          >
            <span style={{ fontWeight: "600", color: 'var(--color-text2)', fontSize: "1.1rem", textAlign: "left" }}>
              {item.title}
            </span>
            <span aria-hidden="true" style={{ marginLeft: '10px' }}>
              {isOpen ? <ChevronUp size={20} color={'var(--color-primary)'} /> : <ChevronDown size={20} color={'var(--color-primary)'}/>}
            </span>
          </button>
        </h3>
        <div
          id={`content-${index}`}
          role="region"
          aria-labelledby={`heading-${index}`}
          style={{ overflow: "hidden", transition: 'height 300ms ease-in-out, opacity 300ms ease-in-out', height: `${height}px`, opacity: isOpen ? 1 : 0, padding: "0 1rem" }}
          ref={contentRef}
        >
          <div style={{ paddingBottom: '20px' }}>
            <p style={{ color: 'var(--color-text3)', lineHeight: "1.4", fontSize: "1rem", textAlign: "left" }}>
              {renderTextWithUnderline(item.description)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const FaqSection = ({  }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
      setOpenIndex(prevIndex => prevIndex === index ? null : index);
    };

    const data = [
      {
        category: "Output",
        title: "What is School GOAT?",
        description: "School GOAT is a personal assistant specifically for students. It's designed to help you with all aspects of your school work and make your life easier.",
      },
      { 
        title: "What does it do?",
        description: "__A lot.__ It will take notes for you - both in class and from materials. It will also: solve problems for you, prepare you for exams, and improve your essays.",
      },
      {
        title: "What level classes can it handle?",
        description: "__School GOAT can handle any level of class__. We have members in high school all the way up to the Ivy League and PhD programs.",
      },
      { 
        title: "Can I try it for free?",
        description: "__Yes!__ Just sign in and you'll be able to try it out",
      },
      { 
        title: "Can I get paid for referrals?",
        description: "__Yes!__ When you sign in you'll be given your own referral link. You'll get paid each time someone subscribes via your link!",
      },
    ]

    return (
      <section aria-label={"FAQs"} style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
        <PageDivider />
        <SectionHeader label={"FAQs"} />

        <h3 className="headerText" style={{ marginBottom: isPhoneAndBelow ? '2rem' : '3rem' }}>
          Common questions
        </h3>
        <div style={{ maxWidth: "700px", paddingTop: '.5rem', paddingBottom: '.5rem', display: "flex", flexDirection: "column", justifyContent: "center", alignSelf: "center"}}
          role="list"
        >
          {data.map((item, index) => (
            <TileFaq 
              key={index} 
              item={item} 
              index={index} 
              isOpen={openIndex === index} 
              toggleItem={toggleItem}
            />
          ))}
        </div>
      </section>
    );
  }

  const CTASection = () => {
    return (
      <section aria-label={"Start now"}>
        <PageDivider />
        <SectionHeader label={"Start now"} />

        
        <div style={{ display: "flex", flexDirection: "column", gap: '1.5rem', alignItems: "center"}}
          aria-labelledby="cta-heading"
        >
          <h3 id="cta-heading" className="headerText"
            >Interested?<br />It's easy to start
          </h3>
          <p style={{ fontSize: !isMobileAndBelow ? "1.4rem" : "1.2rem", lineHeight: "1.6", textAlign: "center", maxWidth: "600px", color: "var(--color-text3)" }}
            >Just click below to get started.<br/><span style={{ textDecoration: "underline" }}>And remember, it's free to start.</span>
          </p>
          <Spacer />
          <CTAButton label="Get started now" />
          <Spacer />
          <Spacer />
          <Spacer />
          <footer style={{ marginTop: '2rem', textAlign: 'center' }}>
            <p style={{ fontSize: '0.9rem' }}>© DealStream LLC. All rights reserved.</p>
            <div style={{ display: 'flex', gap: '.3rem', justifyContent: 'center', marginTop: '0.5rem', fontSize: '0.8rem', color: 'var(--color-text3)' }}>
              <a href={Routes.TERMS} style={{ textDecoration: 'none', color: 'inherit' }}>Terms of Service</a>
              <span>•</span>
              <a href={Routes.PRIVACY} style={{ textDecoration: 'none', color: 'inherit' }}>Privacy</a>
              <span>•</span>
              <a href={Routes.REFUND} style={{ textDecoration: 'none', color: 'inherit' }}>Refund</a>
            </div>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <div id="wrapper" >

      <Helmet>
        <title>Overcome Hearing Loss with School GOAT</title>
        <meta name="description" content="School GOAT can help you overcome hearing loss and reach your academic potential. It's designed to help you with all aspects of your school work, from taking notes in class to preparing for exams." />
        <link rel="canonical" href="https://schoolgoat.com/" />
      </Helmet>

      <NavBar />
      
      <div id="main" style={{ alignItems: "center", justifyContent: "center", backgroundColor: "var(--color-backgroundSecondary)", paddingTop: 0}}>
        <div className="inner" style={{   }}>

          <HeroSection />
          <HowItWorksSection />

          {isPhoneAndBelow ? <BenefitsSectionPhone /> : <BenefitsSection />}
          <FaqSection />
          <CTASection />
          
        </div>
      </div>

      <AuthModal open={openAuthModal} setOpen={setOpenAuthModal} />
    </div>
  );
};

export default LandingScreen;



