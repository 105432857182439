import React, { useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { dbRefs } from '../misc/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API } from '../misc/constants';
import SchoolGoat from '../assets/schoolgoat.webp';
import AuthModal from '../modals/AuthModal2';

const subscriptionClaimStatuses = {
  unclaimed: 'unclaimed',
  claimed: 'claimed',
  not_found: 'not_found',
  error: 'error',
}

const tabOptions = {
  [subscriptionClaimStatuses.unclaimed]: {
    title: 'Subscription Available',
    description: 'This subscription is ready to be claimed. To claim it, please first create a School Goat account.',
  },
  [subscriptionClaimStatuses.claimed]: {
    title: 'Subscription Unavailable',
    description: 'This subscription has already been claimed. If you believe this is an error, please contact Ryan at ryan@schoolgoat.com',
  },
  [subscriptionClaimStatuses.not_found]: {
    title: 'Subscription Not Found',
    description: 'The subscription you are trying to claim does not exist. If you believe this is an error, please contact Ryan at ryan@schoolgoat.com',
  },
  [subscriptionClaimStatuses.error]: {
    title: 'Error',
    description: 'An error occurred while claiming the subscription. Please try refreshing. If this issue persists, please contact Ryan at ryan@schoolgoat.com',
  },
}

const ClaimButton = ({ status, setOpenAuthModal, data }) => {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleStartSubscription = async () => {

    try {
      let result = await axios.put(`${API}/generalFindOneAndUpdate`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $set: { stripe: data.stripe, stripeSubscription: data.stripeSubscription, }},
        dbRef: dbRefs.users 
      })

      let results = result.data;

      if ( results?._id ) {
        await axios.put(`${API}/generalUpdateOne`, {
          matchObj: { _id: data._id },
          updateObj: { $set: { status: subscriptionClaimStatuses.claimed }},
          dbRef: dbRefs.subscription_claims
        });

        setUser({ ...user, ...results, isMember: true });
        navigate('/app');
      }
    } catch (error) {
      console.error("Error claiming subscription:", error);
      alert("Error claiming subscription. Please try again. If this issue persists, please contact Ryan at ryan@schoolgoat.com");
    }


  }

  if (status == subscriptionClaimStatuses.unclaimed) {
    if (!user) {
      return (
        <button style={{ marginTop: '1rem', padding: '0.75rem 1.5rem', backgroundColor: 'var(--color-primary)', color: 'white', border: 'none', borderRadius: '8px', fontSize: '1.1rem', fontWeight: 500, cursor: 'pointer' }}
          onClick={() => setOpenAuthModal(true)}
          >Create Account
        </button>
      )
    } else {
      return (
        <button style={{ marginTop: '1rem', padding: '0.75rem 1.5rem', backgroundColor: 'var(--color-primary)', color: 'white', border: 'none', borderRadius: '8px', fontSize: '1.1rem', fontWeight: 500, cursor: 'pointer' }}
          onClick={handleStartSubscription}
          >Go to School Goat  🎉
        </button>
      )
    }
  }
  else return null;
}




const SubscriptionClaimScreen = () => {
  const { user } = useContext(UserContext);
  const { subscription_claim_id } = useParams();
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  
  useEffect(() => {
    getData();
  }, [subscription_claim_id]);

  const getData = async () => {
    try {
      let result = await axios.get(API + '/generalFindOne', { params: { 
        queryObj: JSON.stringify({ _id: subscription_claim_id }), 
        dbRef: dbRefs.subscription_claims } 
      });
      let results = result?.data
      setData(results);

      if ( subscription_claim_id ) {
        if (results?.status == subscriptionClaimStatuses.unclaimed) {
          setStatus(subscriptionClaimStatuses.unclaimed);
        }
        else if (results?.status == subscriptionClaimStatuses.claimed) {
          setStatus(subscriptionClaimStatuses.claimed);
        }
        else {
          setStatus(subscriptionClaimStatuses.not_found);
        }
      }
      else {
        setStatus(subscriptionClaimStatuses.not_found);
      }
    } 
    catch (error) {
      console.error('Error fetching text content:', error);
      setStatus(subscriptionClaimStatuses.error);
    }
  };

  return (
    <div id='wrapper' style={{ backgroundColor: 'var(--color-backgroundSecondary)', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div id='main'>
        <div style={{maxWidth: '600px', margin: 'auto', padding: '2rem', textAlign: 'center'}}>
            {status && (
              <div style={{backgroundColor: '#fff', padding: '2rem', borderRadius: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem'}}>

                <img src={SchoolGoat} alt='School Goat Logo' style={{width: '100px', height: '100px'}} />

                <h2 style={{fontSize: '1.8rem', fontWeight: 600, color: '#111', marginBottom: '1rem'}}>
                  {tabOptions?.[status]?.title}
                </h2>
                <p style={{color: '#555', lineHeight: 1.6, fontSize: '1.1rem', }}>
                  {tabOptions?.[status]?.description}
                </p>

                <ClaimButton status={status} setOpenAuthModal={setOpenAuthModal} data={data}/>
               
              </div>
            )}
        </div>
      </div>

      <AuthModal open={openAuthModal} setOpen={setOpenAuthModal} />

    </div>
  );
};

export default SubscriptionClaimScreen;