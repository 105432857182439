import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { API, REFERRAL_CODE_USED_KEY, dbRefs, stripeSubscriptionStates, SLACK_WEBHOOK_CHANNELS, PAYWALL_TYPES, MIXPANEL_EVENTS, RESOURCES_UPLOAD_METHODS, stripeSubscriptionStatesAlerts, } from '../misc/constants';
import axios from 'axios';
import { generateReferralCode, sendSlackNotification, validateEmail } from '../misc/utils';
import mixpanel from 'mixpanel-browser';
import { initializePaddle,  } from '@paddle/paddle-js';
import { Pixel } from '../misc/analytics';


export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [ userLoaded, setUserLoaded ] = useState(false)
  const [ showPaywallType, setShowPaywallType ] = useState(false)
  const [ showCheckoutModal, setShowCheckoutModal ] = useState(false)
  const [ showChatModal, setShowChatModal ] = useState(false)
  const auth = getAuth();
  const authChangeInProgress = useRef(false);
  const [ refreshRecentResources, setRefreshRecentResources ] = useState(false)
  const [ paddle, setPaddle ] = useState(null);

  const isActiveSubscription = (userData) => userData?.stripeSubscription?.status ? (userData?.stripeSubscription?.status === stripeSubscriptionStates.active || userData?.stripeSubscription?.status === stripeSubscriptionStates.trialing) : false;


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        await handleAuthStateChange(firebaseUser);
      } 
      catch (err) {
        console.error(err);
      } 
      finally {
        setUserLoaded(true);
      }
    });
    
    return () => unsubscribe();
  }, [auth]);

  //////////////////// HANDLE AUTH STATE CHANGE ////////////////////

  const handleAuthStateChange = async (firebaseUser) => {
    if (authChangeInProgress.current) return;
    authChangeInProgress.current = true;
    
    try {
      if (!firebaseUser) {
        setUser(null);
        return;
      }

      const localStorage_id = localStorage.getItem('_id');
      const isUniqueAccount = await checkUserForUniqueAccount(firebaseUser, localStorage_id);
      await getUserData(firebaseUser, localStorage_id, isUniqueAccount);

      // if (!isUniqueAccount) {
      //   setShowPaywallType(PAYWALL_TYPES.DUPLICATE_ACCOUNT);
      // }
    } 
    catch (err) {
      console.error(err);
      alert("An error occurred while fetching user data. Try refreshing the page and logging in again.")
      setUser(null);
    } 
    finally {
      authChangeInProgress.current = false;
    }
  };


  const checkUserForUniqueAccount = async (firebaseUser, localStorage_id) => {
    try {

      if ( !localStorage_id ) { return true; }
      else if ( localStorage_id === firebaseUser.uid ) { return true; }
      else if ( localStorage_id !== firebaseUser.uid ) {

        let result = await axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ _id: { $in: [firebaseUser.uid, localStorage_id] }, }),
          dbRef: dbRefs.users
        }})
        
        let results = result.data
        let hasActiveMembership = results.some((k) => isActiveSubscription(k))

        if ( !hasActiveMembership ) {
          await axios.put(`${API}/generalUpdateOne`, {
            matchObj: { email: firebaseUser.email },
            updateObj: { $set: { ['stripeSubscription.status']: stripeSubscriptionStates.duplicate_account, }},
            dbRef: dbRefs.users,
          })
          localStorage.removeItem('_id');
          return false;
        }
      }

      return true;
    }
    catch (err) {
      console.error(err);
      return true;
    }
  }

  
  //////////////////// GET USER DATA ////////////////////


  const getUserData = async (firebaseUser, localStorage_id, isUniqueAccount = true ) => {
    
    if ( !localStorage_id ) { localStorage.setItem('_id', firebaseUser.uid); }

    try {
      let result = await axios.put(`${API}/generalFindOneAndUpdate`, { 
        matchObj: { _id: firebaseUser.uid }, 
        updateObj: { $inc: { authCount: 1 }, $set: { authLastDate: Date.now() } },
        dbRef: dbRefs.users 
      })
      let results = result.data

      if ( results?._id ) {
        const userObj = { ...results, isMember: isActiveSubscription(results) };
        setUser(userObj);
        mixpanelIdentifyUser(userObj, MIXPANEL_EVENTS.user_signed_in)
        await cleanUserData(userObj, isUniqueAccount);
      }
      else {
        await createUser(firebaseUser, isUniqueAccount)
        console.log("new user: ", firebaseUser.uid)
      }
      
    }
    catch (err) {
      console.error(err);
      throw new Error("Failed to fetch user data"); // Propagate to handleAuthStateChange
    }
  }

  const createUser = async (firebaseUser, isUniqueAccount = true) => {
    const referralCode = generateReferralCode();
    const referralCodeUsed = localStorage.getItem(REFERRAL_CODE_USED_KEY) || '';
    const statusEffective = isUniqueAccount ? stripeSubscriptionStates.free_trial : stripeSubscriptionStates.duplicate_account;

    try {

      let authData = {};
      try {
        const authResponse = await fetch('https://ipapi.co/json/');
        authData = await authResponse.json();
      }
      catch (err) {
        console.error('Error fetching IP data:', err);
      }

      let userDoc = {
        _id: firebaseUser.uid,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        createdAt: firebaseUser.metadata.createdAt,
        categories: [],
        stripeSubscription: {
          status: statusEffective,
          plan: { }
        },
        referralCode: referralCode,
        referralCodeUsed: referralCodeUsed,
        emailSentCount: 0,
        termsAccepted: Date.now(),
        authCount: 1,
        authLastDate: Date.now(),
        authData: authData,
        usage: {},
      }

      await axios.post(`${API}/generalInsertOne`, { doc: userDoc, dbRef: dbRefs.users });
      
      // await axios.put(`${API}/generalUpdateOne`, {
      //   matchObj: { _id: userDoc._id },
      //   updateObj: { $set: userDoc },
      //   extraObj: { upsert: true },
      //   dbRef: dbRefs.users,
      // });

      Promise.allSettled([
        axios.post(`${API}/email/welcome`, { userEmail: userDoc?.email }),
        sendSlackNotification(`New user created\nEmail: ${userDoc?.email}\nUnique: ${isUniqueAccount}`, SLACK_WEBHOOK_CHANNELS.signups),
      ]).catch(err => console.error('Non-critical error:', err));

      setUser({ ...userDoc, isFirstTimeUser: true, isMember: false });
      mixpanelIdentifyUser(userDoc, MIXPANEL_EVENTS.user_created)
      Pixel.track('CompleteRegistration');
      localStorage.removeItem(REFERRAL_CODE_USED_KEY);
    }
    catch (err) {
      console.error(err);
      throw new Error("Failed to create user"); // Propagate to handleAuthStateChange
    }
  }

  const cleanUserData = async (userObj, ) => {
    if ( !userObj ) { return; }

    if ( !userObj.authData ) {

      try {
        const authResponse = await fetch('https://ipapi.co/json/');
        const authData = await authResponse.json();        
        await axios.put(`${API}/generalUpdateOne`, {
          matchObj: { _id: userObj._id },
          updateObj: { $set: { authData: authData } },
          dbRef: dbRefs.users,
        })
      }
      catch (err) {
        console.error('Error fetching IP data:', err);
      }
    }
  }

  const mixpanelIdentifyUser = useCallback((userObj, event = MIXPANEL_EVENTS.user_signed_in) => {
    mixpanel.identify(userObj._id);
    mixpanel.people.set({ $email: userObj?.email, $created: userObj?.createdAt, 'Subscription Status': userObj?.stripeSubscription?.status });
    mixpanel.track(event, { email: userObj?.email, createdAt: userObj?.createdAt });
  }, []);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setUser(null);
      mixpanel.reset()
    } 
    catch (error) {
      console.error(error);
    }
  }, [auth]);


  const checkUserPermission = async (creditType = '', popupsEnabled = true) => {

    try {
      let hasPermission = false; 
      let alertMessage = null; 
      let paywallType = null;
      let responseData = {}

      if ( !user ) { 
        hasPermission = false;
      }
    
      const userStatus = user?.stripeSubscription?.status;

      if ( user?.isMember || userStatus === stripeSubscriptionStates.active || userStatus === stripeSubscriptionStates.trialing ) {
        hasPermission = true;
      }

      else if ( userStatus === stripeSubscriptionStates.free_trial ) {
        let result = await axios.get(`${API}/paywall/v1/creditsCheck`, { params: { user_id: user?._id, creditType: creditType }})

        if (result?.data) {
          responseData = result.data; 
          hasPermission = result.data.hasCredits ?? false;
          
          if (!hasPermission) {
            paywallType = true;
          }
        }
      }

      else if ( userStatus === stripeSubscriptionStates.duplicate_account ) {
        paywallType = PAYWALL_TYPES.DUPLICATE_ACCOUNT;
      }

      else if ( stripeSubscriptionStatesAlerts?.[userStatus] ) {
        alertMessage = stripeSubscriptionStatesAlerts?.[userStatus]
      }

      else {
        alertMessage = `No access. Your account status is: ${userStatus}. Check Billing (Settings) if available. Otherwise, upgrade to regain access (Upgrade) or email ryan@schoolgoat.com for help.`
      }

      if ( popupsEnabled ) {
        if ( paywallType ) {
          setShowPaywallType(paywallType);
        }
        else if ( alertMessage ) {
          alert(alertMessage)
        }
      }

      return { hasPermission, creditType, ...responseData }
    }
    catch (err) {
      console.error(err);
      alert("Error: please refresh and try again. If this continues, contact us (Settings).")
      return { hasPermission: false, }
    }
  };

  useEffect(() => {
    const initializationObj = { token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN, }
    
    if ( process.env.REACT_APP_NODE_ENV === 'development' ) {
      initializationObj.environment = 'sandbox';
    }
    
    initializePaddle(initializationObj).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  useEffect(() => {
    if ( paddle?.Initialized && user?.stripe?.customer ) {
      paddle.Update({ pwCustomer: { id: user?.stripe?.customer }})
    }
  }, [user?.stripe?.customer, paddle]);

  return (
      <UserContext.Provider value={{ 
          user, setUser,
          userLoaded, setUserLoaded,
          showPaywallType, setShowPaywallType,
          checkUserPermission,
          logout,
          showCheckoutModal, setShowCheckoutModal,
          showChatModal, setShowChatModal,
          refreshRecentResources, setRefreshRecentResources,
          paddle,
          isActiveSubscription,
        }}>
          {children}
      </UserContext.Provider>
  );
};


