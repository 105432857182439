import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS, PAYWALL_TYPES } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Upload } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { Routes } from '../navigation/routes';

const Uploader_Home_File = ({ file, setFile, setOpenResourceProcessingModal, setIsLoading }) => {
  
  const { user, setRefreshRecentResources, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const audioExtensions = ['.mp3', '.wav', '.ogg', '.m4a', '.aac'];
  const acceptedFileTypes = ['.pdf', ...audioExtensions, '.docx', '.doc', '.pptx', '.ppt'];

  useEffect(() => {
    if ( file ) {
      handleFileChange(file)
    }
  }, [file])

  const isValidFileType = (filename) => {
    const extension = '.' + filename.split('.').pop().toLowerCase();
    const isAccepted = acceptedFileTypes.includes(extension);

    if ( !isAccepted ) {
      alert('Invalid file type. Please upload a PDF, Word Doc, PPT, or audio file.');
      return false
    }

    return true
  };

  const isValidFileSize = (file) => {
    const isAccepted = file.size < 100 * 1024 * 1024
    
    if ( !isAccepted ) {
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
      return false
    }

    return true
  }

  const getFileExtension = (file) => {
    let extension = file.name.split('.').pop();
    let fullExtension = `.${extension}`
    return fullExtension
  }

  const handleClose = () => {
    setFile(null);
    setIsLoading(false)
  };

  const handleFileChange = (selectedFile) => {
    if (selectedFile) {

      const isValidSize = isValidFileSize(selectedFile)
      const isValidType = isValidFileType(selectedFile.name)

      if ( !isValidSize || !isValidType ) {
        setFile(null)
      }
      else {
        uploadFile()
      }

    }
  };


  const uploadFile = async () => {

    if (!file) {
      alert('Please select a file first!');
      setIsLoading(false)
      return;
    }

    let resourceObj = null

    try {

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.resources)
      if ( !hasPermission ) {
        return;
      }

      setIsLoading(true)

      resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
      await uploadResourceToS3({ resource_id: resourceObj._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })

      const extension = getFileExtension(file)

      if ( audioExtensions.includes(extension) ) {
        await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user?.categories })
        handleClose();
        setOpenResourceProcessingModal(true);
      }

      else {
        await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories })
        setRefreshRecentResources(prev => !prev)
        navigate(`/app/resources/${resourceObj._id}/${Routes.SOURCE}`) 
        handleClose();
      }
    } 
    catch (error) {
      try {
        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file.name}\nfile type: ${file.type}\nfile size: ${file.size}\nUpload method: ${RESOURCES_UPLOAD_METHODS.file}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }
      alert(`There was an error uploading your file. This typically only happens if the file is too large. Please try breaking the file into smaller parts. If this continues, please email us at ryan@schoolgoat.com and we'll get it sorted out!`);
      setFile(null)
    }
    finally {
      setIsLoading(false)
    }
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

        <Upload size={30} style={{ color: 'var(--color-text3)', marginBottom: '.5rem' }} strokeWidth={1.5}/>

        <h4 style={{fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}
          >Drop here or <span onClick={() => fileInputRef.current.click()} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '600' }}>choose file</span>
        </h4>
        <p style={{ fontSize: '.95rem', color: 'var(--color-text4)', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
          >Supports Word Doc, PPT, PDF, and Audio files
        </p>
      </div>

      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
        ref={fileInputRef}
        style={{ display: 'none' }}
        aria-label="Select file"
        accept=".pdf,.mp3,.wav,.ogg,.m4a,.aac,.docx,.doc,.pptx,.ppt"
      /> 

    </div>
  );
};

export default Uploader_Home_File;










// const validateAndGetAudioDuration = (file) => {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', URL.createObjectURL(file), true);
//     xhr.responseType = 'arraybuffer';
//     xhr.onload = function(e) {
//       if (this.status == 200) {
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         audioContext.decodeAudioData(this.response, 
//           (buffer) => {
//             // setValidationProgress(100);
//             resolve({
//               duration: buffer.duration,
//               isValid: true
//             });
//           },
//           (err) => {
//             reject(new Error('Unable to decode audio data. The file may be corrupted.'));
//           }
//         );
//       }
//     };

//     xhr.onerror = () => {
//       reject(new Error('Error occurred while reading the file.'));
//     };

//     xhr.send();
//   });
// };
