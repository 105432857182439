import './css/App.css';
import './css/Drawer.css';
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect, useState } from 'react';
import { Routes } from './navigation/routes';
import { UserContext, UserProvider } from './contexts/UserProvider';
import ErrorScreen from './screens/ErrorScreen';
import { createBrowserRouter, Navigate, redirect, RouterProvider, useLocation } from "react-router-dom";
import ProtectedRoute from './navigation/ProtectedRoute';
import TermsScreen from './screens/TermsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicy';
import { ThemeProvider } from '@mui/material';
import theme from './contexts/ThemeProvider';
import ReactGA from "react-ga4";
import LandingScreen from './screens/LandingScreen4';
import SettingsScreen from './screens/SettingsScreen';
import TermsPartnerProgramScreen from './screens/TermsPartnerProgramScreen';
import NotesFeedbackScreen from './screens/NotesFeedbackScreen';
import SolveScreen from './screens/SolveScreen2';
import EssayEvaluationScreen from './screens/EssayEvaluationScreen';
import RefundScreen from './screens/RefundScreen';

// import JoinScreen from './screens/JoinScreen3';
import JoinScreen from './screens/JoinScreenPaddle';
// import AIDetectionScreen from './zArchive/AIDetectionScreen';
import HomeScreen from './screens/HomeScreen5';
import HomeRouter from './navigation/HomeRouter3';
import CheckoutCompleteScreen from './zArchive/CheckoutCompleteScreen';
import ResourceRouter from './navigation/ResourceRouter2';
import ResourceFlashcardsScreen from './screens/ResourceFlashcardsScreen';
import ResourceQuizScreen from './screens/ResourceQuizScreen';

import ResourceTeachScreen from './screens/ResourceTeachScreen';
import ResourceNotesScreen from './screens/ResourceNotesScreen';

import TeachScreen from './screens/TeachScreen';
import ResourceSourceScreen from './screens/ResourceSourceScreen';
import ResourceSummaryScreen from './screens/ResourceSummaryScreen2';
import ResourceTestScreen from './screens/ResourceTestScreen';
import ResourcesScreen from './screens/ResourcesScreen';
import ResourceChatScreen from './screens/ResourceChatScreen';
// import EssayScreen from './screens/EssayScreen';
// import ReferralsScreen from './screens/ReferralsScreen';

// import YouTubeHandler from './components/YoutubeHandler';
import LandingParentsScreen from './screens/LandingParentsScreen';
import SubscriptionClaimScreen from './screens/SubscriptionClaimScreen';
import ErrorSentryScreen from './screens/ErrorSentryScreen';
import EmailUnsubscribeScreen from './screens/EmailUnsubscribeScreen';
import DataGodScreen from './screens/DataGodScreen';
import LandingADHDScreen from './screens/LandingADHDScreen';
import LandingHearingScreen from './screens/LandingHearingScreen';
ReactGA.initialize("G-54F08M4G3Z");


const App = () => {

  const router = createBrowserRouter([

    { path: '/', element: <LandingScreen />, errorElement: <ErrorScreen /> },
    { path: `${Routes.PARENTS}`, element: <LandingParentsScreen />, errorElement: <ErrorScreen /> },
    { path: `${Routes.ADHD}`, element: <LandingADHDScreen />, errorElement: <ErrorScreen /> },
    { path: `${Routes.HEARING}`, element: <LandingHearingScreen />, errorElement: <ErrorScreen /> },
    { path: `${Routes.SUBSCRIPTION_CLAIM}/:subscription_claim_id`, element: <SubscriptionClaimScreen />, errorElement: <ErrorScreen /> },
    {
      path: Routes.APP, element: <ProtectedRoute />, errorElement: <ErrorScreen />,
      children: [
        { index: true, element: <Navigate to={Routes.HOME} replace /> },
        { element: <HomeRouter />, children: [          
          { path: Routes.HOME, element: <HomeScreen /> },
          { path: Routes.SOLVE, element: <SolveScreen /> },
          { path: Routes.SETTINGS, element: <SettingsScreen /> },
          { path: Routes.ESSAY_EVALUATION, element: <EssayEvaluationScreen /> },
          { path: Routes.TEACH, element: <TeachScreen /> },
          { path: Routes.JOIN, element: <JoinScreen /> },
          { path: Routes.RESOURCES, element: <ResourcesScreen /> },
          { path: 'resources/:resource_id', element: <ResourceRouter />, children: [          
            { path: Routes.SOURCE, element: <ResourceSourceScreen/> },
            { path: Routes.SUMMARY, element: <ResourceSummaryScreen /> },
            { path: Routes.TEACH, element: <ResourceTeachScreen /> },
            { path: Routes.NOTES, element: <ResourceNotesScreen /> },
            { path: Routes.QUIZ, element: <ResourceQuizScreen /> },
            { path: Routes.FLASHCARDS, element: <ResourceFlashcardsScreen /> },
            { path: Routes.TEST, element: <ResourceTestScreen /> },
            { path: Routes.CHAT, element: <ResourceChatScreen /> },
          ]},
        ]},
        
        { path: Routes.CHECKOUT_COMPLETE, element: <CheckoutCompleteScreen /> },

      ]
    },

    // { path: 'youtube.com/*', element: <YouTubeHandler /> },
    // { path: 'www.youtube.com/*', element: <YouTubeHandler /> },


    { path: Routes.PRIVACY, element: <PrivacyPolicyScreen /> },
    { path: Routes.TERMS, element: <TermsScreen /> },
    { path: Routes.REFUND, element: <RefundScreen /> },
    { path: Routes.TERMS_PARTNER_PROGRAM, element: <TermsPartnerProgramScreen /> },
    { path: Routes.NOTES_FEEDBACK, element: <NotesFeedbackScreen /> },
    { path: Routes.EMAIL_UNSUBSCRIBE, element: <EmailUnsubscribeScreen /> },
    // { path: Routes.DATAGOD, element: <DataGodScreen /> },
    { path: '*', element: <Navigate to="/" replace /> },
  ]);

  return (
    <Sentry.ErrorBoundary 
      fallback={(errorData) => <ErrorSentryScreen error={errorData} />}
    >
      <ThemeProvider theme={theme}>
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>

  );
}

export default App;


{/* <Sentry.ErrorBoundary 
fallback={<div style={{padding: 20}}>Error caught by Sentry boundary!</div>}
>
<div style={{padding: 20}}>
  <h1>Test App</h1>
  <button onClick={() => setShowError(true)}>
    Show Error Component
  </button>
  {showError && <ErrorThrower />}
</div>
</Sentry.ErrorBoundary> */}


// { path: Routes.AI_DETECTION, element: <AIDetectionScreen /> },      
          // { path: Routes.WRITING, element: <WritingScreen /> },
          // { path: Routes.ESSAY, element: <EssayScreen /> },
          // { path: Routes.STUDY, element: <StudyScreen /> },

    // {
    //   path: '/writeassist', 
    //   element: <JennyScreen />,
    //   errorElement: <ErrorScreen />,
    // },
    // {
    //   path: '/maninfinance', 
    //   element: <DataScreen />,
    //   errorElement: <ErrorScreen />,
    // },

            // { path: Routes.REFERRALS, element: <ReferralsScreen /> },
        // { index: true, element: <Navigate to={Routes.HOME} replace /> },
        // { path: Routes.ESSAY_OUTLINE, element: <EssayOutlineScreen /> },
        // { path: Routes.SHORTCUT_READING, element: <ShortcutReadingScreen /> },
        // { path: Routes.HOME, element: <AppHomeScreen />  },
        // { path: Routes.NOTES_IN_CLASS, element: <NotesInClassScreen /> },
        // { path: Routes.UPLOAD_FILES, element: <NotesConvertScreen /> },
        // { path: Routes.FLASHCARDS, element: <FlashcardsScreen /> },
        // { path: Routes.QUIZ, element: <QuizScreen /> },
        // { path: Routes.MEMBERSHIP, element: <MembershipScreen /> },
        // { path: Routes.TEACH, element: <TeachScreen /> },