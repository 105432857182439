export const Routes = {
  LANDING: '/',
  APP: '/app',
  TAKE_NOTES: 'take-notes',

  TERMS: '/terms',
  REFUND: '/refund',
  TERMS_PARTNER_PROGRAM: '/terms-partner-program',
  PRIVACY: '/privacy',
  SETTINGS: 'settings',
  REFERRALS: 'referrals',
  NOTES_FEEDBACK: 'notes-feedback/:file_id',
  
  NOTES_IN_CLASS: 'notes-in-class',
  UPLOAD_FILES: 'upload-files',
  NOTES: 'notes',
  NOTES_FILE: 'notes-file/:file_id',

  SOLVE: 'solve',
  SHORTCUT_READING: 'shortcut-reading',

  STUDY: 'study',
  QUIZ: 'quiz',
  FLASHCARDS: 'flashcards',

  ESSAY: 'essay',
  ESSAY_EVALUATION: 'essay-evaluation',
  ESSAY_OUTLINE: 'essay-outline',

  MEMBERSHIP: 'membership',
  TEACH: 'teach',
  RESOURCES: 'resources',
  RESOURCES_PROFILE: 'resources/:resource_id',

  CHECKOUT: 'checkout',
  JOIN: 'join',
  ONBOARDING: 'onboarding',

  

  // NEW
  HOME: 'home',
  AI_DETECTION: 'ai-detection',
  NOTES: 'notes',
  CHAT: 'chat',
  WRITING: 'writing',
  CHECKOUT_COMPLETE: 'checkout-complete',
  SUMMARY: 'summary',
  TEST: 'test',
  FILE: 'file',
  SOURCE: 'source',


  // LANDING SCREENS
  PARENTS: 'parents',
  ADHD: 'adhd',
  HEARING: 'hearing',


  SUBSCRIPTION_CLAIM: 'subscription-claim',

  EMAIL_UNSUBSCRIBE: 'email-unsubscribe',

  DATAGOD: 'datagod',
};