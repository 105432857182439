import React, { useState, useContext } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import Resource_Flashcards from '../components/Resource_Flashcards';
import { useOutletContext } from 'react-router-dom';
import { Resource_Loader } from '../components/Resource_Loader';
import Subheader from '../components/Subheader';
import Resource_Flashcards_Edit from '../components/Resource_Flashcards_Edit';
import { getResourceTextUrl } from '../misc/utils';
import { GenerateComponent } from '../components/Resource_Components';
import { UserContext } from '../contexts/UserProvider';

const tabs = { active: "Cards", edit: "Edit" }

const ResourceFlashcardsScreen = ({ }) => {
  const { user } = useContext(UserContext);
  const { data, getData, } = useOutletContext();
  const [ loading, setLoading ] = useState(false);
  const [ activeTab, setActiveTab ] = useState(tabs.active);

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      await axios.post(`${API}/generateFlashcards`, { resource_id: data._id, content_url });
      await Promise.all([
        axios.put(`${API}/generalUpdateOne`, { matchObj: { _id: user._id }, updateObj: { $inc: { ['usage.resource_flashcards']: 1,  } }, dbRef: dbRefs.users }),
        getData()
      ]);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
      alert("Error generating flashcards, please try again.")
    }
    finally {
      setLoading(false);
    }
  }

  const handleSave = async (flashcards) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: data._id },
        updateObj: { $set: { 'flashcards.content': flashcards } },
        dbRef: dbRefs.resources,
      })
      await getData();
      setActiveTab(tabs.active);
    }
    catch (error) {
      console.error('Error saving flashcards:', error);
      alert("Error saving flashcards, please try again.")
    }
  }


  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner" style={{ maxWidth: '1000px', margin: '0 auto' }}>


          { loading ?
            <Resource_Loader isActive={true} text="Generating flashcards" subText="This may take ~10 seconds" />

          : !data?.flashcards?.content ?
            <GenerateComponent title="Generate Flashcards" onClick={generateContent} isActive={true} />
          
          :
            <>

              { !data?.previewMode && (
                <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
              )}
              
              { activeTab === tabs.active && (
                <Resource_Flashcards isActive={!loading} data={data} />
              )}

              { activeTab === tabs.edit && (
                <Resource_Flashcards_Edit data={data} handleSave={handleSave} />
              )}

            </>
          }


        </div>
      </main>
    </div>
  );  
};


export default ResourceFlashcardsScreen;

