import React, { useContext, useEffect, useState, } from 'react';
import { API, dbRefs, PAYWALL_TYPES, } from '../misc/constants';
import { UserContext } from '../contexts/UserProvider';
import { Mic, ArrowRight,  } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import AudioRecorder from '../components/AudioRecorder';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import AudioRecorderModal from '../modals/AudioRecorderModal';
import { Routes } from '../navigation/routes';
import { EXAMPLE_NOTES } from '../misc/constants';
import Uploader_HomeComponent from '../components/Uploader_HomeComponent';
import axios from 'axios';


const Subheader = ({ title, usageString, styleOverride }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '0.9rem', color: 'var(--color-text1)', textAlign: 'left', textTransform: 'uppercase', marginBottom: "1rem", fontWeight: '600', width: '100%', ...styleOverride }}>
      {title}
      { usageString && <span style={{ fontSize: '0.8rem', color: '#8b5cf6', fontWeight: '400', backgroundColor: '#f3e8ff', padding: '.25rem .5rem', borderRadius: '.25rem' }}>{usageString}</span> }
    </div>
  )
}

const ExampleButton = ({ note, onClick, label }) => {
  return (
    <button style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '.5rem .75rem', fontSize: '.9rem', color: 'var(--color-text3)', borderRadius: '.75rem', cursor: 'pointer', whiteSpace: 'nowrap', backgroundColor: 'var(--color-background)', }}
      key={note._id}
      onClick={onClick}
      onMouseEnter={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'; }}
      onMouseLeave={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-background)'; }}
    >
      <div style={{ color: 'var(--color-text4)', fontSize: '.8rem', marginBottom: '.15rem' }}>Example</div>
      {label}
    </button>
  )
}


const HomeScreen = ({ }) => {
  const { user, checkUserPermission, setRefreshRecentResources } = useContext(UserContext)
  const { data, dataCount } = useOutletContext()
  const navigate = useNavigate()
  const { isMobileAndBelow, } = useBreakpoint()

  // TOOLS
  const [ openAudioRecorderModal, setOpenAudioRecorderModal ] = useState(false)
  const [ triggerRecording, setTriggerRecording ] = useState(false);
  const [ openResourceProcessingModal, setOpenResourceProcessingModal ] = useState(false);

  const handleRecordingClick = async () => {
    const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.recording)
    if ( hasPermission ) {
      setOpenAudioRecorderModal(true)
    }
  }

  const handleResourceExampleClick = async (note) => {
    
    try {
      await axios.put(`${API}/generalUpdateOne`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $inc: { ['usage.example_resources']: 1,  } }, 
        dbRef: dbRefs.users 
      })
    }
    catch (error) {
      console.error('Error updating user usage:', error);
    }
    finally {
      navigate(`/app/resources/${note._id}/${Routes.SOURCE}`)
    }
  }

  const handleRecordingExampleClick = async (note) => {
    
    try {
      await axios.put(`${API}/generalUpdateOne`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $inc: { ['usage.example_recording']: 1,  } }, 
        dbRef: dbRefs.users 
      })
    }
    catch (error) {
      console.error('Error updating user usage:', error);
    }
    finally {
      navigate(`/app/resources/${note._id}/${Routes.NOTES}`)
    }
  }


  return (

    <div id="wrapper">
      <div id="main">
        <div className="inner">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '700px', alignSelf: 'center', height: '100%', width: '100%', margin: "auto", paddingBottom: "4rem", gap: '4rem',
            ...isMobileAndBelow ? { marginTop: '1rem', justifyContent: 'flex-start' } : {}
          }}>
            
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <Subheader 
                title="Study" 
              />
              <Uploader_HomeComponent setOpenResourceProcessingModal={setOpenResourceProcessingModal} dataCount={dataCount} />

              {dataCount === 0 && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '.5rem', width: '100%', marginTop: '.75rem', flexWrap: 'wrap' }}>
                  {EXAMPLE_NOTES.slice(0,3).map((note, index) => (
                    <ExampleButton 
                      key={note._id} 
                      note={note} 
                      label={note.title2}
                      onClick={() => handleResourceExampleClick(note)} 
                    />
                  ))}
                </div>
              )}
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <Subheader title="In Class" />

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1.5rem', borderRadius: '1rem', cursor: 'pointer', width: '100%', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
                onClick={handleRecordingClick}
              >
                <Mic size={28} color="var(--color-text1)" strokeWidth={1.5} style={{ marginRight: '1rem', marginLeft: "-.5rem" }} />
                <div style={{ flex: 1 }}>
                  <div style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', textAlign: 'left' }}>
                    Personal Note-Taker
                  </div>
                  <div style={{ fontSize: '.95rem', color: 'var(--color-text4)', textAlign: 'left', lineHeight: '1.2', marginTop: '.5rem' }}>
                    School Goat will take detailed notes on what your teacher says
                  </div>
                </div>
                <ArrowRight size={24} color="var(--color-text3)" style={{ marginLeft: '1rem' }} strokeWidth={1.5} />
              </div>

              {dataCount === 0 && (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '.5rem', width: '100%', marginTop: '.75rem', flexWrap: 'wrap' }}>
                  <ExampleButton 
                    key={'0'} 
                  note={EXAMPLE_NOTES[2]} 
                  label="Econ Lecture Notes"
                    onClick={() => handleRecordingExampleClick(EXAMPLE_NOTES[2])} 
                  />
                </div>
              )}
            </div>
          </div>
          
        </div>


      </div>

      <AudioRecorderModal 
        open={openAudioRecorderModal} 
        setOpen={setOpenAudioRecorderModal} 
        setTriggerRecording={setTriggerRecording}
      />
      
      <AudioRecorder 
        triggerRecording={triggerRecording} 
        setTriggerRecording={setTriggerRecording} 
        setOpenResourceProcessingModal={setOpenResourceProcessingModal} 
      />

      <ResourceProcessingModal 
        open={openResourceProcessingModal} 
        setOpen={setOpenResourceProcessingModal} 
      />


    </div>
  );  
}


export default HomeScreen;


{/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Subheader title="Other Tools"  />

                <div style={{ display: 'flex', flexDirection: 'row', gap: isMobileAndBelow ? '0.5rem' : '1rem', width: '100%' }}>
                  {[
                    {
                      title: 'Solve Homework',
                      description: 'Get step-by-step help with your homework problems', 
                      icon: <Sparkles />,
                      route: Routes.SOLVE
                    },
                    {
                      title: 'Personal Tutor',
                      description: 'Get personalized explanations on any academic topic',
                      icon: <BookOpen />,
                      route: Routes.TEACH
                    },
                    {
                      title: 'Evaluate Essay', 
                      description: 'Get detailed feedback and suggestions on your essays',
                      icon: <FileText />,
                      route: Routes.ESSAY_EVALUATION
                    }
                  ].map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '1.5rem', borderRadius: '1rem', cursor: 'pointer', flex: 1, backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)',
                      ...isMobileAndBelow ? { padding: "1rem" } : {}
                     }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
                      onClick={() => navigate(`/app/${item.route}`)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginBottom: '1rem' }}>
                        {React.cloneElement(item.icon, { size: 20, strokeWidth: 1.5 })}
                      </div>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '.25rem', fontSize: '1rem', fontWeight: '400', color: 'var(--color-text1)', lineHeight: '1.3' }}>
                          {item.title}
                          { !isMobileAndBelow &&
                            <ArrowRight size={16} color="var(--color-text1)" strokeWidth={1.5} />
                          }
                        </div>
                        
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}

              {/* { dataCount == 0 && 
                <div style={{ width: '100%', }}>
                  <Subheader title="Explore examples" />

                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '1rem', }}>
                    { EXAMPLE_NOTES.map(note => <Tile_Example key={note._id} item={note} />)}
                  </div>
                </div>
              } */}

{/* <UploaderModal 
open={openUploaderModal} 
setOpen={setOpenUploaderModal} 
setOpenResourceProcessingModal={setOpenResourceProcessingModal} 
/> */}


// const Tile = ({ item, handleTileClick }) => {
//   const { isMobileAndBelow } = useBreakpoint()

//   if ( !isMobileAndBelow ) {
//     return (
//       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1rem', borderRadius: '1.5rem', cursor: 'pointer', width: '280px', height: '180px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', }}
//       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//       onClick={async () => await handleTileClick(item)}
//       >
//         <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginBottom: '.5rem' }}>
//           {React.cloneElement(item.icon, { size: 30, color: 'var(--color-primary)', strokeWidth: 1.5 })}
//         </div>
//         <div style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', textAlign: 'center' }}>
//           {item.title}
//         </div>
//         <div style={{ fontSize: '1rem', color: 'var(--color-text4)', textAlign: 'center', lineHeight: '1.2', marginTop: '.5rem' }}>
//           {item.description}
//         </div>
//       </div>
//     )
//   } 
//   else {
//     return (
//       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1.5rem', borderRadius: '1.5rem', cursor: 'pointer', width: '100%', maxWidth: '550px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', }}
//       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//       onClick={() => handleTileClick(item)}
//       >
//         <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginRight: '1rem' }}>
//           {React.cloneElement(item.icon, { size: 26, color: 'var(--color-primary)', strokeWidth: 1.5 })}
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           <div style={{ fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)', }}>
//             {item.title}
//           </div>
//           <div style={{ fontSize: '1rem', color: 'var(--color-text4)', lineHeight: '1.2', marginTop: ".5rem" }}>
//             {item.descriptionMobile}
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// const Tile_Example = ({ item }) => {
//   const { isMobileAndBelow } = useBreakpoint()
//   const navigate = useNavigate()

//   const handleNoteClick = (noteId) => {
//     navigate(`/app/resources/${noteId}/${Routes.SUMMARY}`)
//   }

//   if ( !isMobileAndBelow ) {
//     return (
//       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '1.5rem', cursor: 'pointer', borderRadius: '1rem', background: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', flex: 1 }}
//         onClick={() => handleNoteClick(item._id)}
//         onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'}
//         onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//       >
//         <img src={item.image} alt={item.title} style={{ width: '64px', height: '64px', borderRadius: '8px', objectFit: 'cover', marginLeft: "-15px" }} />
//         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '.5rem' }}>
//           <h4 style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', margin: 0, textAlign: 'left' }}>{item.title}</h4>
//           <div style={{ display: 'flex', alignItems: 'flex-start', gap: '.25rem' }}>
//             <span style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>{item.type}</span>
//           </div>
//         </div>
//       </div>
//     )
//   }
//   else {
//     return (
//       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '.5rem', cursor: 'pointer', borderRadius: '1rem', background: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', width: '100%' }}
//         onClick={() => handleNoteClick(item._id)}
//       >
//         <img src={item.image} alt={item.title} style={{ width: '64px', height: '64px', borderRadius: '8px', objectFit: 'cover', marginRight: '1rem' }} />
//         <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '.5rem' }}>
//           <h4 style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', margin: 0 }}>{item.title}</h4>
//           <div style={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
//             <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>{item.category}</span>
//             <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>•</span>
//             <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>{item.type}</span>
//           </div>
//         </div>
//         <ArrowRight size={20} color="var(--color-text4)" strokeWidth={1.5} style={{ marginLeft: 'auto', marginRight: '1rem' }} />
//       </div>
//     )
//   }
// }

              

              {/* <div style={{ display: 'flex', flexDirection: 'row', gap: TILE_GAP_DISTANCE, justifyContent: 'center', flexWrap: 'wrap', width: '100%', marginBottom: TILE_GAP_DISTANCE }}>
                {Object.values(tabs).slice(0, 2).map((item, index) => <Tile key={index} item={item} handleTileClick={handleTileClick} /> )}
              </div> */}

              {/* <ContentCreate_Input
                placeholder="Or quickstart with a topic to learn..."
                value={chatInput}
                setValue={setChatInput}
                handleSubmit={submitQuickstart}
              >
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%", paddingLeft: ".5rem", marginTop: ".25rem" }}>
                  <div style={{ fontSize: "0.9rem", color: "var(--color-text4)" }}>
                    e.g. "The process of photosynthesis"
                  </div>
                </div>

              </ContentCreate_Input> */}



      {/* <div style={{ position: 'fixed', bottom: '0', right: '0', zIndex: 100 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '0.75rem 1.25rem', borderTopLeftRadius: '.5rem', cursor: 'pointer', fontSize: '0.9rem', color: 'var(--color-text3)', transition: 'all 0.2s ease-in-out', }}
          onClick={() => window.open('https://insigh.to/b/school-goat', '_blank')}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
        >
          <ThumbsUp size={16} color="var(--color-text3)" strokeWidth={1.5} />
          Feedback
        </div>
      </div> */}






  {/* <div style={{ marginTop: '2rem', width: '100%', marginTop: '6rem' }}>
    <div style={{ fontSize: '0.9rem', color: 'var(--color-text4)', marginBottom: '1rem', textAlign: 'center', textTransform: 'uppercase' }}>
      Other tools
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', justifyContent: 'center', alignItems: 'center' }}>
      {[
        { icon: <Calculator size={24} />, label: 'Solver', route: Routes.SOLVE },
        { icon: <GraduationCap size={24} />, label: 'Tutor', route: Routes.TEACH },
        { icon: <PenTool size={24} />, label: 'Writing', route: Routes.ESSAY_EVALUATION }
      ].map((tool, index) => (
        <div key={index} 
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.75rem', cursor: 'pointer' }}
              onClick={() => navigate(`/app/${tool.route}`)}>
          <div style={{ 
            width: '64px', 
            height: '64px', 
            borderRadius: '16px', 
            backgroundColor: 'var(--color-backgroundSecondary)',
            border: '1px solid var(--color-separatorOpaque)',
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            // boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            border: '1px solid var(--color-separatorOpaque)',
          }}>
            {React.cloneElement(tool.icon, { strokeWidth: 1.5, style: { color: 'var(--color-text3)' }})}
          </div>
          <div style={{ 
            fontSize: '0.9rem', 
            color: 'var(--color-text2)',
            fontWeight: '500'
          }}>
            {tool.label}
          </div>
        </div>
      ))}


    </div>
  </div> */}


// const styles = {
//   container: { maxWidth: '1024px', margin: '0 auto', padding: '80px 24px 0' },
//   heading: { fontSize: '2.25rem', fontWeight: '600', textAlign: 'center', marginBottom: '48px' },
//   actionsContainer: { marginBottom: '48px' },
//   dropArea: { padding: '48px', backgroundColor: 'white', border: '2px dashed #E5E7EB', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', marginBottom: '24px', cursor: 'pointer' },
//   iconGrid: { display: 'flex', gap: '32px', marginBottom: '8px' },
//   iconBox: { width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F3F4F6', borderRadius: '8px' },
//   uploadText: { fontWeight: '500', fontSize: '16px', color: '#111827', textAlign: 'center' },
//   supportingText: { fontSize: '14px', color: '#6B7280', textAlign: 'center' },
//   divider: { width: '100%', display: 'flex', alignItems: 'center', gap: '16px', margin: '8px 0' },
//   dividerLine: { flex: 1, height: '1px', backgroundColor: '#E5E7EB' },
//   dividerText: { fontSize: '14px', color: '#6B7280' },
//   actionButtons: { display: 'flex', gap: '16px' },
//   actionButton: { flex: 1, padding: '16px', backgroundColor: 'white', border: '1px solid #E5E7EB', borderRadius: '12px', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '12px' },
//   actionIcon: { width: '40px', height: '40px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' },
// };


        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', maxWidth: '1000px', }}>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer' }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Mic size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Live Lecture</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Take AI-enhanced notes during class</div>
          </div>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px dashed var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer' }}
          // onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          // onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Upload size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Upload Materials</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Learn from PDFs, videos, and more</div>
          </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px dashed var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer', marginTop: "1rem", height: "20rem", flex: 1 }}>
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Upload size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Upload Materials</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Learn from PDFs, videos, and more</div>
          </div> */}

{/* <div style={styles.actionsContainer}>
  <div style={styles.dropArea}>
    <div style={styles.iconGrid}>
      <div style={styles.iconBox}>
        <Upload size={24} color="#6B7280" />
      </div>
    </div>
    
    <div>
      <div style={styles.uploadText}>Drop your files here or click to upload</div>
      <div style={styles.supportingText}>Support for PDFs, video links, and more</div>
    </div>
  </div>

  <div style={styles.actionButtons}>
    <button style={styles.actionButton}>
      <div style={{...styles.actionIcon, backgroundColor: '#EFF6FF'}}>
        <Mic size={20} color="#2563EB" />
      </div>
      <div>
        <div style={styles.uploadText}>Live Lecture</div>
        <div style={styles.supportingText}>Take AI-enhanced notes</div>
      </div>
    </button>

    <button style={styles.actionButton}>
      <div style={{...styles.actionIcon, backgroundColor: '#F3F4F6'}}>
        <LightbulbIcon size={20} color="#6B7280" />
      </div>
      <div>
        <div style={styles.uploadText}>Quick Start</div>
        <div style={styles.supportingText}>Type or paste anything</div>
      </div>
    </button>
  </div>
</div> */}






          {/* {data.length > 0 && (
            <div style={{ marginTop: "3.5rem"  }}>
          <SectionHeader title="Recent Materials" />
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '16px' }}>
                {data.map((file) => (
                  <button key={file.name} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px', backgroundColor: 'white', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.75rem', cursor: 'pointer', textAlign: 'left', width: '100%' }}
                  
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
                  onClick={() => navigate(`../${Routes.RESOURCES}/${file._id}/${Routes.SUMMARY}`)}
                  >
                                          <FileText size={20} color="var(--color-text4)" strokeWidth={1.5} style={{ flexShrink: 0, width: '30px' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px', marginLeft: '8px', width: '90%' }}>
                      <div style={{ fontSize: '14px', fontWeight: '500', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}
                        >{file.title}
                      </div>
                      <div style={{ fontSize: '.8rem', color: 'var(--color-text4)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}
                      >{file.category} · {new Date(file.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </div>
                    </div>

                  </button>
                ))}
              </div>
            </div>
          )} */}


          {/* <div style={{ marginTop: "3.5rem"  }}>
          <SectionHeader title="Other Tools" />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', marginBottom: '5rem', maxWidth: '1000px', }}>
              {Object.values(altTabs).map((tool, index) => (
                <Tile2 key={tool.title} item={tool} index={index} handleTabClick={() => navigate(tool.route)} />
              ))}
            </div>
          </div> */}








          {/* <h1 style={{ fontSize: '2.25rem', fontWeight: '600', textAlign: 'center', marginBottom: '48px' }}>
            What do you want to learn today?
          </h1>

          <div style={{ marginBottom: '48px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px', padding: '16px', backgroundColor: 'white', border: '1px solid #E5E7EB', borderRadius: '12px' }}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <button style={{ padding: '8px', border: 'none', background: 'none', borderRadius: '8px', cursor: 'pointer' }}>
                  <Paperclip size={20} color="#6B7280" />
                </button>
                <button style={{ padding: '8px', border: 'none', background: 'none', borderRadius: '8px', cursor: 'pointer' }}>
                  <Mic size={20} color="#6B7280" />
                </button>
              </div>
              <input 
                style={{ flex: 1, border: 'none', fontSize: '16px', outline: 'none', color: '#4B5563' }}
                placeholder="Upload PDF, paste YouTube video, or record a lecture"
              />
              <button style={{ backgroundColor: 'black', color: 'white', padding: '8px 16px', border: 'none', borderRadius: '8px', cursor: 'pointer', fontSize: '14px' }}>
                Start
              </button>
            </div>
          </div> */}