import React, { useContext, useState, useEffect } from 'react';
import { API, dbRefs, PADDLE_BILLING_PORTAL_URL, PAYWALL_TYPES, RESOURCES_UPLOAD_METHODS, STRIPE_BILLING_PORTAL_URL, stripeSubscriptionStates } from '../misc/constants';
import { UserContext } from '../contexts/UserProvider';
import { Crown, MessageCircle, HeartHandshake, LogOut, ArrowRight, CreditCard, Settings, AlertCircle } from 'lucide-react';
import ContactModal from '../modals/ContactModal2';
import FeedbackModal from '../modals/FeedbackModal2';
import { Routes } from '../navigation/routes';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';

const calculatePercentage = (used, total) => (used / total) * 100;

const Divider = () => {
  return <div style={{ width: "100%", height: "1px", backgroundColor: "var(--color-separatorOpaque)", margin: "2.5rem 0" }} />
}

const Tile_Settings = ({ item, index }) => {
  if ( item.isShown ) {
    return (
      <li key={index}>
        <button style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", borderTop: index != 0 ? `1px solid var(--color-separatorOpaque)` : "none", padding: "1.2rem 0", cursor: "pointer", textAlign: "left", color: `var(--color-text2)`, fontSize: "1rem"}}
          onClick={item.onClick}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
        >
          <span style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "15px" }}>
            {/* <item.icon size={20} style={{ marginRight: '20px', color: 'var(--color-text3)' }} aria-hidden="true" /> */}
            {item.text}
          </span>
          <ArrowRight size={'1rem'} style={{ marginRight: '15px', color: 'var(--color-text3)' }} aria-hidden="true" />
        </button>
      </li>
    )
  }
  return null;
}

const Tile_Credits = ({ item, index }) => {
  const percentage = calculatePercentage(item.used, item.total);
  
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
          <span style={{ fontSize: "1rem", fontWeight: "500", color: "var(--color-text2)", padding: '.25rem 0' }}
            >{item.name}
          </span>
          { percentage >= 100 && 
            <span style={{backgroundColor: "#fee2e2", color: "#dc2626", padding: "4px 8px", borderRadius: "4px", fontSize: ".9rem", fontWeight: "500"}}
              >Used
            </span>
          }
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <span style={{ fontSize: ".9rem", color: "var(--color-text3)" }}>
            {item.used} / {item.total}{item.unit ? ` ${item.unit}` : ''}
          </span>
          
        </div>
      </div>
      <div style={{ width: "100%", height: "16px", backgroundColor: "var(--color-background)", borderRadius: ".5rem", overflow: "hidden" }}>
        <div style={{ width: `${percentage}%`, height: "100%", backgroundColor: 'var(--color-text1)', transition: "width 0.3s ease" }} />
      </div>
    </div>
  );
}



const SettingsScreen = () => {
  const { user, logout, setShowCheckoutModal, checkUserPermission } = useContext(UserContext);
  const { isPhoneAndBelow } = useBreakpoint();
  const [ creditData, setCreditData ] = useState([]);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);


  const getData = async () => {

    try {
      const [ resources, recording, solver, teach, essay] = await Promise.all([
        checkUserPermission(PAYWALL_TYPES.resources, false),
        checkUserPermission(PAYWALL_TYPES.recording, false),
        checkUserPermission(PAYWALL_TYPES.solver, false),
        checkUserPermission(PAYWALL_TYPES.teach, false),
        checkUserPermission(PAYWALL_TYPES.essay_evaluation, false),
      ]);

      const credits = [
        { name: "Study Materials", used: resources.initialCreditsUsed, total: resources.initialCredits, },
        { name: "Personal Note-Taker", used: recording.initialCreditsUsed, total: recording.initialCredits, unit: "mins", },
        { name: "Solver", used: solver.initialCreditsUsed, total: solver.initialCredits, },
        { name: "Tutor", used: teach?.initialCreditsUsed, total: teach?.initialCredits, },
        { name: "Essay Evaluation", used: essay?.initialCreditsUsed, total: essay?.initialCredits, },
      ];

      setCreditData(credits);

    } 
    catch (error) {
      console.error(error);
    }
  }

  const data = [
    { icon: Crown, text: 'Get Full Access', onClick: () =>  navigate(`/app/${Routes.JOIN}`), isShown: !user?.isMember },
    { icon: MessageCircle, text: 'Contact Us', onClick: () => setOpenContactModal(true), isShown: true },
    { icon: HeartHandshake, text: 'Give Feedback', onClick: () => setOpenFeedbackModal(true), isShown: true },
    { icon: CreditCard, text: 'Billing', onClick: () => handleBillingClick(), 
      isShown: 
        user?.stripeSubscription?.status != stripeSubscriptionStates.free_trial &&
        user?.stripeSubscription?.status != stripeSubscriptionStates.duplicate_account &&
        user?.stripeSubscription?.status != stripeSubscriptionStates.canceled
    },
    { icon: LogOut, text: 'Log out', onClick: () => logout(), isShown: true },
  ];

  const handleBillingClick = () => {
    let url = ''

    if (user?.stripeSubscription?.paymentProvider == 'paddle') {
      url = process.env.REACT_APP_PADDLE_BILLING_PORTAL_URL;
    }
    else {
      url = STRIPE_BILLING_PORTAL_URL;
    }
    
    window.open(url, '_blank');
  }


  const containerStyle = { display: "flex", flexDirection: "column", gap: "24px",  backgroundColor: "var(--color-backgroundSecondary)",  }
  const titleStyle = { fontSize: "1rem", fontWeight: "600", color: "var(--color-text1)", marginBottom: "8px" }
  const descriptionStyle = { fontSize: ".9rem", color: "var(--color-text4)", margin: 0 }



  return (
    <div id="wrapper">
      <main id="main" >
        <div className="inner" style={{ }}>

          {(user?.stripeSubscription?.status != stripeSubscriptionStates.active && user?.stripeSubscription?.status != stripeSubscriptionStates.trialing) && (
            <>
              <div style={{...containerStyle, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "24px"}}>
                <div style={{ }}>
                  <h2 style={titleStyle}
                    >Your Current Status
                  </h2>
                  <p style={{...descriptionStyle, textTransform: "uppercase"}}
                    >{user?.stripeSubscription?.status?.replace(/_/g, ' ')}
                  </p>
                </div>

                <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', padding: '.75rem 1rem', fontSize: '.95rem', fontWeight: '500', cursor: 'pointer', alignSelf: 'flex-start', }}
                  onClick={() => navigate(`/app/${Routes.JOIN}`)}
                  onMouseEnter={(e) => e.currentTarget.style.opacity = '.7'}
                  onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                >
                  Get Full Access
                </button>
              </div>
              
              <Divider />
            </>
          )}


          {user?.stripeSubscription?.status == stripeSubscriptionStates.free_trial && (
            <>
              <div style={containerStyle}>
                <div style={{ }}>
                  <h2 style={titleStyle}
                  >Free Credit Usage
                  </h2>
                  <p style={descriptionStyle}
                    >Upgrade to School Goat PLUS for unlimited access
                  </p>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "24px", marginTop: "1rem" }}>
                  {creditData.map((feature) => <Tile_Credits key={feature.name} item={feature} />)}
                </div>
              </div>

              <Divider />

            </>
          )}

          <div style={containerStyle}>
            <div style={{ }}>
              <h2 style={titleStyle}
                >Your Account
              </h2>
              <p style={descriptionStyle}
                >{user?.email}
              </p>
            </div>         

            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {data.map((item, index) => <Tile_Settings key={index} item={item} index={index} />)}
            </ul>
          </div>


          <ContactModal open={openContactModal} setOpen={setOpenContactModal} />
          <FeedbackModal open={openFeedbackModal} setOpen={setOpenFeedbackModal} />

        </div>
      </main>
    </div>
  );
};

export default SettingsScreen;