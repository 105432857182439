import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS, PAYWALL_TYPES } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';
import { Routes } from '../navigation/routes';

const Uploader_Home_Text = ({ 
  placeholder = "Paste text from your class materials (readings, notes, etc.)...",
  styleOverride = {},
  setIsLoading = () => {}
 }) => {

  const { isPhoneAndBelow } = useBreakpoint();
  const { user, setRefreshRecentResources, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const textareaRef = useRef(null);

  const handleClose = () => {
    setFile('');
  };

  const uploadFile = async () => {

    let resourceObj = null

    try {
      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.resources)
      if ( !hasPermission ) {
        return;
      }

      setIsLoading(true)

      resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await uploadResourceToS3({ resource_id: resourceObj._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories  })
      handleClose();
      setRefreshRecentResources(prev => !prev)
      navigate(`/app/resources/${resourceObj._id}/${Routes.CHAT}`)
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      try {
        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file}\nUpload method: ${RESOURCES_UPLOAD_METHODS.text}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }

      setFile('')
      alert(error.message);
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%', width: '100%',  }}>
      <textarea style={{ position: 'relative', height: '100%', width: '100%', padding: '1rem', resize: 'none', fontSize: isPhoneAndBelow ? '16px' : '1rem', color: 'var(--color-text2)', lineHeight: '1.4', outline: 'none', backgroundColor: 'transparent', ...styleOverride }}
        ref={textareaRef}   
        placeholder={placeholder}
        value={file}
        onChange={(e) => setFile(e.target.value)}
        aria-label="Text to convert"
      >
        
      </textarea>

      <div style={{ position: 'absolute', bottom: '.5rem', right: '.5rem', zIndex: 1, display: file ? 'block' : 'none' }}>
        <button 
          onClick={uploadFile}
          disabled={!file}
          style={{
            padding: '8px 16px',
            fontSize: '1rem', 
            backgroundColor: file ? 'var(--color-text1)' : 'var(--color-backgroundTertiary)',
            color: file ? 'white' : 'var(--color-text3)',
            border: 'none',
            borderRadius: '6px',
            cursor: file ? 'pointer' : 'default',
            transition: 'all 0.2s'
          }}
          onMouseEnter={(e) => { e.target.style.opacity = '0.6'; }}
          onMouseLeave={(e) => { e.target.style.opacity = '1'; }}
        >
          {'Submit'}
        </button>
      </div>
    </div>
    
  );
};

export default Uploader_Home_Text;
