import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { Upload } from 'lucide-react';


const FileOverlay = ({ 
  title = "Drop your screenshot here",
  handleFile = () => {}
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const overlayRef = useRef(null);

  const useDocumentFileDrag = () => {
    const dragCounter = useRef(0);
    
    useEffect(() => {
      const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.types.includes('Files')) {
          setIsDragging(true);
        }
      };
  
      const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
  
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
          setIsDragging(false);
        }
      };
  
      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          handleFile(e.dataTransfer.files[0])
        }
      };
  
      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragover', handleDragOver);
      document.addEventListener('dragleave', handleDragLeave);
      document.addEventListener('drop', handleDrop);
  
      return () => {
        document.removeEventListener('dragenter', handleDragEnter);
        document.removeEventListener('dragover', handleDragOver);
        document.removeEventListener('dragleave', handleDragLeave);
        document.removeEventListener('drop', handleDrop);
      };
    }, []);
  
    return isDragging;
  };

  const isFileOver = useDocumentFileDrag();


  /////////////////////////////////////// COMPONENTS ///////////////////////////////////////

  if ( isFileOver ) {
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#EEE7FE85', zIndex: 9999, display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'background-color 0.3s', backdropFilter: 'blur(25px)', WebkitBackdropFilter: 'blur(25px)' }}
        ref={overlayRef}
      >
        <div style={{ padding: '32px', borderRadius: '8px', textAlign: 'center' }}>
          <Upload style={{ margin: '0 auto 16px auto', color: 'var(--color-primary)' }} size={48} />
          <p style={{ fontSize: '20px', fontWeight: 600, color: '#333' }}
            >{title}  
          </p>
        </div>
      </div>
    )
  }
  else {
    return null
  }
};

export default FileOverlay;




