import Physics from '../assets/Physics2.webp';
import Chess from '../assets/Chess2.webp';
import War from '../assets/War2.webp';
import Dollar from '../assets/Dollar2.webp';
import { Routes } from '../navigation/routes';

//////////////////// API & S3 ////////////////////
export const API = process.env.REACT_APP_BACKEND_API
export const S3_URL_PREFIX = `https://academicweapon.s3.amazonaws.com/`;
export const REFERRAL_CODE_USED_KEY = 'referralCodeUsed';


//////////////////// NAVIGATION & URL SCHEME ////////////////////
export const SEARCH_PARAMS = {
  _id: "_id",
  ref: "ref",
  checkout_abandoned: "checkout_abandoned",
  generate: "generate",
  tab: "tab",
  checkout: "checkout",
  refresh: "refresh",
  checkout_success: "checkout_success",
  payment_provider: "payment_provider",
  user_id: "user_id",
  email_id: "email_id",
}

export const LOCAL_STORAGE_PARAMS = {
  _id: "_id",
  referralCodeUsed: "referralCodeUsed",
}

export const SESSION_STORAGE_PARAMS = {
  stripeCheckoutPending: "stripeCheckoutPending",
}

///////////////////////// MONGO /////////////////////////
export const dbRefs = {
  users: "USERS",
  messages: "MESSAGES",
  resources: "RESOURCES",
  essays: "ESSAYS",
  ai_detection: "AI_DETECTION",
  essay_evaluation: "ESSAY_EVALUATION",
  teach: "TEACH",
  subscription_claims: "SUBSCRIPTION_CLAIMS",
  emails: "EMAILS",
};


//////////////////// SLACK ////////////////////
export const SLACK_WEBHOOK_CHANNELS = {
  issues: "issues",
  general: "general",
  stripe: "stripe",
  messages: "messages",
  resources: "resources",
  signups: "signups",
  paywall: "paywall",
}

//////////////////// RESOURCES ////////////////////
export const RESOURCES_UPLOAD_METHODS = {
  existingResource: "existingResource",
  file: "file",
  text: "text",
  recording: "recording",
  topic: "topic",
  youtube: "youtube",
}

export const SOURCE_FILE_TYPES = {
  pdf: {
    extensions: ['pdf'],
    label: 'pdf',
    isAllowed: true,
  },
  ppt: {
    extensions: ['ppt', 'pptx', 'pptm'],
    label: 'ppt',
    isAllowed: false,
  },
  doc: {
    extensions: ['doc', 'docx', 'docm'],
    label: 'doc',
    isAllowed: false,
  },
  txt: {
    extensions: ['txt', 'rtf', 'md', 'csv'],
    label: 'txt',
    isAllowed: false,
  },
  audio: {
    extensions: ['mp3', 'wav', 'ogg', 'm4a', 'aac', 'wma', 'flac'],
    label: 'audio',
    isAllowed: true,
  },
  youtube: {
    label: 'youtube',
    isAllowed: true,
  }
};


///////////////////////// AUDIO RECORDING /////////////////////////
export const recordingIntervalDuration = parseInt(process.env.REACT_APP_RECORDING_INTERVAL_DURATION)
export const audioMimeTypes = {
  'audio/webm': '.webm',
  'audio/wav': '.wav',
  'audio/ogg': '.ogg',
  'audio/ogg; codecs=opus': '.opus',
  'audio/mp4': '.m4a',
  'audio/m4a': '.m4a',
  'audio/aac': '.aac',
  'audio/mp3': '.mp3',
  'audio/mpeg': '.mp3',
  'audio/flac': '.flac'
};


///////////////////////// STRIPE /////////////////////////
export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/eVa15GeYedHs7AI5kk" // live
// export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/test_bIY5od6oqa3zgDK000" // test

export const stripeSubscriptionStates = {
  trialing: "trialing", 
  active: "active", 
  incomplete: "incomplete", 
  incomplete_expired: "incomplete_expired", 
  past_due: "past_due", 
  canceled: "canceled",
  unpaid: "unpaid", 
  paused: "paused", 
  free_trial: "free_trial", 
  duplicate_account: "duplicate_account", 
}

export const stripeSubscriptionStatesAlerts = {
  [stripeSubscriptionStates.incomplete]: "Your last transaction is incomplete. Either visit the Billing portal (Settings) if available or subscribe again to regain access (Upgrade). Email ryan@schoolgoat.com if you need help.",
  [stripeSubscriptionStates.incomplete_expired]: "Your last transaction failed. Subscribe again to regain access (Upgrade). Email ryan@schoolgoat.com if you need help.",
  [stripeSubscriptionStates.past_due]: "Your account is past due. Go to the Billing Portal (Settings > Billing) and update your subscription to regain access. Email ryan@schoolgoat.com if you need help.",
  [stripeSubscriptionStates.canceled]: "Your previous subscription was canceled. Subscribe again to regain access (Upgrade). Email ryan@schoolgoat.com if you need help.",
  [stripeSubscriptionStates.unpaid]: "Your account is unpaid. Go to the Billing Portal (Settings > Billing) and update your subscription to regain access. Email ryan@schoolgoat.com if you need help.",
  [stripeSubscriptionStates.paused]: "Your account is paused. Go to the Billing Portal (Settings > Billing) and update your subscription to regain access. Email ryan@schoolgoat.com if you need help.",
}

export const PAYWALL_SCEHMA_VERSION = 1

export const PAYWALL_EVENTS = {

  PAYWALL_SHOWN: "paywall_shown",
  DECLINED: "declined",                
  ACCEPTED: "accepted",                // NEW

  PLANS_SHOWN: "plans_shown",          // NEW
  PLANS_CHECKOUT: "plans_checkout",    // NEW
  PLANS_DECLINED: "plans_declined",    // NEW

  CHECKOUT: "checkout",
  
  DECLINED_REASON: "declined_reason",
  
  CHECKOUT_ABANDONED: "checkout_abandoned",
  CHECKOUT_ABANDONED_REASON: "checkout_abandoned_reason",
  CHECKOUT_ABANDONED_SKIPPED: "checkout_abandoned_skipped",

  // OLD
  DEAL_CHECKOUT: "deal_checkout",
  DEAL_DECLINED: "deal_declined",
  FEEDBACK_SUBMITTED: "feedback_submitted",
  FEEDBACK_SKIPPED: "feedback_skipped",


}

export const PAYWALL_TYPES = {

  // LIVE PAYWALLS
  DUPLICATE_ACCOUNT: "duplicate_account",
  MEMBERS_ONLY_FEATURE: "members_only_feature",
  
  // CREDITS
  recording: "recording",
  notes: "notes",                     
  solver: "solver",
  study: "study",
  ai_detection: "ai_detection",                             
  essay_evaluation: "essay_evaluation",
  teach: "teach",
  resources_quickstart: "resources_quickstart",
  resources_upload: "resources_upload",
  resource_chat: "resource_chat",
  resource_test: "resource_test",

  resources_file: "resources_file",
  resources_text: "resources_text",
  resources: "resources",
}


///////////////////////// DESIGN /////////////////////////

export const COLOR_ARRAY = [
  // "#AF52DE", 
  "#5656ff",
  // "#8b5cf6",
"#FF3B30", "#34C759", "#30B0C7", "#007aff", "#FF9500", "#FFCC00", "#00C7BE", "#32ADE6", "#5856D6", "#FF2D55"]

///////////////////////// MIXPANEL /////////////////////////
export const MIXPANEL_EVENTS = {
  solver: "Solver", // Solver         
  user_created: "User Created",
  essay_evaluation: "Essay Evaluation", //
  flashcard_next: "Flashcard Next",
  quiz_next: "Quiz Next",
  user_signed_in: "User Signed In",
  user_created: "User Created",
  essay_suggestion: "Essay Suggestion",
  essay_ai_detection: "Essay AI Detection",
}

///////////////////////// OPENAI /////////////////////////
export const OPENAI_CHAT_ROLES = { assistant: 'assistant', user: 'user', system: 'system' }



export const EXAMPLE_NOTES = [
  { _id: "42584d00-5705-4356-bbe3-e45c2be5bfdd", 
    title: "World War II", 
    titleHome: "World War II", 
    title2: "History Slides",
    title2Route: Routes.SOURCE,
    title3: "History Slides",
    category: "History", 
    type: "Class Notes", 
    image: War, 
    uploadMethod: RESOURCES_UPLOAD_METHODS.text, 
    date: new Date(),
    user_id: "newuserexample",
    previewMode: true,
  },
  { 
    _id: "ee3df88c-7262-46ab-981a-e69788e662b7", 
    title: "Quantum Physics", 
    titleHome: "Quantum Physics", 
    title2: "Textbook Chapter",
    title2Route: Routes.SOURCE,
    title3: "Physics Textbook",
    category: "Physics", 
    type: "Uploaded Article", 
    image: Physics, 
    uploadMethod: RESOURCES_UPLOAD_METHODS.file, 
    date: new Date(),
    user_id: "newuserexample",
    previewMode: true,
  },
  { 
    _id: "bb550a06-5ad3-4071-9041-0d760d1e41c6", 
    title: "Inflation Notes", 
    titleHome: "Inflation Notes", 
    title2: "Class Notes",
    title2Route: Routes.NOTES,
    title3: "Econ Lecture Notes",
    category: "Economics", 
    type: "Recorded Lecture", 
    image: Dollar, 
    uploadMethod: RESOURCES_UPLOAD_METHODS.recording, 
    date: new Date(),
    user_id: "newuserexample",
    previewMode: true,
  },
  // { _id: "b585edbb-aa83-406d-90bf-4e88397cb978", title: "Introduction to Chess", subject: "Sports", type: "Uploaded PDF", image: Chess },
];